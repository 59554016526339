import React, { useState, useRef, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import SellerStore from '../stores/SellerStore'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import readXlsxFile from 'read-excel-file'
import { FaFileExcel } from 'react-icons/fa'
import backendApis from '../utils/backendApis'
import ItemStore from '../stores/ItemStore'
import socket from '../utils/socket'
import Select, { default as ReactSelect, components } from 'react-select'
import Format from '../utils/format'
import Table from './atoms/table'
import tableUtils from '../utils/tableUtils'
import CancelOrderButton from './buttons/CancelOrderButton'
import EditableCell from './atoms/editableCell'
import SelectableCell from './atoms/selectableCell'
import FastShippingOrderMent from './OrderComponents/FastShippingOrderMent'
import DirectShippingButton from './buttons/DirectShippingButton'
import PostalShippingButton from './buttons/PostalShippingButton'
import UploadShippingNumberButton from './buttons/UploadShippingNumberButton'
import moment from 'moment'
import shortUUID from 'short-uuid'
import {
  getCouponNotSettlementAmount,
  getCouponSettlementAmount,
  getSettlementAmount,
} from '../helper/order'
import RegisterDelayModal from '../screens/Modals/RegisterDelayModal'
import Button from './atoms/button'

const bmoment = require('moment-business-days')

const holidays = SellerStore.holidays

bmoment.updateLocale('us', {
  workingWeekdays: [1, 2, 3, 4, 5],
  holidayFormat: 'YYYY-MM-DD',
  holidays: SellerStore.holidays,
})

const PAGE_ORDER_NUM = 10

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

function isDeparturePossibleDay(
  payedAt,
  regularClosedDay,
  irregularClosedDays,
) {
  const checkDate = moment(payedAt).add(1, 'day') // 오늘 날짜부터 시작

  while (true) {
    const dayOfWeek = checkDate.day()
    const formattedDate = checkDate.format('YYYY-MM-DD')

    // 정규 휴무일 또는 비정규 휴무일 확인
    const isRegularClosed = dayOfWeek === regularClosedDay
    const isIrregularClosed = irregularClosedDays?.some(
      (date) => moment(date).format('YYYY-MM-DD') === formattedDate,
    )
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6
    const isHolidays = holidays?.some((date) => moment(date).isSame(checkDate))

    // 배송 가능 여부 확인
    if (!isRegularClosed && !isIrregularClosed && !isWeekend && !isHolidays) {
      return checkDate.endOf('day').format('YYYY-MM-DD HH:mm') // 배송 가능한 날짜 반환
    }

    // 다음 날짜로 이동
    checkDate.add(1, 'days')
  }
}

const comparePreshippingTimeStamp = (obj1, obj2) => {
  const createdAt1 = new Date(obj1.preShippingTimeStamp)
  const createdAt2 = new Date(obj2.preShippingTimeStamp)
  let comparison = 0
  if (createdAt1 > createdAt2) {
    comparison = 1
  } else if (createdAt1 < createdAt2) {
    comparison = -1
  }
  return comparison
}

const headers = [
  { header: '주문아이디', key: 'orderId', width: 20 },
  { header: '상품아이디', key: 'itemId', width: 20 },
  { header: '합배송아이디', key: 'addressId', width: 20 },
  { header: '판매자 상품코드', key: 'optionCode', width: 20 },
  { header: '상품명', key: 'itemTitle', width: 30 },
  { header: '옵션', key: 'option', width: 40 },
  { header: '수량', key: 'quantity' },
  { header: '상품가격', key: 'totalPrice' },
  { header: '배송비', key: 'shippingFee' },
  { header: '추가지원금', key: 'platformSupportPrice' },
  {
    header: '올웨이즈 부담 쿠폰 할인금',
    key: 'couponSettlementAmount',
  },
  {
    header: '판매자 부담 쿠폰 할인금',
    key: 'couponNotSettlementAmount',
  },
  { header: '총 쿠폰 할인금', key: 'finalDiscountAmount' },
  { header: '정산대상금액(수수료 제외)', key: 'settlementAmount' },
  { header: '주소', key: 'address', width: 50 },
  { header: '우편번호', key: 'postcode' },
  { header: '공동현관 비밀번호', key: 'frontDoorPassword', width: 20 },
  { header: '수령 방법', key: 'detailForReceivingMethod' },
  { header: '수령인', key: 'recipient' },
  { header: '수령인 연락처', key: 'recipientPhoneNumber', width: 20 },
  { header: '주문 시점', key: 'payedAt', width: 20 },
  { header: '택배사', key: 'shippingCompany' },
  { header: '운송장번호', key: 'shippingNumber', width: 30 },
  { header: '고유 코드', key: 'manualItemCode' },
]

const xlsxHeader = [
  '주문아이디',
  '상품명',
  '옵션',
  '수량',
  '주소',
  '우편번호',
  '공동현관 비밀번호',
  '수령 방법',
  '수령인',
  '수령인 연락처',
  '택배사',
  '운송장 번호',
]

const PreShippingOrdersAfterExcel = observer(({ orders, setOrders }) => {
  const [preShippingExcelLimit, setPreShippingExcelLimit] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [shippingInfo, setShippingInfo] = useState(null)
  const [selectedOption, setSelectedOption] = useState([])
  const [registerDelayModal, setRegisterDelayModal] = useState(false)
  const [withoutDelayedOrders, setWithoutDelayedOrders] = useState([])
  const translator = shortUUID()

  const data = useMemo(() => {
    return orders
      ?.filter(
        (order) => order.status === 'pre-shipping' && order.excelDownloadedAt,
      )
      .slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
      ?.map((order, index) => {
        let timePast = Math.ceil(
          (new Date().getTime() - new Date(order.payedAt).getTime()) /
            (1000 * 60 * 60),
        )
        const daysPast = Math.floor(timePast / 24)
        timePast %= 24
        if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
          if (order?.teamDealInfo?.subAddressInfo) {
            order.addressInfo = order?.teamDealInfo?.subAddressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          } else {
            order.addressInfo = order?.teamDealInfo?.addressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          }
        }
        return {
          ...order,
          index: index + 1 + PAGE_ORDER_NUM * pageIndex,
          itemTitle: (
            <a
              href={order.url
                ?.replace('m.coupang', 'coupang')
                ?.replace('/vm/', '/vp/')}
            >
              {order.itemInfo?.itemTitle}
            </a>
          ),
          option: tableUtils.parseOptionText(
            order?.itemInfo?.optionsInfo,
            order?.selectedOption,
          ),
          shippingCompanyCode: '',
          shippingNumber: '',
          uploadShippingNumber: order,
          postalShipping: (
            <PostalShippingButton
              order={order}
              setOrders={setOrders}
              orders={orders}
            />
          ),
          directShipping: (
            <DirectShippingButton
              order={order}
              setOrders={setOrders}
              orders={orders}
            />
          ),
          totalPrice: order?.totalPrice,
          payedAt: moment(order.payedAt).format('YYYY-MM-DD HH:mm'),
          addressId: `${Format.DateToday(order?.payedAt)}${
            checkIfValidUUID(order?.addressInfo?.addressId) &&
            translator.fromUUID(order?.addressInfo?.addressId || order?._id)
          }`.substring(2),
          optionCode: order?.selectedOption?.optionCode || '',
          itemId: order?.itemInfo?.itemId,
          // option: optionsText.map((option) => <div key={option}>{option}</div>),
          platformSupportPrice:
            (order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1),
          couponSettlementAmount: getCouponSettlementAmount(order),
          couponNotSettlementAmount: getCouponNotSettlementAmount(order),
          finalDiscountAmount:
            order.selectedCouponInfo?.finalDiscountAmount || 0,
          settlementAmount: getSettlementAmount(order),
          shippingFee: order?.shippingFee,
          address: `${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`,
          detailAddress: order.addressInfo?.detailAddress,
          recipient: Format.Recipient(order.addressInfo?.recipient),
          recipientPhoneNumber: order.addressInfo?.recipientPhoneNumber,
          timePast: (
            <>
              <div>{moment(order.payedAt).format('YYYY-MM-DD HH:mm')}</div>
              <div>
                ({daysPast > 0 ? `${daysPast}일 ` : ''} {timePast} 시간 전)
              </div>
            </>
          ),
          cancel: (
            <CancelOrderButton
              order={order}
              setOrders={setOrders}
              status='pre-shipping'
            />
          ),
        }
      })
  }, [
    orders?.filter(
      (order) => order.status === 'pre-shipping' && order.excelDownloadedAt,
    )?.length,
    pageIndex,
  ])

  const columns = useMemo(() => [
    { Header: '번호', accessor: 'index' },
    {
      Header: '발송처리기한',
      Cell: ({ row }) => {
        const now = moment()
        const preShippingDate = row?.original?.preShippingTimeStamp
        const delayedDepartureDeadlineValue =
          row?.original?.deadline?.delayedDepartureDeadline
        const deadline = bmoment(preShippingDate)
          .businessAdd(3, 'days')
          .endOf('day')
        const deadlineLeftDays = bmoment(deadline).businessDiff(now, 'days')

        const isTodayDepartureItem =
          row?.original?.itemInfo?.shippingInfo?.todayDeparture
            ?.isTodayDepartureItem
        const orderFinishDeadline =
          row?.original?.itemInfo?.shippingInfo?.todayDeparture
            ?.orderFinishDeadline
        const regularClosedDays =
          row?.original?.itemInfo?.shippingInfo?.todayDeparture
            ?.regularClosedDays
        const irregularClosedDays =
          row?.original?.itemInfo?.shippingInfo?.todayDeparture
            ?.irregularClosedDays || []

        if (isTodayDepartureItem && !delayedDepartureDeadlineValue) {
          // const payedDate = row?.original?.payedAt
          const orderDeadline = moment(preShippingDate)
            .set({
              hour: orderFinishDeadline?.hour,
              minute: orderFinishDeadline?.minute,
            })
            .format('YYYY-MM-DD HH:mm')

          // 마감시간 이후 주문 > 다음 배송 가능일을 계산해서 뱉어줌
          if (preShippingDate > orderDeadline) {
            const nextDepartureDate = isDeparturePossibleDay(
              preShippingDate,
              regularClosedDays,
              irregularClosedDays,
            )
            if (now.format('YYYY-MM-DD HH:mm') > nextDepartureDate) {
              return (
                <div className='flex items-center justify-center'>
                  <div className='text-sm text-center text-red-600 font-semibold'>
                    {nextDepartureDate}
                    <br />
                    오늘출발 패널티 부과
                  </div>
                </div>
              )
            }
            return (
              <div className='flex items-center justify-center'>
                <div className='text-sm text-center text-blue-600 font-semibold'>
                  {nextDepartureDate}
                  <br />
                  오늘출발 주문
                </div>
              </div>
            )
          }
          // 마감시간 이전 주문 > 다음 배송 가능일을 계산해서 뱉어줌
          if (preShippingDate < orderDeadline) {
            const isIrregularClosed = irregularClosedDays.some(
              (date) =>
                moment(date).format('YYYY-MM-DD') ===
                moment(preShippingDate).format('YYYY-MM-DD'),
            )
            const isHolidays = holidays.some((date) =>
              moment(date).isSame(preShippingDate),
            )
            // 주말이면 다음 가능한 날짜를 뱉어야 함
            if (
              moment(preShippingDate).day() === 0 ||
              moment(preShippingDate).day() === 6 ||
              moment(preShippingDate).day() === regularClosedDays ||
              isIrregularClosed ||
              isHolidays
            ) {
              const nextDepartureDate = isDeparturePossibleDay(
                preShippingDate,
                regularClosedDays,
                irregularClosedDays,
              )
              return (
                <div className='flex items-center justify-center'>
                  <div className='text-sm text-center text-blue-600 font-semibold'>
                    {nextDepartureDate}
                    <br />
                    오늘출발 주문
                  </div>
                </div>
              )
            }
            const todayDepartureDeadline = moment(preShippingDate)
              .set({ hour: 23, minute: 59 })
              .format('YYYY-MM-DD HH:mm')
            if (now.format('YYYY-MM-DD HH:mm') > todayDepartureDeadline) {
              return (
                <div className='flex items-center justify-center'>
                  <div className='text-sm text-center text-red-600 font-semibold'>
                    {todayDepartureDeadline}
                    <br />
                    오늘출발 패널티 부과
                  </div>
                </div>
              )
            }
            return (
              <div className='flex items-center justify-center'>
                <div className='text-sm text-center text-blue-600 font-semibold'>
                  {todayDepartureDeadline}
                  <br />
                  오늘출발 주문
                </div>
              </div>
            )
          }
        }

        if (delayedDepartureDeadlineValue) {
          const delayedDepartureDeadline = moment(delayedDepartureDeadlineValue)
          return now > delayedDepartureDeadline ? (
            <div className='flex items-center justify-center'>
              <div className='text-sm text-center text-red-600'>
                {delayedDepartureDeadline.format('YYYY-MM-DD HH:mm')}
                <br />
                지연발송 패널티 부과
              </div>
            </div>
          ) : (
            <div className='flex items-center justify-center'>
              <div className='text-sm text-center text-teal-600'>
                {delayedDepartureDeadline.format('YYYY-MM-DD HH:mm')}
                <br />
                지연발송 주문
              </div>
            </div>
          )
        }
        return now > deadline ? (
          <div className='flex items-center justify-center'>
            <div className='text-sm text-center text-red-600'>
              {deadline.format('YYYY-MM-DD HH:mm')}
              <br />
              패널티 부과
            </div>
          </div>
        ) : (
          <div className='flex items-center justify-center'>
            <div className='text-sm text-center text-blue-600'>
              {deadline.format('YYYY-MM-DD HH:mm')}
              <br />
              {deadlineLeftDays}영업일
            </div>
          </div>
        )
      },
    },
    { Header: '주문아이디', accessor: '_id' },
    { Header: '상품명', accessor: 'itemTitle' },
    { Header: '옵션', accessor: 'option' },
    { Header: '수량', accessor: 'quantity' },
    {
      Header: '택배사',
      accessor: 'shippingCompanyCode',
      Cell: ({ row }) => {
        return (
          <SelectableCell
            value={row?.state?.shippingCompanyCode}
            options={[
              { value: '', text: '===' },
              ...(OrderStore.shippingCompaniesInfo?.map(
                (shippingCompanyInfo) => ({
                  value: shippingCompanyInfo.shippingCompanyCode,
                  text: shippingCompanyInfo.shippingCompanyName,
                }),
              ) || []),
            ]}
            onChange={(e) => {
              row?.setState((prev) => ({
                ...prev,
                shippingCompanyCode: e.target.value,
              }))
            }}
          />
        )
      },
    },
    {
      Header: '운송장번호',
      accessor: 'shippingNumber',
      Cell: ({ value, row, column, cell }) => {
        return (
          <EditableCell
            value={row?.state?.shippingNumber}
            onChange={(e) => {
              row?.setState((prev) => ({
                ...prev,
                shippingNumber: e.target.value,
              }))
            }}
          />
        )
      },
    },
    {
      Header: '운송장 업로드',
      accessor: 'uploadShippingNumber',
      Cell: ({ value, row, column, cell }) => {
        return (
          <UploadShippingNumberButton
            order={value}
            setOrders={setOrders}
            orders={orders}
            shippingNumber={row?.state?.shippingNumber}
            shippingCompanyCode={row?.state?.shippingCompanyCode}
          />
        )
      },
    },
    { Header: '우편발송', accessor: 'postalShipping' },
    { Header: '직접배송', accessor: 'directShipping' },
    { Header: '상품가격', accessor: 'totalPrice' },
    { Header: '추가지원금', accessor: 'platformSupportPrice' },
    {
      Header: '올웨이즈 부담 쿠폰 할인금',
      accessor: 'couponSettlementAmount',
    },
    {
      Header: '판매자 부담 쿠폰 할인금',
      accessor: 'couponNotSettlementAmount',
    },
    { Header: '총 쿠폰 할인금', accessor: 'finalDiscountAmount' },
    { Header: '정산대상금액(수수료 제)', accessor: 'settlementAmount' },
    { Header: '배송비', accessor: 'shippingFee' },
    { Header: '배송지', accessor: 'address' },
    { Header: '상세 주소', accessor: 'detailAddress' },
    { Header: '수령인', accessor: 'recipient' },
    { Header: '수령인 연락처', accessor: 'recipientPhoneNumber' },
    { Header: '주문 시점', accessor: 'timePast' },
    { Header: '주문 취소', accessor: 'cancel' },
  ])

  const excelInput = document.getElementById('excel-input-after-excel')
  const parseAndUploadShippingNumber = async () => {
    SellerStore.setIsLoading(true)
    const rows = await readXlsxFile(excelInput.files[0], { sheet: 1 })
    const headerTemp = rows[0]
    const orderIdIndex = headerTemp.findIndex((title) => title === '주문아이디')
    const shippingCompanyNameIndex = headerTemp.findIndex(
      (title) => title === '택배사',
    )
    const shippingNumberIndex = headerTemp.findIndex(
      (title) => title === '운송장번호',
    )
    const failedRows = []
    const chunkSize = 100
    for (let index = 1; index < rows.length; index += chunkSize) {
      const chunkedRows = rows.slice(index, index + chunkSize)
      const shippingInfos = chunkedRows.map((row) => {
        // const row = rows[index]
        const orderId = row[orderIdIndex]
        const shippingCompanyName = row[shippingCompanyNameIndex]
        const shippingNumber = row[shippingNumberIndex]
          ?.toString()
          ?.replace(/[- ]/g, '')
        if (shippingCompanyName === '우편발송') {
          return {
            orderId,
            shippingInfo: { shippingCompanyName },
          }
        }
        const correspondingShippingCompanyInfo =
          OrderStore.shippingCompaniesInfo.find(
            (shippingCompanyInfo) =>
              shippingCompanyInfo.shippingCompanyName === shippingCompanyName,
          )
        return {
          orderId,
          shippingInfo: { ...correspondingShippingCompanyInfo, shippingNumber },
        }
      })
      // 현일 - 100개의 청크 올리기 전에 front 단에서 주문아이디, 택배사, 운송장번호 있는지(1차 유효성검사) 확인하는 로직
      // 참고사항 - 청크별 1차 유효성검사가 이뤄지므로 앞선 청크는 배송중으로 넘어갈 수 있는데, alert가 뜬 걸 보면 셀러 입장에서는 전체다 안올라 간것과 같은 착시현상 있을 수 있음
      // 백에서 1차 유효성 + status 검사까지 추가로 다 하고 failed rows로 박아줘야 하나..?
      if (!shippingInfos.every((shippingInfo) => shippingInfo.orderId)) {
        alert(
          '주문아이디가 없는 주문이 있습니다. 엑셀을 다시한번 확인해 주세요!',
        )
        SellerStore.setIsLoading(false)
        return
      }
      if (
        !shippingInfos
          .filter(
            (item) => item.shippingInfo?.shippingCompanyName !== '우편발송',
          )
          .every(
            (shippingInfo) => shippingInfo.shippingInfo?.shippingCompanyName,
          )
      ) {
        alert(
          '택배사가 정확하지 않은 주문이 있습니다. 엑셀을 다시한번 확인해 주세요!',
        )
        SellerStore.setIsLoading(false)
        return
      }
      if (
        !shippingInfos
          .filter(
            (item) => item.shippingInfo?.shippingCompanyName !== '우편발송',
          )
          .every((shippingInfo) => shippingInfo.shippingInfo?.shippingNumber)
      ) {
        alert(
          '운송장번호가 없는 주문이 있습니다. 엑셀을 다시한번 확인해 주세요!',
        )
        SellerStore.setIsLoading(false)
        return
      }
      if (
        Array.isArray(shippingInfos) &&
        shippingInfos
          .filter(
            (item) => item.shippingInfo?.shippingCompanyName === '우편발송',
          )
          .every((shippingInfo) => shippingInfo.orderId) &&
        shippingInfos.filter(
          (item) => item.shippingInfo?.shippingCompanyName === '우편발송',
        ).length > 0
      ) {
        shippingInfos
          .filter(
            (item) => item.shippingInfo?.shippingCompanyName === '우편발송',
          )
          .map(async (order) => {
            const result = await backendApis.postOrderPostalShippingInfo(
              order?.orderId,
              {
                _id: '60fa5eb6d28d5e1c65152c77',
                shippingCompanyName: 'CJ대한통운',
                shippingCompanyCode: '04',
                // shippingNumber: shippingNumber.replace(/-/g, ''),
                isPostalShipping: true,
              },
            )
            if (result.status === 200) {
              const excelDownloadedAt = order.excelDownloadedAt
              if (order.excelDownloadedAt) {
                OrderStore.changeOrdersNumPerStatus(
                  'pre-shipping-post-excel',
                  -1,
                )
              } else {
                OrderStore.changeOrdersNumPerStatus(
                  'pre-shipping-pre-excel',
                  -1,
                )
              }

              OrderStore.setDisplayedOrders(
                OrderStore.displayedOrders.map((orderTemp) => {
                  if (orderTemp._id === order._id) {
                    return {
                      ...orderTemp,
                      status: 'shipping',
                      shippingInfo: {
                        ...orderTemp.shippingInfo,
                        isPostalShipping: true,
                        // shippingNumber: shippingNumber.replace(/-/g, ''),
                      },
                    }
                  }
                  return orderTemp
                }),
              )
              if (setOrders) {
                OrderStore.setUserOrders(
                  orders.map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        status: 'shipping',
                        shippingInfo: {
                          ...orderTemp.shippingInfo,
                          isPostalShipping: true,
                          // shippingNumber: shippingNumber.replace(/-/g, ''),
                        },
                      }
                    }
                    return orderTemp
                  }),
                )
              }
              OrderStore.changeOrdersNumPerStatus('pre-shipping', -1)
              OrderStore.changeOrdersNumPerStatus('shipping', 1)
            }
            return result
          })
      }
      if (
        Array.isArray(shippingInfos) &&
        shippingInfos
          .filter(
            (item) => item.shippingInfo?.shippingCompanyName !== '우편발송',
          )
          .every(
            (shippingInfo) =>
              shippingInfo.orderId &&
              shippingInfo.shippingInfo?.shippingCompanyName &&
              shippingInfo.shippingInfo?.shippingCompanyCode &&
              shippingInfo.shippingInfo?.shippingNumber,
          ) &&
        shippingInfos.filter(
          (item) => item.shippingInfo?.shippingCompanyName !== '우편발송',
        ).length > 0
      ) {
        const status = 'pre-shipping'
        const result = await backendApis.postShippingInfosInBulk(
          shippingInfos,
          status,
        )
        if (result?.status === 2000) {
          const successFids = result?.data?.list
            ?.filter((element) => element?.success)
            ?.map((element) => element?.fid)
          OrderStore.setDisplayedOrders(
            OrderStore.displayedOrders.map((orderTemp) => {
              if (successFids?.includes(orderTemp._id)) {
                return {
                  ...orderTemp,
                  status: 'shipping',
                  shippingInfo: shippingInfos.find(
                    (info) => info?.orderId === orderTemp?._id,
                  )?.shippingInfo,
                }
              }
              return orderTemp
            }),
          )
          if (orders && setOrders) {
            setOrders(
              orders.map((orderTemp) => {
                if (successFids?.includes(orderTemp._id)) {
                  return {
                    ...orderTemp,
                    status: 'shipping',
                    shippingInfo: shippingInfos.find(
                      (info) => info?.orderId === orderTemp?._id,
                    )?.shippingInfo,
                  }
                }
                return orderTemp
              }),
            )
          }
          if (successFids?.length) {
            OrderStore.changeOrdersNumPerStatus(
              'pre-shipping',
              -successFids?.length,
            )
            OrderStore.changeOrdersNumPerStatus(
              'pre-shipping-post-excel',
              -successFids?.length,
            )
            OrderStore.changeOrdersNumPerStatus(
              'shipping-sending',
              successFids?.length,
            )
          }
          // failedRows.push(
          //   ...chunkedRows.filter(
          //     (row) => !successFids?.includes(row?.[orderIdIndex]),
          //   ),
          // )
          result?.data?.list
            ?.filter((element) => !element?.success)
            ?.forEach((element) => {
              failedRows.push({
                ...chunkedRows.filter(
                  (row) => element?._fid === row?.[orderIdIndex],
                ),
                failReason: element?.e_message,
              })
            })
        } else {
          result?.data?.list
            ?.filter((element) => !element?.success)
            ?.forEach((element) => {
              failedRows.push({
                ...chunkedRows.filter(
                  (row) => element?._fid === row?.[orderIdIndex],
                ),
                failReason: element?.e_message,
              })
            })
        }
      }
    }
    if (failedRows.length > 0) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('주문 내역')
      worksheet.columns = [
        ...headers,
        { header: '운송장번호 등록 실패 사유', key: 'failReason', width: 30 },
      ]
      worksheet.addRows(failedRows)
      worksheet.getColumn(3).alignment = { wrapText: true }
      const headerRow = worksheet.getRow(1)
      headers.forEach((header, index) => {
        headerRow.getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFEA3F49' },
        }
      })
      worksheet.getRow(1).font = {
        color: { argb: 'FFFFFFFF' },
        bold: true,
      }
      const mimeType = {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)
      const saveDate = new Date()
      const year = saveDate.getFullYear()
      const month = saveDate.getMonth() + 1
      const date = saveDate.getDate()
      saveAs(
        blob,
        `${SellerStore.sellerInfo.sellerDisplayName}_주문 내역_${year}-${
          month < 10 ? `0${month}` : month
        }-${date < 10 ? `0${date}` : date}_운송장 오류 주문건.xlsx`,
      )
      SellerStore.setIsLoading(false)
      alert(
        '업로드되지 않은 주문이 있습니다. [결제완료 중 엑셀 추출 이후] 또는 엑셀 파일을 통해 해당 내역을 확인하세요.',
      )
    } else {
      SellerStore.setIsLoading(false)
      alert('주문 업로드가 완료되었습니다.')
    }
  }

  const handleOpenRegisterDelayModal = () => {
    // delayedDepartureDeadline이 없는 주문들만 필터링
    const ordersWithoutDelay = orders.filter(
      (order) => !order.deadline?.delayedDepartureDeadline,
    )
    setWithoutDelayedOrders(ordersWithoutDelay)
    setRegisterDelayModal(true)
  }

  useEffect(() => {
    if (excelInput) {
      excelInput.addEventListener('change', parseAndUploadShippingNumber)
      return () => {
        excelInput?.removeEventListener('change', parseAndUploadShippingNumber)
      }
    }
  }, [excelInput, orders.length])

  useEffect(() => {
    if (
      SellerStore.sellerInfo._id &&
      OrderStore.shippingCompaniesInfo.length > 0 &&
      orders.length > 0
    ) {
      if (SellerStore.sellerInfo.defaultShippingCompanyId) {
        const correspondingShippingCompanyInfo =
          OrderStore.shippingCompaniesInfo.find(
            (shippingCompanyInfo) =>
              shippingCompanyInfo._id ===
              SellerStore.sellerInfo.defaultShippingCompanyId,
          )

        setShippingInfo({
          ...shippingInfo,
          ...correspondingShippingCompanyInfo,
        })
      }
    }
  }, [
    SellerStore.sellerInfo._id,
    OrderStore.shippingCompaniesInfo.length,
    orders.length,
  ])

  return (
    <div className='flex-1 w-full mb-20' id='preShippingPostExcelOrders'>
      <div>
        <h2 className='p-5 text-2xl font-bold'>
          상품준비중 (엑셀추출 이후) (
          {setOrders
            ? orders?.filter(
                (order) =>
                  order.status === 'pre-shipping' && order.excelDownloadedAt,
              )?.length
            : OrderStore.ordersNumPerStatus['pre-shipping-post-excel'] || 0}
          )
        </h2>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'center',
            // backgroundColor: 'red',
          }}
        >
          <div className='flex flex-row mb-5'>
            {/* <h2 style={{ marginBottom: 5 }}>
          결제완료 ({OrderStore.preShippingOrders.length})
        </h2> */}

            <button
              className='flex items-center px-2 py-3 mr-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
              type='button'
              onClick={async () => {
                if (!shippingInfo?._id) {
                  alert('택배사를 선택해주세요!')
                  return
                }
                if (SellerStore.isLoading) {
                  return
                }
                SellerStore.setIsLoading(true)

                let loadedOrders = []
                if (
                  orders.filter(
                    (order) =>
                      order.status === 'pre-shipping' &&
                      order.excelDownloadedAt,
                  ).length < 50 ||
                  (preShippingExcelLimit &&
                    orders
                      .filter(
                        (order) =>
                          order.status === 'pre-shipping' &&
                          order.excelDownloadedAt,
                      )
                      .filter((order) => {
                        // if (filteredItemId) {
                        //   return order.itemInfo.itemId === filteredItemId
                        // }
                        if (selectedOption.length > 0) {
                          return selectedOption
                            .map((option) => option.value)
                            .includes(order.itemInfo.itemId)
                        }
                        return true
                      }).length > parseInt(preShippingExcelLimit, 10))
                ) {
                  loadedOrders = orders.filter(
                    (order) =>
                      order.status === 'pre-shipping' &&
                      order.excelDownloadedAt,
                  )
                } else {
                  let loadOrders = true
                  let index = 0
                  while (loadOrders && index < 2000) {
                    index += 1
                    const loadResult =
                      await backendApis.getSegmentedOrdersInStatus(
                        'pre-shipping',
                        loadedOrders.slice(-1)?.length > 0 &&
                          loadedOrders.slice(-1)[0]?.payedAt,
                        false,
                      )
                    if (
                      loadResult?.status === 200 &&
                      loadResult.data?.length > 0
                    ) {
                      loadedOrders.push(...loadResult.data)
                    } else {
                      loadOrders = false
                    }
                  }
                }
                const workbook = new ExcelJS.Workbook()
                const worksheet = workbook.addWorksheet('주문 내역')
                const fetchItemsData = async () => {
                  const itemIds = SellerStore?.sellerInfo?.itemIds || []
                  const projectCondition = {
                    _id: 1,
                    manualItemCode: 1,
                  }
                  const result = await backendApis.loadItemSpecificInfo(
                    itemIds,
                    projectCondition,
                  )
                  if (result?.status === 200) {
                    return result?.data
                  }
                  return []
                }
                let totalItemInfo = []
                if (
                  SellerStore.sellerInfo?._id === '6238198180322c376f1ebece'
                ) {
                  totalItemInfo = (await fetchItemsData()) || []
                }
                const worksheetData = loadedOrders
                  // .filter((order) => {
                  //   // if (filteredItemId) {
                  //   //   return order.itemInfo.itemId === filteredItemId
                  //   // }
                  //   if (selectedOption.length > 0) {
                  //     return selectedOption
                  //       .map((option) => option.value)
                  //       .includes(order.itemInfo.itemId)
                  //   }
                  //   return true
                  // })
                  .filter(
                    (order) =>
                      order.status === 'pre-shipping' &&
                      order.excelDownloadedAt &&
                      order.addressInfo,
                  )
                  .slice(
                    0,
                    parseInt(preShippingExcelLimit, 10) ||
                      OrderStore.ordersNumPerStatus['pre-shipping-post-excel'],
                  )
                  .map((order) => {
                    const optionsText =
                      order.itemInfo.optionsInfo.optionNames.map(
                        (value, optionIndex) => {
                          const optionDetail =
                            order.itemInfo.optionsInfo.totalOptions[
                              optionIndex
                            ][order.selectedOption[optionIndex]].name
                          return `${optionIndex}. ${value}: ${optionDetail}`
                        },
                      )
                    if (
                      order?.itemInfo?.characteristicItem?.isApartmentTeamDeal
                    ) {
                      if (order?.teamDealInfo?.subAddressInfo) {
                        order.addressInfo = order?.teamDealInfo?.subAddressInfo
                        order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
                      } else {
                        order.addressInfo = order?.teamDealInfo?.addressInfo
                        order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
                      }
                    }
                    const recipientPhoneNumber =
                      order.addressInfo?.recipientPhoneNumber
                    let manualItemCode = ''
                    const recipientPhoneNumberProcessed = recipientPhoneNumber
                    if (
                      SellerStore.sellerInfo?._id === '6238198180322c376f1ebece'
                    ) {
                      manualItemCode = totalItemInfo?.find(
                        (itemTemp) =>
                          itemTemp?._id?.toString() ===
                          order?.itemInfo?.itemId?.toString(),
                      )?.manualItemCode
                    }

                    return {
                      orderId: order._id,
                      itemId: order?.itemInfo?.itemId,
                      itemTitle: order.itemInfo?.itemTitle,
                      addressId: `${Format.DateToday(order?.payedAt)}${
                        checkIfValidUUID(order?.addressInfo?.addressId) &&
                        translator.fromUUID(
                          order?.addressInfo?.addressId || order?._id,
                        )
                      }`.substring(2),
                      optionCode: order?.selectedOption?.optionCode || '',
                      option: optionsText.join('\n'),
                      quantity: order.quantity,
                      address: `${order.addressInfo?.postcodeAddress} ${order.addressInfo?.detailAddress}`,
                      postcode: order.addressInfo?.postcode,
                      payedAt: moment(order.payedAt).format('YYYY-MM-DD HH:mm'),
                      frontDoorPassword: order.addressInfo?.frontDoorPassword,
                      detailForReceivingMethod:
                        order.addressInfo?.receivingMethod === 'door'
                          ? '문 앞'
                          : order.addressInfo?.detailForReceivingMethod,
                      recipient: Format.Recipient(order.addressInfo?.recipient),
                      recipientPhoneNumber: recipientPhoneNumberProcessed,
                      shippingCompany: shippingInfo.shippingCompanyName,
                      shippingNumber: '',
                      manualItemCode,
                      totalPrice: order?.totalPrice,
                      shippingFee: order?.shippingFee,
                      platformSupportPrice:
                        (order.selectedOption?.platformSupportPrice || 0) *
                        (order.quantity || 1),
                      couponSettlementAmount: getCouponSettlementAmount(order),
                      couponNotSettlementAmount:
                        getCouponNotSettlementAmount(order),
                      finalDiscountAmount:
                        order.selectedCouponInfo?.finalDiscountAmount || 0,
                      settlementAmount: getSettlementAmount(order),
                    }
                  })
                worksheet.columns = headers
                worksheet.addRows(worksheetData)
                worksheet.getColumn(3).alignment = { wrapText: true }
                const headerRow = worksheet.getRow(1)
                headers.forEach((header, index) => {
                  headerRow.getCell(index + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFEA3F49' },
                  }
                })
                worksheet.getRow(1).font = {
                  color: { argb: 'FFFFFFFF' },
                  bold: true,
                }
                const mimeType = {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }
                const buffer = await workbook.xlsx.writeBuffer()
                const blob = new Blob([buffer], mimeType)
                const saveDate = new Date()
                const year = saveDate.getFullYear()
                const month = saveDate.getMonth() + 1
                const date = saveDate.getDate()
                saveAs(
                  blob,
                  `${
                    SellerStore.sellerInfo.sellerDisplayName
                  }_주문 내역_${year}-${month < 10 ? `0${month}` : month}-${
                    date < 10 ? `0${date}` : date
                  }.xlsx`,
                )
                // backendApis.setSellerPreShippingOrdersExcelDownloadedAt(
                //   worksheetData.map((order) => order.orderId),
                // )

                SellerStore.setIsLoading(false)
              }}
            >
              <FaFileExcel />
              엑셀 다시 추출하기
            </button>
            <div style={{ paddingLeft: 30 }} />
            <button
              className='flex items-center px-2 py-2 mr-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
              type='button'
              onClick={() => {
                if (pageIndex > 0) {
                  setPageIndex(pageIndex - 1)
                }
              }}
            >
              이전
            </button>
            <div
              style={{
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {pageIndex + 1}
            </div>
            {/* {`  ${pageIndex + 1}  `} */}
            <button
              className='flex items-center px-3 py-3 mx-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
              type='button'
              onClick={async () => {
                if (SellerStore.isLoading) {
                  return
                }
                if (
                  pageIndex + 1 <
                  orders.filter(
                    (order) =>
                      order.status === 'pre-shipping' &&
                      order.excelDownloadedAt,
                  ).length /
                    PAGE_ORDER_NUM
                ) {
                  setPageIndex(pageIndex + 1)
                } else if (orders.length > 0 && !setOrders) {
                  SellerStore.setIsLoading(true)
                  const result = await OrderStore.loadExtraPostExcelOrders(
                    'pre-shipping',
                    orders.slice(-1)?.length > 0 &&
                      orders.slice(-1)[0]?.payedAt,
                  )
                  if (result) {
                    setPageIndex(pageIndex + 1)
                  }
                  SellerStore.setIsLoading(false)
                }
              }}
            >
              다음
            </button>
          </div>
        </div>
        <div>
          <div className='flex flex-col items-start rounded-md h-35'>
            <div className='flex flex-row items-center mb-5 '>
              <div className='flex flex-row items-center flex-1 text-lg pl-200'>
                택배사 일괄 변경 적용하기 :
              </div>
              <div className='flex items-center border-2'>
                {SellerStore.sellerInfo._id && (
                  <select
                    className='w-80'
                    id='shipping_company'
                    name='shipping_company'
                    onChange={(e) => {
                      const correspondingShippingCompanyInfo =
                        OrderStore.shippingCompaniesInfo.find(
                          (shippingCompanyInfo) =>
                            shippingCompanyInfo.shippingCompanyCode ===
                            e.target.value,
                        )
                      if (correspondingShippingCompanyInfo) {
                        setShippingInfo({
                          ...shippingInfo,
                          ...correspondingShippingCompanyInfo,
                        })
                        OrderStore.setDisplayedOrders(
                          OrderStore.displayedOrders.map((orderTemp) => {
                            if (
                              // !filteredItemId ||
                              // filteredItemId === orderTemp.itemInfo.itemId
                              (!selectedOption?.length ||
                                selectedOption
                                  .map((option) => option.value)
                                  .includes(orderTemp.itemInfo.itemId)) &&
                              orderTemp.status === 'pre-shpipping' &&
                              orderTemp.excelDownloadedAt
                            ) {
                              return {
                                ...orderTemp,
                                shippingInfo: {
                                  ...orderTemp.shippingInfo,
                                  ...correspondingShippingCompanyInfo,
                                },
                              }
                            }
                            return orderTemp
                            // if (orderTemp._id === order._id) {

                            // }
                            // return orderTemp
                          }),
                        )
                        if (orders && setOrders) {
                          setOrders(
                            orders.map((orderTemp) => {
                              if (
                                // !filteredItemId ||
                                // filteredItemId === orderTemp.itemInfo.itemId
                                (!selectedOption?.length ||
                                  selectedOption
                                    .map((option) => option.value)
                                    .includes(orderTemp.itemInfo.itemId)) &&
                                orderTemp.status === 'pre-shipping'
                              ) {
                                return {
                                  ...orderTemp,
                                  shippingInfo: {
                                    ...orderTemp.shippingInfo,
                                    ...correspondingShippingCompanyInfo,
                                  },
                                }
                              }
                              return orderTemp
                            }),
                          )
                        }
                      } else {
                        setShippingInfo(null)
                        // setFilteredPreShippingOrders(
                        //   filteredPreShippingOrders.map((orderTemp) => {
                        //     // if (orderTemp._id === order._id) {
                        //     return {
                        //       ...orderTemp,
                        //       shippingInfo: null,
                        //     }
                        //     // }
                        //     // return orderTemp
                        //   }),
                        // )
                        OrderStore.setDisplayedOrders(
                          OrderStore.displayedOrders.map((orderTemp) => {
                            if (
                              // !filteredItemId ||
                              // filteredItemId === orderTemp.itemInfo.itemId
                              (!selectedOption?.length ||
                                selectedOption
                                  .map((option) => option.value)
                                  .includes(orderTemp.itemInfo.itemId)) &&
                              orderTemp.status === 'pre-shipping'
                            ) {
                              return {
                                ...orderTemp,
                                shippingInfo: {
                                  ...orderTemp.shippingInfo,
                                  ...correspondingShippingCompanyInfo,
                                },
                              }
                            }
                            return orderTemp
                            // if (orderTemp._id === order._id) {

                            // }
                            // return orderTemp
                          }),
                        )
                        if (orders && setOrders) {
                          setOrders(
                            orders.map((orderTemp) => {
                              if (
                                // !filteredItemId ||
                                // filteredItemId === orderTemp.itemInfo.itemId
                                (!selectedOption?.length ||
                                  selectedOption
                                    .map((option) => option.value)
                                    .includes(orderTemp.itemInfo.itemId)) &&
                                orderTemp.status === 'pre-shipping'
                              ) {
                                return {
                                  ...orderTemp,
                                  shippingInfo: {
                                    ...orderTemp.shippingInfo,
                                    ...correspondingShippingCompanyInfo,
                                  },
                                }
                              }
                              return orderTemp
                            }),
                          )
                        }
                      }
                    }}
                    // defaultValue={
                    //   SellerStore.sellerInfo.defaultShippingCompanyId
                    //     ? OrderStore.shippingCompaniesInfo.find(
                    //         (shippingCompanyInfo) =>
                    //           shippingCompanyInfo._id ===
                    //           SellerStore.sellerInfo.defaultShippingCompanyId,
                    //       ).shippingCompanyCode
                    //     : ''
                    // }
                    value={shippingInfo?.shippingCompanyCode || ''}
                  >
                    <option value=''>===</option>
                    {OrderStore.shippingCompaniesInfo.map(
                      (shippingCompanyInfo) => (
                        <option
                          key={shippingCompanyInfo.shippingCompanyCode}
                          value={shippingCompanyInfo.shippingCompanyCode}
                        >
                          {shippingCompanyInfo.shippingCompanyName}
                        </option>
                      ),
                    )}
                  </select>
                )}
              </div>
            </div>
            <div className='flex flex-row items-center flex-1 mb-5'>
              <div className='text-lg'>엑셀로 송장 한꺼번에 올리기 :</div>
              <input
                className='mr-3 bg-white'
                type='file'
                id='excel-input-after-excel'
                accept='.xlsx'
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Select
        value={selectedOption}
        onChange={(selectedOptionTemp) => {
          setPageIndex(0)
          setSelectedOption(selectedOptionTemp)
          console.log(`selected option: ${JSON.stringify(selectedOptionTemp)}`)
        }}
        options={OrderStore.preShippingItemInfo.map((item) => ({
          value: item._id,
          label: item.itemTitle,
        }))}
        allowSelectAll
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
      /> */}
      <div className='mb-5'>
        <Button
          appearance='red'
          size='sm'
          onClick={handleOpenRegisterDelayModal}
        >
          발송지연 처리
        </Button>
      </div>
      <Table
        columns={columns}
        data={data}
        shippingCompanyCode={shippingInfo?.shippingCompanyCode}
      />
      {registerDelayModal && (
        <RegisterDelayModal
          showModal={registerDelayModal}
          setShowModal={setRegisterDelayModal}
          orders={withoutDelayedOrders}
          setOrders={setOrders}
        />
      )}
    </div>
  )
})

export default PreShippingOrdersAfterExcel
