import { makeObservable, observable, action } from 'mobx'

class ModalStore {
  maintenanceMode = { visible: false, html: '' }

  constructor() {
    makeObservable(this, {
      maintenanceMode: observable.ref,
      setMaintenanceMode: action,
    })
  }

  setMaintenanceMode(value) {
    this.maintenanceMode = value
  }
}

export default new ModalStore()
