import { makeObservable, observable, action, runInAction } from 'mobx'
import backendApis from '../utils/backendApis'
import socket from '../utils/socket'
import Format from '../utils/format'
import { getSettlementAmount } from '../helper/order'

class SellerStore {
  sellerInfo = {}
  isLoading = false
  ordersForSettlements = []
  settlements = []
  banksInfo = []
  testingSeller = []
  sellerType = []
  holidays = []
  sellerScores = {}
  sellerPeriodScores = []
  checkedScore = 'all'
  itemStats = {}
  recommenderName = ''
  recommenderRank = ''
  recommenderNumber = ''
  badItemResult = []
  settlementExceptAmount = ''
  settlementAmount = ''
  settlementConfirmAmount = ''
  loadingDueSettlements = true
  dashboardInfo = []
  loadingDashboardInfo = true
  sellerGmvForRank = ''
  sellerTotalOrderForRank = ''
  sellerRank = []
  sellerScoreForRank = []
  itemsRank = []
  biddingItem = {}
  biddingItemOptionsInfo = {}
  orderForGoodService = ''
  isCpcAdSeller = false
  isUploading = false
  uploadingStatus = {}
  nudgeModalStatus = {
    dealOneClickModal: false,
  }

  // sellerName = ''
  // sellerImageUri = ''
  constructor() {
    makeObservable(this, {
      // sellerName: observable,
      // sellerImageUri: observable,
      sellerInfo: observable,
      isLoading: observable,
      ordersForSettlements: observable,
      settlements: observable,
      banksInfo: observable,
      testingSeller: observable,
      sellerType: observable,
      sellerScores: observable,
      sellerPeriodScores: observable,
      checkedScore: observable,
      itemStats: observable,
      recommenderName: observable,
      recommenderRank: observable,
      recommenderNumber: observable,
      badItemResult: observable,
      settlementExceptAmount: observable,
      settlementAmount: observable,
      settlementConfirmAmount: observable,
      loadingDueSettlements: observable,
      dashboardInfo: observable,
      loadingDashboardInfo: observable,
      sellerGmvForRank: observable,
      sellerTotalOrderForRank: observable,
      sellerRank: observable,
      sellerScoreForRank: observable,
      itemsRank: observable,
      biddingItem: observable,
      biddingItemOptionsInfo: observable,
      orderForGoodService: observable,
      isCpcAdSeller: observable,
      isUploading: observable,
      uploadingStatus: observable,
      nudgeModalStatus: observable,

      setSellerInfo: action,
      setIsLoading: action,
      setOrdersForSettlements: action,
      setSettlements: action,
      setBanksInfo: action,
      setSellerType: action,
      setSellerScores: action,
      setHolidays: action,
      setSellerPeriodScores: action,
      setCheckedScore: action,
      setItemStats: action,
      setRecommenderName: action,
      setRecommenderRank: action,
      setRecommenderNumber: action,
      setBadItemResult: action,
      setSettlementExceptAmount: action,
      setSettlementAmount: action,
      setSettlementConfirmAmount: action,
      setLoadingDueSettlements: action,
      setDashboardInfo: action,
      setLoadingDashboardInfo: action,
      setSellerGmvForRank: action,
      setSellerTotalOrderForRank: action,
      setSellerRank: action,
      setSellerScoreForRank: action,
      setItemsRank: action,
      setBiddingItem: action,
      setBiddingItemOptionsInfo: action,
      setOrderForGoodService: action,
      setIsCpcAdSeller: action,
      setIsUploading: action,
      setUploadingStatus: action,
      setNudgeModalStatus: action,
    })
  }

  setLoadingDueSettlements(loadingDueSettlements) {
    this.loadingDueSettlements = loadingDueSettlements
  }

  setLoadingDashboardInfo(loadingDashboardInfo) {
    this.loadingDashboardInfo = loadingDashboardInfo
  }

  setItemStats(itemStats) {
    this.itemStats = itemStats
  }

  setSettlementExceptAmount(settlementExceptAmount) {
    this.settlementExceptAmount = settlementExceptAmount
  }

  setHolidays(holidays) {
    this.holidays = holidays
  }

  setSellerScores(sellerScores) {
    this.sellerScores = sellerScores
  }

  setSellerPeriodScores(sellerPeriodScores) {
    this.sellerPeriodScores = sellerPeriodScores
  }

  setCheckedScore(checkedScore) {
    this.checkedScore = checkedScore
  }

  setBanksInfo(banksInfo) {
    this.banksInfo = banksInfo
  }

  setSellerGmvForRank(sellerGmvForRank) {
    this.sellerGmvForRank = sellerGmvForRank
  }

  setOrderForGoodService(orderForGoodService) {
    this.orderForGoodService = orderForGoodService
  }

  setSellerScoreForRank(sellerScoreForRank) {
    this.sellerScoreForRank = sellerScoreForRank
  }

  setSellerTotalOrderForRank(sellerTotalOrderForRank) {
    this.sellerTotalOrderForRank = sellerTotalOrderForRank
  }

  setSellerRank(sellerRank) {
    this.sellerRank = sellerRank
  }

  setSellerType(sellerType) {
    this.sellerType = sellerType
  }

  setSettlements(settlements) {
    this.settlements = settlements
  }

  setOrdersForSettlements(ordersForSettlements) {
    this.ordersForSettlements = ordersForSettlements
  }

  setSellerInfo(sellerInfo) {
    this.sellerInfo = sellerInfo
  }

  setSellerName(sellerName) {
    this.setSellerInfo({ ...this.sellerInfo, sellerName })
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading
  }

  setRecommenderName(recommenderName) {
    this.recommenderName = recommenderName
  }

  setRecommenderRank(recommenderRank) {
    this.recommenderRank = recommenderRank
  }

  setRecommenderNumber(recommenderNumber) {
    this.recommenderNumber = recommenderNumber
  }

  setBadItemResult(badItemResult) {
    this.badItemResult = badItemResult
  }

  setSettlementAmount(settlementAmount) {
    this.settlementAmount = settlementAmount
  }

  setSettlementConfirmAmount(settlementConfirmAmount) {
    this.settlementConfirmAmount = settlementConfirmAmount
  }

  setDashboardInfo(dashboardInfo) {
    this.dashboardInfo = dashboardInfo
  }

  setItemsRank(itemsRank) {
    this.itemsRank = itemsRank
  }

  setBiddingItem(biddingItem) {
    this.biddingItem = biddingItem
  }

  setBiddingItemOptionsInfo(biddingItemOptionsInfo) {
    this.biddingItemOptionsInfo = biddingItemOptionsInfo
  }

  setIsCpcAdSeller(isCpcAdSeller) {
    this.isCpcAdSeller = isCpcAdSeller
  }

  setIsUploading(isUploading) {
    this.isUploading = isUploading
  }

  setUploadingStatus(uploadingStatus) {
    this.uploadingStatus = uploadingStatus
  }

  setNudgeModalStatus(nudgeModalStatus) {
    this.nudgeModalStatus = nudgeModalStatus
  }

  async loadSellerInfo() {
    const sellerResult = await backendApis.loadSellerInfo()
    const sellerBusinessInfo =
      await backendApis.loadSellerBusinessRegistrationInfo()
    if (sellerResult?.status === 200 && sellerResult?.data) {
      const updatedSellerInfo = {
        ...sellerResult.data,
        dataForValidation: sellerBusinessInfo?.data || {},
      }
      this.setSellerInfo(updatedSellerInfo)
      return true
    }
    return false
  }

  async loadDashboardInfo() {
    const result = await backendApis.loadDashboardInfo()
    if (result?.status === 200) {
      this.setDashboardInfo(result?.data)
      this.setLoadingDashboardInfo(false)
    }
  }

  async loadDueSettlements() {
    if (this.ordersForSettlements?.length > 0) {
      return
    }
    let errorAPIsCount = 0
    this.setLoadingDueSettlements(true)
    let ordersForSettlementsTemp = []
    while (this.loadingDueSettlements) {
      const result = await backendApis.loadSegmentedSellerDueSettlements(
        ordersForSettlementsTemp.slice(-1)?.length > 0 &&
          ordersForSettlementsTemp.slice(-1)[0]?.payedAt,
      )
      if (result?.status === 200 && result.data?.length > 0) {
        ordersForSettlementsTemp = [...ordersForSettlementsTemp, ...result.data]
      } else if (result?.status === 200 && !result.data?.length) {
        this.setLoadingDueSettlements(false)
      } else {
        errorAPIsCount += 1
        if (errorAPIsCount > 100) {
          this.setLoadingDueSettlements(false)
        }
      }
    }
    this.setOrdersForSettlements(ordersForSettlementsTemp)

    this.setSettlementAmount(
      Math.ceil(
        this.ordersForSettlements
          .map((order) => {
            return Format.formatOrderForSettlementExcel(order)
          })
          .reduce((a, b) => a + b?.settlementAmount, 0),
      ),
    )
    const settlementExceptAmountResult =
      await backendApis.loadSettlementExceptAmount(this.settlementAmount)
    if (
      settlementExceptAmountResult?.status === 200 &&
      settlementExceptAmountResult?.data
    ) {
      this.setSettlementExceptAmount(settlementExceptAmountResult?.data)
    }
    this.setSettlementConfirmAmount(
      this.settlementAmount - this.settlementExceptAmount,
    )
  }

  //! deprecated
  loadSettlementConfirmAmount() {
    this.setSettlementConfirmAmount(
      Math.ceil(
        this.ordersForSettlements
          .map((order) => {
            const settlementAmount = getSettlementAmount(order)
            return settlementAmount
          })
          .reduce((a, b) => a + b, 0),
      ) - Math.ceil(this.settlementExceptAmount || 0),
    )
  }

  async loadBanksInfo() {
    const result = await backendApis.loadBanksInfo()
    if (result?.status === 200) {
      this.setBanksInfo(result?.data)
    }
  }

  async loadSettlements() {
    const result = await backendApis.loadSellerPastSettlements()
    if (result?.status === 200) {
      this.setSettlements(result.data)
    }
  }

  async loadSellerGmvForRank(sellerId) {
    const result = await backendApis.loadSellerGmvForRank(sellerId)
    if (result?.status === 200) {
      this.setSellerGmvForRank(result.data || 0)
    }
  }

  async loadSellerScoreForRank(sellerId) {
    const result = await backendApis.loadSellerScoreForRank(sellerId)
    if (result?.status === 200) {
      this.setSellerScoreForRank(result.data[0])
    }
  }

  async loadSellerRank(sellerId) {
    const result = await backendApis.loadSellerRank(sellerId)
    if (result?.status === 200) {
      this.setSellerRank(result.data)
    }
  }

  async loadSellerTotalOrderForRank(sellerId) {
    const result = await backendApis.loadSellerTotalOrderForRank(sellerId)
    if (result?.status === 200) {
      this.setSellerTotalOrderForRank(result.data || 0)
    }
  }

  async loadOrderForGoodService() {
    const result = await backendApis.loadOrderForGoodService()
    if (result?.status === 200) {
      this.setOrderForGoodService(result.data)
    }
  }

  async setSellerShippingScore(shippingScore) {
    this.setSellerScores({ ...this.sellerScores, shippingScore })
  }

  async setSellerShippingDetailStats(shippingDetailStats) {
    this.setSellerScores({
      ...this.sellerScores,
      shippingDetailStats,
    })
  }

  async setSellerMaxWeeklyTotalOrdersLastMonth() {
    const result = await backendApis.loadSellerMaxWeeklyTotalOrdersLastMonth()
    if (result?.status === 200 && result.data) {
      this.setSellerScores({
        ...this.sellerScores,
        maxWeeklyTotalOrdersLastMonth: result.data.maxTotalOrderNum,
      })
    }
  }

  async loadHolidays() {
    const result = await backendApis.loadHolidays()
    if (result?.status === 200) {
      this.setHolidays(result?.data)
    }
  }

  async setSellerRecentTodayDeparturePenalty() {
    const result = await backendApis.getRecentTodayDeparturePenalty()
    if (result?.status === 200 && result.data) {
      this.setSellerScores({
        ...this.sellerScores,
        recentTodayDeparturePenalty: result.data[0]?.penaltyPoint || 0,
      })
    }
  }

  async loadItemStats(
    itemStatsTemp,
    visitOverImpScore,
    orderOverImpScore,
    orderOverVisitScore,
    totalShortVisitResult,
    totalShortImpressionResult,
    totalLongVisitResult,
    totalLongOrdersResult,
  ) {
    const itemStatsToObject = {}
    Object.keys(itemStatsTemp).forEach((item) => {
      itemStatsToObject[item.itemId] = item
    })
    this.setItemStats(itemStatsToObject)
    this.setSellerScores({
      ...this.sellerScores,
      itemScores: {
        totalItemScore:
          visitOverImpScore + orderOverImpScore + orderOverVisitScore,
        totalShortVisitResult,
        totalShortImpressionResult,
        totalLongVisitResult,
        totalLongOrdersResult,
        itemScoresList: itemStatsTemp,
      },
    })
  }

  async setSellerReviewScore(reviewScore) {
    this.setSellerScores({
      ...this.sellerScores,
      reviewScore,
    })
  }

  async setSellerCustomerServiceScore(customerServiceScore) {
    this.setSellerScores({ ...this.sellerScores, customerServiceScore })
  }

  async setSellerCancelScore(cancelScore) {
    this.setSellerScores({
      ...this.sellerScores,
      cancelScore,
    })
  }

  async loadItemsRank(sellerId) {
    const result = await backendApis.loadItemsRank(sellerId)
    if (result?.status === 200 && result.data?.length > 0) {
      this.setItemsRank(result.data)
    }
  }

  async loadCalculatedSellerPoints() {
    const result = await backendApis.loadSellerCalculatedScores()
    if (result?.status === 200 && result.data) {
      this.setSellerShippingScore(result.data?.shippingScore || 0)
      this.setSellerShippingDetailStats(result.data?.shippingDetailStats || {})
      this.setSellerReviewScore(result.data?.reviewScore || 0)
      this.setSellerCancelScore(result.data?.cancelScore || 0)
      if (result.data?.itemStats) {
        this.loadItemStats(
          result.data?.itemStats,
          result.data?.visitOverImpScore || 0,
          result.data?.orderOverImpScore || 0,
          result.data?.orderOverVisitScore || 0,
          result.data?.itemStats.reduce(
            (a, b) => a + (b?.shortVisitResult || 0),
            0,
          ),
          result.data?.itemStats.reduce(
            (a, b) => a + (b?.shortImpressionResult || 0),
            0,
          ),
          result.data?.itemStats.reduce(
            (a, b) => a + (b?.longVisitResult || 0),
            0,
          ),
          result.data?.itemStats.reduce(
            (a, b) => a + (b?.longOrdersResult || 0),
            0,
          ),
        )
      }
      // if (result.data?.customerServiceStats) {
      this.setSellerCustomerServiceScore(result.data?.customerServiceScore || 0)
      // }
    }
  }

  async loadCalculatedSellerPeriodScores() {
    const result = await backendApis.loadSellerPeriodCalculatedScores()
    if (result?.status === 200 && result.data) {
      this.setSellerPeriodScores({
        ...this.sellerPeriodScores,
        rawData: result.data,
      })
    }
  }

  async loadSettlementExceptAmount(settlementAmount) {
    const result = await backendApis.loadSettlementExceptAmount(
      settlementAmount,
    )
    console.log('2222@@@@', result)
    if (result?.status === 200 && result?.data) {
      this.setSettlementExceptAmount(result?.data)
    }
  }

  async loadBiddingItem(itemId) {
    const result = await backendApis.getBiddingItem(itemId)
    if (result?.status === 200 && result?.data) {
      this.setBiddingItem({ ...result?.data[0] })
    }
  }

  async loadSellerRevenueByItemRank() {
    const result = await backendApis.getSellerRevenueByItemRank(
      this.sellerInfo._id,
    )
    if (result?.status === 200 && result?.data) {
      return result
    }
    return {}
  }

  async tracePriceNudgeOfRankRevenueLog() {
    const result = await backendApis.tracePriceNudgeOfRankRevenueLog(
      this.sellerInfo._id,
    )
    if (result?.status === 200 && result?.data) {
      return result
    }
    return []
  }

  async tracePriceNudgeLog(eventLocation, eventAction) {
    const result = await backendApis.tracePriceNudgeLog(
      this.sellerInfo._id,
      eventLocation,
      eventAction,
    )
    if (result?.status === 200 && result?.data) {
      return result
    }
    return []
  }

  initializeSocket() {
    socket?.emit('sellerInitialize', this.sellerInfo._id)
  }
}

export default new SellerStore()
