import React, { useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import moment from 'moment-timezone'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import commaNumber from 'comma-number'
import Log from '../utils/log'
import tableUtils from '../utils/tableUtils'
import Button from '../components/atoms/button'
import Title from '../components/ADS/atoms/title'
import Statistics from '../components/ADS/molecules/statistics'
import Table from '../components/ADS/molecules/table'
import Pagination from '../components/ADS/atoms/pagination'
import ButtonCard from '../components/ADS/molecules/buttonCard'
import Tabs from '../components/ADS/molecules/tabs'
import { SiGooglesheets } from 'react-icons/si'
import Format from '../utils/format'
import shortUUID from 'short-uuid'
import {
  getCommissionRate,
  getSettlementAmount,
  getCouponSettlementAmount,
  getCouponSettlementAmountForRevenueDiscount,
  getPlatformSupportPriceInfo,
} from '../helper/order'

const PAGE_ORDER_NUM = 10

const headers = Format.SettlementExcelHeaders

const settlementExceptHeaders = [
  { header: '정산 일자', key: 'settlementDate', width: 30 },
  { header: '신상품 매대 체험단', key: 'newItemReviewEvent', width: 30 },
  { header: '사진후기', key: 'alfarmPhotoReview', width: 20 },
  { header: '올팜 퀴즈', key: 'alfarmQuiz', width: 20 },
  { header: '올팜 상점', key: 'alfarmStore', width: 20 },
  { header: '올팜 포춘쿠키', key: 'alfarmEgg', width: 20 },
  { header: '우주 최저가', key: 'alfarmTrendQuiz', width: 20 },
  {
    header: '바로도착(CJ대한통운) 운임',
    key: 'fastArrivalCjLogistics',
    width: 20,
  },
  {
    header: '바로도착(딜리버스) 운임',
    key: 'fastArrivalDelivus',
    width: 20,
  },
  { header: '기타', key: 'etc', width: 20 },
]

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

const SettlementsScreen = observer(() => {
  const isFriday = moment().day() === 5
  const showSettlementComplete = isFriday && SellerStore.settlementAmount === 0

  const [pageIndex, setPageIndex] = useState(0)
  const [sortOrder, setSortOrder] = useState('desc') // 'desc' 또는 'asc'

  const translator = shortUUID()
  const [isGettingPlatformSupportPrice, setIsGettingPlatformSupportPrice] =
    useState(false)
  const daysFromSaturday = (moment().days() + 1) % 7
  const startTimeStamp = new Date(
    moment()
      .subtract(14 + daysFromSaturday - 2, 'days') // 2주전 월요일
      .startOf('day'),
  )
  const endTimeStamp = new Date(
    moment()
      .subtract(7 + daysFromSaturday - 1, 'days') // 2주전 일요일
      .startOf('day'),
  )
  useEffect(() => {
    const fetchData = async () => {
      await SellerStore.loadDueSettlements()
      await SellerStore.loadSettlements()
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (SellerStore.ordersForSettlements.length > 0) {
      const totalSupport = SellerStore.ordersForSettlements.reduce(
        (a, b) => a + (b.selectedOption?.platformSupportPrice || 0),
        0,
      )
      if (totalSupport > 0) {
        setIsGettingPlatformSupportPrice(true)
      }
    }
    if (SellerStore.settlements.length > 0) {
      const totalSupport = SellerStore.settlements.reduce(
        (a, b) => a + (b.totalPlatformSupportPrice || 0),
        0,
      )
      if (totalSupport > 0) {
        setIsGettingPlatformSupportPrice(true)
      }
    }
  }, [
    SellerStore.ordersForSettlements.length > 0,
    SellerStore.settlements.length > 0,
  ])

  const ordersForSettlementsColumns = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '주문아이디', accessor: '_id' },
      { Header: '합배송 아이디', accessor: 'addressId' },
      { Header: '상품명', accessor: 'itemTitle' },
      { Header: '옵션', accessor: 'option' },
      { Header: '수량', accessor: 'quantity' },
      { Header: '주문 상태', accessor: 'status' },
      { Header: '결제 수단', accessor: 'cardOrMoney' },
      { Header: '상품가격', accessor: 'totalPrice' },
      { Header: '추가지원금', accessor: 'platformSupportPrice' },
      {
        Header: '특별 수수료(기타 매출)',
        accessor: 'platformSupportPriceRevenue',
      },
      {
        Header: '올웨이즈 부담 할인금',
        accessor: 'discountAmountForRevenueDiscount',
      },
      {
        Header: '판매자 부담 쿠폰 할인금',
        accessor: 'couponNotSettlementAmount',
      },
      { Header: '기 정산 금액', accessor: 'originallySettledAmount' },
      { Header: '기 정산 수수료', accessor: 'originalCommissionAmount' },
      // { Header: '기타 정산금액', accessor: 'otherSettlementAmount' },
      { Header: '수수료', accessor: 'commissionRate' },
      { Header: '정산대상금액(수수료 제)', accessor: 'settlementAmount' },
      { Header: '배송비', accessor: 'shippingFee' },
      { Header: '주문 성사 시점', accessor: 'preShippingTimeStamp' },
      { Header: '배송 완료 시점', accessor: 'arrivedTimeStamp' },
      { Header: '취소 완료 시점', accessor: 'canceledTimeStamp' },
      { Header: '구매(취소) 확정 시점', accessor: 'orderConfirmedTimeStamp' },
    ],
    [],
  )

  const ordersForSettlementsData = useMemo(() => {
    return SellerStore.ordersForSettlements
      ?.slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
      ?.map((order, index) => {
        const couponSettlementAmount = getCouponSettlementAmount(order)
        const couponSettlementAmountForRevenueDiscount =
          getCouponSettlementAmountForRevenueDiscount(order)
        const otherSettlementAmount =
          couponSettlementAmount - couponSettlementAmountForRevenueDiscount
        // const couponSettlementAmountForNewYear24Deal =
        //   order?.selectedCouponInfoForNewYear24Deal?.finalDiscountAmount || 0

        // const selectedCouponFinalDiscountAmount =
        //   order.selectedCouponInfo?.finalDiscountAmount || 0
        // let couponSettlementAmount =
        //   bySellerCoupon && !order.selectedCouponInfo?.condition?.noCalculate
        //     ? 0
        //     : Number(selectedCouponFinalDiscountAmount)

        // couponSettlementAmount += Number(couponSettlementAmountForNewYear24Deal)

        const couponNotSettlementAmount =
          (order.selectedCouponInfo?.couponInfo?.type === 'bySeller' ||
            order.selectedCouponInfo?.condition?.type === 'bySeller') &&
          !order.selectedCouponInfo?.condition?.noCalculate
            ? order.selectedCouponInfo?.finalDiscountAmount || 0
            : 0

        const settlementAmount = getSettlementAmount(order)

        const originallySettledAmount =
          order.settlementLogs
            ?.map((settlementLog) => settlementLog.settlementAmount)
            .reduce((a, b) => a + b, 0) || 0
        const originalCommissionAmount =
          order.settlementLogs
            ?.map((settlementLog) => settlementLog.commissionAmount)
            .reduce((a, b) => a + b, 0) || 0
        const addressId = `${Format.DateToday(order?.payedAt)}${
          checkIfValidUUID(order?.addressInfo?.addressId) &&
          translator.fromUUID(order?.addressInfo?.addressId || order?._id)
        }`.substring(2)
        const {
          platformSupportPrice,
          platformSupportPriceRevenue,
          platformSupportPriceRevenueDiscount,
        } = getPlatformSupportPriceInfo(order)

        const discountAmountForRevenueDiscount =
          couponSettlementAmountForRevenueDiscount +
          platformSupportPriceRevenueDiscount
        return {
          ...order,
          index: (
            <div className='text-right'>
              {index + 1 + PAGE_ORDER_NUM * pageIndex}
            </div>
          ),
          option: tableUtils.parseOptionText(
            order?.itemInfo?.optionsInfo,
            order?.selectedOption,
          ),
          addressId,
          totalPrice: (
            <div className='text-right'>
              {(order?.totalPrice || 0).toLocaleString()}
            </div>
          ),
          // option: optionsText.map((option) => <div key={option}>{option}</div>),
          platformSupportPrice: (
            <div className='text-right'>
              {platformSupportPrice.toLocaleString()}
            </div>
          ),
          platformSupportPriceRevenue: (
            <div className='text-right'>
              {platformSupportPriceRevenue.toLocaleString()}
            </div>
          ),
          shippingFee: (
            <div className='text-right'>
              {order?.shippingFee.toLocaleString()}
            </div>
          ),
          // address: `${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`,
          // detailAddress: order.addressInfo?.detailAddress,
          // recipient: Format.Recipient(order.addressInfo?.recipient),
          // recipientPhoneNumber: order.addressInfo?.recipientPhoneNumber,
          originallySettledAmount: (
            <div className='text-right'>
              {originallySettledAmount.toLocaleString()}
            </div>
          ),
          originalCommissionAmount: (
            <div className='text-right'>
              {originalCommissionAmount.toLocaleString()}
            </div>
          ),
          commissionRate: (
            <div className='text-right'>
              {Math.round(getCommissionRate(order) * 100 * 100) / 100}%
            </div>
          ),
          settlementAmount: (
            <div className='text-right'>
              {settlementAmount.toLocaleString()}
            </div>
          ),
          discountAmountForRevenueDiscount: (
            <div className='text-right'>
              {discountAmountForRevenueDiscount.toLocaleString()}
            </div>
          ),
          couponNotSettlementAmount: (
            <div className='text-right'>
              {couponNotSettlementAmount.toLocaleString()}
            </div>
          ),
          // otherSettlementAmount: (
          //   <div className='text-right'>
          //     {otherSettlementAmount.toLocaleString()}
          //   </div>
          // ),
          preShippingTimeStamp:
            order?.preShippingTimeStamp &&
            moment(order.preShippingTimeStamp).format('YYYY-MM-DD HH:mm'),
          arrivedTimeStamp:
            order.arrivedInfo?.recordedAt &&
            moment(order.arrivedInfo.recordedAt).format('YYYY-MM-DD HH:mm'),
          canceledTimeStamp:
            order.refundedInfo?.recordedAt || order.canceledInfo?.recordedAt
              ? moment(
                  order.refundedInfo?.recordedAt ||
                    order.canceledInfo?.recordedAt,
                ).format('YYYY-MM-DD HH:mm')
              : '-',
          orderConfirmedTimeStamp: moment(
            order.refundedInfo?.recordedAt ||
              order.canceledInfo?.recordedAt ||
              order.arrivedInfo?.recordedAt,
          )
            .add(7, 'days')
            .format('YYYY-MM-DD HH:mm'),
        }
      })
  }, [SellerStore.ordersForSettlements?.length, pageIndex])

  const settlementsColumns = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '정산 일자', accessor: 'settledAt' },
      { Header: '배송완료 기간', accessor: 'payedTimeInterval' },
      { Header: '판매 금액', accessor: 'totalPriceAmount' },
      { Header: '추가지원금', accessor: 'totalPlatformSupportPrice' },
      {
        Header: '특별 수수료(기타 매출)',
        accessor: 'totalPlatformSupportPriceRevenue',
      },
      {
        Header: '올웨이즈 부담 할인금',
        accessor: 'totalDiscountAmountForRevenueDiscount',
      },
      {
        Header: '판매자 부담 쿠폰 할인금',
        accessor: 'totalCouponNotSettlementAmount',
      },
      // {
      //   Header: '기타 정산금액',
      //   accessor: 'totalOtherSettlementAmount',
      // },
      { Header: '정산 제외 금액', accessor: 'settlementExceptAmount' },
      { Header: '기 정산 금액', accessor: 'totalOriginallySettledAmount' },
      { Header: '기 정산 수수료', accessor: 'totalOriginalCommissionAmount' },
      { Header: '정산대상금액(수수료 제)', accessor: 'totalSettlementAmount' },
      { Header: '수수료', accessor: 'totalCommissionAmount' },
      { Header: '내역 다운로드', accessor: 'downloadSettlementButton' },
      {
        Header: '정산제외 다운로드',
        accessor: 'downloadSettlementExceptButton',
      },
    ],
    [],
  )

  const settlementsData = useMemo(() => {
    const data = SellerStore.settlements
      ?.filter((settlement) => !settlement.errorType)
      ?.map((settlement, index) => {
        const totalCouponDiscountAmount = Number(
          settlement.totalCouponDiscountAmount || 0,
        )
        const totalCouponDiscountAmountForRevenueDiscount = Object.keys(
          settlement,
        )?.includes('totalCouponDiscountAmountForRevenueDiscount')
          ? Number(settlement?.totalCouponDiscountAmountForRevenueDiscount || 0)
          : Number(settlement?.totalCouponDiscountAmount || 0)

        const totalPlatformSupportPriceForRevenueDiscount = Object.keys(
          settlement,
        )?.includes('totalPlatformSupportPriceForRevenueDiscount')
          ? Number(settlement?.totalPlatformSupportPriceForRevenueDiscount || 0)
          : 0

        const totalDiscountAmountForRevenueDiscount =
          totalCouponDiscountAmountForRevenueDiscount +
          totalPlatformSupportPriceForRevenueDiscount

        const totalOtherSettlementAmount =
          totalCouponDiscountAmount - totalDiscountAmountForRevenueDiscount

        return {
          ...settlement,
          // 번호
          index: (
            <div className='text-right'>
              {index + 1 + PAGE_ORDER_NUM * pageIndex}
            </div>
          ),
          // 정산 일자
          settledAt: settlement.settlmntDt
            ? moment(settlement.settlmntDt, 'YYYYMMDD').format('YYYY-MM-DD')
            : moment(settlement.createdAt).format('YYYY-MM-DD'),
          // 배송완료 기간
          payedTimeInterval: `${moment(settlement.startTimeStamp).format(
            'YYYY-MM-DD',
          )} ~ ${moment(settlement.endTimeStamp)
            .subtract(1, 'seconds') // 데이터상 endTimestamp가 월요일 00:00 으로 찍힘 -> 화면에는 일요일 날짜로 표시하기 위해 1초를 빼줌
            .format('YYYY-MM-DD')}`,
          // 판매 금액
          totalPriceAmount: (
            <div className='text-right'>
              {(
                settlement.totalPriceAmount +
                settlement.totalShippingFee -
                settlement.totalRefundAmount
              ).toLocaleString()}
            </div>
          ),
          // 올웨이즈 부담 할인금
          totalDiscountAmountForRevenueDiscount: (
            <div className='text-right'>
              {totalDiscountAmountForRevenueDiscount.toLocaleString()}
            </div>
          ),
          // 정산 제외 금액
          settlementExceptAmount: (
            <div className='text-right'>
              {(settlement.settlementExceptAmount || 0).toLocaleString()}
            </div>
          ),
          // 판매자 부담 쿠폰 할인금
          totalCouponNotSettlementAmount: (
            <div className='text-right'>
              {(
                settlement.totalCouponNotSettlementAmount || 0
              ).toLocaleString()}
            </div>
          ),
          // 추가지원금
          totalPlatformSupportPrice: (
            <div className='text-right'>
              {Math.max(
                Number(settlement.totalPlatformSupportPrice || 0) +
                  Number(settlement.totalPlatformSupportPriceRevenue || 0) +
                  totalOtherSettlementAmount,
                0,
              ).toLocaleString()}
            </div>
          ),
          // 특별 수수료(기타 매출)
          totalPlatformSupportPriceRevenue: (
            <div className='text-right'>
              {Math.max(
                Number(settlement.totalPlatformSupportPriceRevenue || 0),
                0,
              ).toLocaleString()}
            </div>
          ),
          totalSettlementAmount: (
            <div className='text-right'>
              {settlement?.totalSettlementAmount?.toLocaleString()}
            </div>
          ),
          // 기타 정산금액
          // totalOtherSettlementAmount: (
          //   <div className='text-right'>
          //     {totalOtherSettlementAmount.toLocaleString()}
          //   </div>
          // ),
          // 정산대상금액(수수료 제)
          // totalSettlementAmount: (
          //   <div className='text-right'>
          //     {settlement?.totalSettlementAmount?.toLocaleString()}
          //   </div>
          // ),
          // 수수료
          // totalCommissionAmount: (
          //   <div className='text-right'>
          //     {settlement?.totalCommissionAmount?.toLocaleString()}
          //   </div>
          // ),
          downloadSettlementButton: (
            <Button
              size='sm'
              className='flex items-center whitespace-nowrap'
              appearance='gray'
              type='button'
              onClick={async () => {
                // const totalCouponDiscountAmountForRevenueDiscount =
                //   settlement.totalCouponDiscountAmount || 0

                const totalCommissionAmountRevenue =
                  settlement.totalCommissionAmount || 0

                const settlementExceptAmountRevenue =
                  (settlement.settlementExceptAmount || 0) -
                  (settlement.nonRevenueSettlementExceptAmount || 0)
                const totalPlatformSupportPriceRevenue =
                  settlement.totalPlatformSupportPriceRevenue || 0

                const leftOverDiscountAmount = Math.max(
                  0,
                  totalDiscountAmountForRevenueDiscount -
                    totalCommissionAmountRevenue -
                    totalPlatformSupportPriceRevenue,
                )

                const settlementOrdersInfo = []
                let loadOrders = true
                let index = 0
                while (loadOrders && index < 2000) {
                  index += 1
                  // 기존 api는 100개씩 slice해서 여러번 호출, 새로운 api는 한번에 호출
                  const appliedNewSettlement =
                    new Date(settlement.createdAt) > new Date('2024-12-09')
                  if (appliedNewSettlement) {
                    loadOrders = false
                  }

                  const loadResult =
                    await backendApis.loadSellerPastSettlementOrders(
                      settlement._id,
                      settlementOrdersInfo.length,
                    )
                  if (
                    loadResult?.status === 200 &&
                    loadResult.data?.length > 0
                  ) {
                    const filteredOrders = loadResult?.data?.filter((order) => {
                      if (!order) return false

                      return true
                    })

                    if (filteredOrders?.length > 0) {
                      settlementOrdersInfo.push(
                        ...filteredOrders?.map((order) => {
                          let platformSupportPrice = Math.max(
                            0,
                            Number(order?.platformSupportPrice || 0),
                          )
                          if (
                            Object.keys(order)?.includes(
                              'platformSupportPriceRevenue',
                            ) &&
                            order?.platformSupportPrice < 0 &&
                            order?.platformSupportPriceRevenue === 0
                          ) {
                            platformSupportPrice = Number(
                              order?.platformSupportPrice || 0,
                            )
                          }

                          const platformSupportPriceRevenue = Object.keys(
                            order,
                          )?.includes('platformSupportPriceRevenue')
                            ? Number(order?.platformSupportPriceRevenue)
                            : Math.max(
                                0,
                                -Number(order?.platformSupportPrice || 0),
                              )
                          const couponDiscountAmountForRevenueDiscount =
                            Object.keys(order)?.includes(
                              'couponDiscountAmountForRevenueDiscount',
                            )
                              ? Number(
                                  order?.couponDiscountAmountForRevenueDiscount ||
                                    0,
                                )
                              : Number(order?.couponDiscountAmount || 0)
                          const platformSupportPriceForRevenueDiscount =
                            Object.keys(order)?.includes(
                              'platformSupportPriceForRevenueDiscount',
                            )
                              ? Number(
                                  order?.platformSupportPriceForRevenueDiscount ||
                                    0,
                                )
                              : 0
                          const couponDiscountAmount = Number(
                            order?.couponDiscountAmount || 0,
                          )
                          const otherSettlementAmount =
                            couponDiscountAmount -
                            couponDiscountAmountForRevenueDiscount -
                            platformSupportPriceForRevenueDiscount

                          const discountAmountForRevenueDiscount =
                            couponDiscountAmountForRevenueDiscount +
                            platformSupportPriceForRevenueDiscount

                          return {
                            ...order,
                            _id: order.orderId || order._id,
                            platformSupportPrice:
                              platformSupportPrice + otherSettlementAmount,
                            platformSupportPriceRevenue,
                            discountAmountForRevenueDiscount,
                            // otherSettlementAmount,
                            cardOrMoney: order?.paymentType,
                            preShippingTimeStamp: order?.preShippingTimeStamp
                              ? moment(order?.preShippingTimeStamp).format(
                                  'YYYY-MM-DD HH:mm',
                                )
                              : '-',
                            arrivedTimeStamp: order?.arrivedTimeStamp
                              ? moment(order?.arrivedTimeStamp).format(
                                  'YYYY-MM-DD HH:mm',
                                )
                              : '-',
                            canceledTimeStamp: order?.canceledTimeStamp
                              ? moment(order?.canceledTimeStamp).format(
                                  'YYYY-MM-DD HH:mm',
                                )
                              : '-',
                            orderConfirmedTimeStamp:
                              order?.orderConfirmedTimeStamp
                                ? moment(order?.orderConfirmedTimeStamp).format(
                                    'YYYY-MM-DD HH:mm',
                                  )
                                : '-',
                          }
                        }),
                      )
                    }
                  } else {
                    loadOrders = false
                  }
                }
                if (settlement?.settlementExceptAmount) {
                  settlementOrdersInfo.push({
                    _id: '-',
                    addressId: 0,
                    itemTitle: '정산 제외 금액 (후기 이벤트 등 참여)',
                    totalPrice: 0,
                    shippingFee: 0,
                    paymentType: '-',
                    taxFreeAmount: 0,
                    couponDiscountAmountForRevenueDiscount: 0,
                    couponNotSettlementAmount: 0,
                    refundAmount: 0,
                    originallySettledAmount: 0,
                    originalCommissionAmount: 0,
                    settlementAmount: 0 - settlement.settlementExceptAmount,
                    commissionAmount: 0,
                    // otherSettlementAmount: 0,
                    preShippingTimeStamp: '-',
                    arrivedTimeStamp: '-',
                    canceledTimeStamp: '-',
                    orderConfirmedTimeStamp: '-',
                  })
                }
                settlementOrdersInfo.push({
                  _id: '-',
                  addressId: 0,
                  itemTitle: '올웨이즈 부담 할인금 (매출 차감 대상)',
                  totalPrice: 0,
                  shippingFee: 0,
                  paymentType: '-',
                  taxFreeAmount: 0,
                  discountAmountForRevenueDiscount:
                    totalDiscountAmountForRevenueDiscount -
                    leftOverDiscountAmount,
                  couponNotSettlementAmount: 0,
                  refundAmount: 0,
                  originallySettledAmount: 0,
                  originalCommissionAmount: 0,
                  settlementAmount: 0,
                  commissionAmount: 0,
                  // otherSettlementAmount: 0,
                  preShippingTimeStamp: '-',
                  arrivedTimeStamp: '-',
                  canceledTimeStamp: '-',
                  orderConfirmedTimeStamp: '-',
                })

                const workbook = new ExcelJS.Workbook()
                const worksheet = workbook.addWorksheet('매출 내역')
                const worksheetData = settlementOrdersInfo.map((order) => {
                  return {
                    ...order,
                    commissionRate: `${
                      Math.round(getCommissionRate(order) * 100 * 100) / 100
                    }%`,
                    platformSupportPrice: order.platformSupportPrice || 0,
                    addressId:
                      order?._id === '-'
                        ? ''
                        : `${Format.DateToday(order?.payedAt)}${
                            checkIfValidUUID(order?.addressInfo?.addressId) &&
                            translator.fromUUID(
                              order?.addressInfo?.addressId || order?._id,
                            )
                          }`.substring(2),
                  }
                })

                // if (
                //   settlement.totalPlatformSupportPrice &&
                //   !headers.find(
                //     (header) => header.key === 'platformSupportPrice',
                //   )
                // ) {
                //   headers.splice(6, 0, {
                //     header: '추가 지원금액',
                //     key: 'platformSupportPrice',
                //     width: 15,
                //   })
                // }
                worksheet.columns = headers
                worksheet.addRows(worksheetData)
                const headerRow = worksheet.getRow(1)
                headers.forEach((header, index) => {
                  headerRow.getCell(index + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFEA3F49' },
                  }
                })
                worksheet.getRow(1).font = {
                  color: { argb: 'FFFFFFFF' },
                  bold: true,
                }
                if (settlement?.settlementExceptAmount) {
                  headers.forEach((header, index) => {
                    worksheet
                      .getRow(settlementOrdersInfo.length)
                      .getCell(index + 1).fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: '20FFFF54' },
                    }
                  })
                }
                headers.forEach((header, index) => {
                  worksheet
                    .getRow(settlementOrdersInfo.length + 1)
                    .getCell(index + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '2092D051' },
                  }
                })
                const mimeType = {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }
                const buffer = await workbook.xlsx.writeBuffer()
                const blob = new Blob([buffer], mimeType)
                saveAs(
                  blob,
                  `${SellerStore.sellerInfo.sellerDisplayName}_매출 내역_${
                    settlement.settlmntDt
                      ? moment(settlement.settlmntDt, 'YYYYMMDD').format(
                          'YYYY-MM-DD',
                        )
                      : moment(settlement.createdAt).format('YYYY-MM-DD')
                  }.xlsx`,
                )
                if (SellerStore.loadSellerInfo())
                  Log.event(
                    'SettlemenftsDownloadButton',
                    'SettlemenftsDownloadButton',
                    {
                      click: 'SettlemenftsDownloadButton',
                    },
                  )
              }}
            >
              <SiGooglesheets className='mr-2' />
              매출내역 다운로드
            </Button>
          ),
          downloadSettlementExceptButton: (
            <Button
              size='sm'
              className='flex items-center whitespace-nowrap'
              appearance='gray'
              type='button'
              onClick={async () => {
                // ### 정산 제외 내역에 대한 데이터 정합성이 23.4.28일 부터 맞음.
                const possibleDate = new Date('2023-04.28')
                if (new Date(settlement.createdAt) > possibleDate) {
                  const settlementOrdersInfo = []
                  // 바로도착 정산내역을 위해 subType 필드가 추가되었음
                  const loadResult = await backendApis.loadSettlementExceptData(
                    settlement.createdAt,
                  )
                  if (
                    loadResult?.status === 200 &&
                    loadResult.data?.length > 0
                  ) {
                    /**
                     * @typedef {"fastArrivalDelivus" | "fastArrivalCjLogistics" | "alfarmPhotoReview" | "alfarm_quiz" | "alfarm_store_ad" | "newItemReviewEvent" | "alfarm_egg" | "alfarm_trendQuiz" | "etc"} SettlementExceptType
                     */
                    /**
                     *
                     * @param {SettlementExceptType} type
                     * @returns {number}
                     */
                    const getTotalAmount = (type) => {
                      // MEMO(민국): SettlementExceptType에 포함되지 않은 경우를 etc에 합산하여 표시
                      if (type === 'etc') {
                        const typeList = [
                          'alfarmPhotoReview',
                          'alfarm_quiz',
                          'alfarm_store_ad',
                          'newItemReviewEvent',
                          'alfarm_egg',
                          'alfarm_trendQuiz',
                          'fastArrivalCjLogistics',
                          'fastArrivalDelivus',
                        ]
                        const etcData = loadResult?.data?.filter(
                          (e) => !typeList.includes(e?._id),
                        )
                        const etcTotalAmount = etcData?.reduce(
                          (total, item) => total + (item?.totalAmount || 0),
                          0,
                        )
                        return etcTotalAmount
                      }

                      return loadResult?.data?.filter(
                        (e) => e?._id === type,
                      )?.[0]?.totalAmount
                    }

                    settlementOrdersInfo.push({
                      alfarmPhotoReview: getTotalAmount('alfarmPhotoReview'),
                      alfarmQuiz: getTotalAmount('alfarm_quiz'),
                      alfarmStore: getTotalAmount('alfarm_store_ad'),
                      newItemReviewEvent: getTotalAmount('newItemReviewEvent'),
                      alfarmEgg: getTotalAmount('alfarm_egg'),
                      alfarmTrendQuiz: getTotalAmount('alfarm_trendQuiz'),
                      fastArrivalCjLogistics: getTotalAmount(
                        'fastArrivalCjLogistics',
                      ),
                      fastArrivalDelivus: getTotalAmount('fastArrivalDelivus'),
                      etc: getTotalAmount('etc'),
                      settlementDate: moment(settlement.createdAt).format(
                        'YYYY-MM-DD',
                      ),
                    })
                  }

                  const workbook = new ExcelJS.Workbook()
                  const worksheet = workbook.addWorksheet('매출 내역')
                  const worksheetData = settlementOrdersInfo.map((order) => {
                    return {
                      ...order,
                    }
                  })
                  worksheet.columns = settlementExceptHeaders
                  worksheet.addRows(worksheetData)
                  const headerRow = worksheet.getRow(1)
                  settlementExceptHeaders.forEach((header, index) => {
                    headerRow.getCell(index + 1).fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'FFEA3F49' },
                    }
                  })
                  worksheet.getRow(1).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true,
                  }
                  if (settlementOrdersInfo?.length > 0) {
                    settlementExceptHeaders.forEach((header, index) => {
                      worksheet
                        .getRow(settlementOrdersInfo.length + 1)
                        .getCell(index + 1).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '20FFFF54' },
                      }
                    })
                  }
                  const mimeType = {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  }
                  const buffer = await workbook.xlsx.writeBuffer()
                  const blob = new Blob([buffer], mimeType)
                  saveAs(
                    blob,
                    `${
                      SellerStore.sellerInfo.sellerDisplayName
                    }_정산제외 내역_${moment(settlement.createdAt).format(
                      'YYYY-MM-DD',
                    )}.xlsx`,
                  )
                  if (SellerStore.loadSellerInfo())
                    Log.event(
                      'SettlementExceptDownloadButton',
                      'SettlementExceptDownloadButton',
                      {
                        click: 'SettlementExceptDownloadButton',
                      },
                    )
                } else {
                  alert(
                    '정산 제외 금액에 대한 구체적인 내용은 2023.04.28일 이후로 볼 수 있습니다. 정산 제외 금액은 사진 후기, 올팜 상점, 신상품 매대 체험단, 올팜 퀴즈로 구성되어있습니다',
                  )
                }
              }}
            >
              <SiGooglesheets className='mr-2' />
              정산제외 내역 다운로드
            </Button>
          ),
        }
      })

    return data?.sort((a, b) => {
      const aIndex = parseInt(a.index.props.children, 10)
      const bIndex = parseInt(b.index.props.children, 10)
      return sortOrder === 'desc' ? bIndex - aIndex : aIndex - bIndex
    })
  }, [SellerStore.settlements?.length, sortOrder])

  const tabContArr = [
    {
      title: `정산대상 주문내역 (${SellerStore.ordersForSettlements.length})`,
      content: (
        <>
          <Table
            columns={ordersForSettlementsColumns}
            data={ordersForSettlementsData}
          />
          <Pagination
            page={pageIndex}
            setPage={setPageIndex}
            total={SellerStore.ordersForSettlements.length}
            limit={PAGE_ORDER_NUM}
          />
        </>
      ),
    },
    {
      title: `정산 내역 (${settlementsData.length})`,
      content: (
        <div>
          <div className='flex justify-end mb-4'>
            <Button
              size='sm'
              appearance='gray'
              onClick={() =>
                setSortOrder((prev) => (prev === 'desc' ? 'asc' : 'desc'))
              }
            >
              {sortOrder === 'desc' ? '오름차순 정렬' : '내림차순 정렬'}
            </Button>
          </div>
          <Table columns={settlementsColumns} data={settlementsData} />
        </div>
      ),
    },
  ]

  return (
    <>
      <Title text='정산현황' />
      {/* MEMO(민국): 우진이가 공지 작업 이후에 다시 활용하거나 지우기로 함, 한달 이후 먼 미래에 발견하게 되었다면 그냥 지워도 됨 */}
      {/* <h2 className='pb-5 text-xl font-bold text-red-500'>
        설 특가 신청 상품의 수수료는 안내드렸던 대로 15%(PG수수료 포함, 부가세
        별도)로 추후 계산되어 정산 예정입니다.
      </h2>
      <h2 className='pb-5 text-xl font-bold text-red-500'>
        설 특가로 판매된 상품은 현재 주문건별 표기되는 수수료와 상이합니다. 세금
        계산서 발행 등 정상적으로 처리될 예정입니다.
      </h2> */}

      <div className='flex flex-col items-center w-full pb-5 space-y-4 md:space-x-4 md:space-y-0 md:flex-row relative'>
        {showSettlementComplete && (
          <div className='absolute h-36 min-w-[405px] top-0 left-4 bg-gray-900/90 rounded-2xl flex items-center justify-center z-10'>
            <span className='text-white text-xl font-bold'>정산 완료</span>
          </div>
        )}
        <Statistics
          index='정산예정'
          number={
            SellerStore.loadingDueSettlements || showSettlementComplete
              ? ' Loading...'
              : `${commaNumber(SellerStore.settlementConfirmAmount || 0)} `
          }
          unit='원'
        />
        <Statistics
          index='정산대상 기간'
          tooltip='해당 기간의 배송 및 취소완료 주문건이 정산됩니다.'
          number={`${startTimeStamp.getFullYear()}.${
            startTimeStamp.getMonth() + 1
          }.${startTimeStamp.getDate()} 
          ~ ${endTimeStamp.getFullYear()}.${
            endTimeStamp.getMonth() + 1
          }.${endTimeStamp.getDate()}`}
        />
        <div className='relative mx-auto bg-white h-36 shadow-lg rounded-2xl border-indigo-500 text-center p-4 min-w-[405px]'>
          {showSettlementComplete && (
            <div className='absolute h-36 min-w-[405px] top-0 left-0 bg-gray-900/90 rounded-2xl flex items-center justify-center z-10'>
              <span className='text-white text-xl font-bold'>정산 완료</span>
            </div>
          )}
          <div className='flex items-center justify-between pb-2 mb-2 space-x-12 text-md md:space-x-24'>
            <p className='text-black whitespace-nowrap'>정산대상</p>
            <div className='flex items-end font-semibold text-gray-700 whitespace-nowrap'>
              {SellerStore.loadingDueSettlements
                ? ' Loading...'
                : `${commaNumber(SellerStore.settlementAmount || 0)}`}{' '}
              원
            </div>
          </div>
          <div className='flex items-center justify-between pb-2 mb-2 space-x-12 text-md md:space-x-24'>
            <p className='text-black whitespace-nowrap'>정산제외</p>
            <div className='flex items-end font-semibold text-gray-700 whitespace-nowrap'>
              {SellerStore.loadingDueSettlements || showSettlementComplete
                ? ' Loading...'
                : `${commaNumber(
                    SellerStore.settlementExceptAmount || 0,
                  )}`}{' '}
              원
            </div>
          </div>
          <div className='flex justify-end pb-2 mb-2 space-x-12 md:space-x-24'>
            <a
              href='https://drive.google.com/uc?id=155oDG6sEa_ahjVb9-jLZnwB1WABGwCpC&export=download'
              target='_blank'
              rel='noreferrer'
            >
              <button
                type='button'
                className='w-full px-4 py-2 border rounded-lg hover:bg-gray-100'
              >
                정산/세금계산서 안내 다운로드
              </button>
            </a>
          </div>
        </div>
      </div>
      <Button
        size='sm'
        className='flex items-center whitespace-nowrap'
        appearance='gray'
        type='button'
        onClick={async () => {
          const workbook = new ExcelJS.Workbook()
          const worksheet = workbook.addWorksheet('정산대상 주문내역')
          const worksheetData = SellerStore.ordersForSettlements.map(
            (order) => {
              return Format.formatOrderForSettlementExcel(order)
            },
          )

          // if (
          //   SellerStore.ordersForSettlements?.some(
          //     (order) => !!order?.selectedOption?.platformSupportPrice,
          //   ) &&
          //   !headers.find((header) => header.key === 'platformSupportPrice')
          // ) {
          //   headers.splice(6, 0, {
          //     header: '추가 지원금액',
          //     key: 'platformSupportPrice',
          //     width: 15,
          //   })
          // }
          worksheet.columns = headers
          worksheet.addRows(worksheetData)
          const headerRow = worksheet.getRow(1)
          headers.forEach((header, index) => {
            headerRow.getCell(index + 1).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFEA3F49' },
            }
          })
          worksheet.getRow(1).font = {
            color: { argb: 'FFFFFFFF' },
            bold: true,
          }

          const mimeType = {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }
          const buffer = await workbook.xlsx.writeBuffer()
          const blob = new Blob([buffer], mimeType)
          saveAs(
            blob,
            `${
              SellerStore.sellerInfo.sellerDisplayName
            }_정산대상 주문내역_${moment(settlementsData.createdAt).format(
              'YYYY-MM-DD',
            )}.xlsx`,
          )
          if (SellerStore.loadSellerInfo())
            Log.event(
              'SettlemenftsDownloadButton',
              'SettlemenftsDownloadButton',
              {
                click: 'SettlemenftsDownloadButton',
              },
            )
        }}
      >
        <SiGooglesheets className='mr-2' />
        정산대상 주문내역 다운로드
      </Button>
      <Tabs tabContArr={tabContArr} />
    </>
  )
})

export default SettlementsScreen
