import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import {
  Card,
  Row,
  Col,
  Button,
  Table,
  Modal,
  Tag,
  message,
  Tabs,
  Checkbox,
} from 'antd'
import moment from 'moment'
import backendApis from '../../../utils/backendApis'
import RadioGroup from '../../../components/ADS/molecules/radioGroup'
import { CopyOutlined, ReloadOutlined } from '@ant-design/icons'
import DatePicker from 'react-tailwindcss-datepicker'
import {
  CpmAdAnalyticsGraph,
  CpmAdAnalyticsGraphSecondary,
} from '../../../components/molecules/CpmAdAnalyticsGraph'
import CpmAdRewardNoticeModal from './CpmAdRewardNoticeModal'

const REFRESH_COOLDOWN = 30
const REFRESH_TIMESTAMP_KEY = 'cpmAdAnalytics_lastRefreshTime'
const DEFAULT_PAGE_SIZE = 20

const CpmAdAnalyticsSection = ({ activeTab, refreshTrigger }) => {
  const [dateRange, setDateRange] = useState(() => [
    moment().subtract(30, 'days'),
    moment(),
  ])
  const [dateOption, setDateOption] = useState('custom')
  const [selectedAdSet, setSelectedAdSet] = useState(null)
  const [analyticsData, setAnalyticsData] = useState([])
  const [loading, setLoading] = useState(true)
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false)
  const [totalStats, setTotalStats] = useState({
    totalImpressions: 0,
    totalSpentBudget: 0,
    totalRevenue: 0,
  })
  const [detailLoading, setDetailLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [total, setTotal] = useState(0)
  const [allAdSets, setAllAdSets] = useState([])
  const [displayedAdSets, setDisplayedAdSets] = useState([])
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(() => {
    const lastRefreshTime = localStorage.getItem(REFRESH_TIMESTAMP_KEY)
    if (!lastRefreshTime) return false

    const timeSinceLastRefresh =
      (Date.now() - parseInt(lastRefreshTime, 10)) / 1000
    return timeSinceLastRefresh < REFRESH_COOLDOWN
  })

  const [remainingTime, setRemainingTime] = useState(0)
  const [selectedMetrics, setSelectedMetrics] = useState([
    'impressions',
    'revenue',
  ])

  const dateOptions = [
    { label: '전체 기간', value: 'all' },
    { label: '날짜 선택', value: 'custom' },
  ]

  const isFetching = useRef(false) // API 호출 중복 방지 플래그

  const calculateTotalStats = useCallback((data) => {
    const stats = data.reduce(
      (acc, curr) => {
        return {
          totalImpressions:
            acc.totalImpressions + (curr.analytics?.impressions || 0),
          totalSpentBudget:
            acc.totalSpentBudget + (curr.analytics?.spentBudget || 0),
          totalRevenue: acc.totalRevenue + (curr.analytics?.revenue || 0),
        }
      },
      { totalImpressions: 0, totalSpentBudget: 0, totalRevenue: 0 },
    )
    setTotalStats(stats)
  }, [])

  const fetchAdSets = useCallback(async () => {
    if (isFetching.current) {
      console.warn('중복 호출 방지: fetchAdSets 이미 실행 중')
      return // 중복 호출 방지
    }
    isFetching.current = true

    setLoading(true)
    try {
      const [startDate, endDate] = dateRange
      const params = {
        size: total || pageSize,
        page: 1,
        ...(dateOption === 'custom' && {
          startDate: startDate?.format('YYYY-MM-DD'),
          endDate: endDate?.format('YYYY-MM-DD'),
        }),
      }

      const res = await backendApis.getCpmAdSetList(params)

      if (res?.status === 200) {
        const filteredAdSets = res.data.filter((adSet) =>
          ['active', 'completed', 'cancelledAfterActive'].includes(
            adSet.status,
          ),
        )

        if (filteredAdSets.length > 0) {
          const adSetIds = filteredAdSets.map((adSet) => adSet._id)
          const analyticsRes = await backendApis.getCpmAdSetsAnalyticsBulk({
            adSetIds,
          })

          if (analyticsRes?.status === 200) {
            const analyticsMap = analyticsRes.data.adSetAnalyticsList
            const adSetsWithAnalytics = filteredAdSets.map((adSet) => ({
              ...adSet,
              analytics: analyticsMap[adSet._id] || {
                impressions: 0,
                spentBudget: 0,
                revenue: 0,
              },
            }))
            setAllAdSets(adSetsWithAnalytics)
            calculateTotalStats(adSetsWithAnalytics)
            updateDisplayedAdSets(1, pageSize, adSetsWithAnalytics)
            setTotal(adSetsWithAnalytics.length)
            message.success('광고 데이터 검색 완료')
          }

          if (analyticsRes?.status === 404) {
            setAllAdSets([])
            setTotal(0)
            setDisplayedAdSets([])
            setAnalyticsData([])
            calculateTotalStats([])
            message.success('해당 기간 광고 데이터가 없습니다.')
          }
        } else {
          setAllAdSets([])
          setTotal(0)
          setDisplayedAdSets([])
          setAnalyticsData([])
          calculateTotalStats([])
          message.success('광고 세트 데이터를 불러오는데 실패했습니다.')
        }
      }
    } catch (error) {
      console.error('Error fetching ad sets:', error)
      message.error('광고 세트 데이터를 불러오는데 실패했습니다.')
    } finally {
      isFetching.current = false // 호출 완료 후 초기화
      setLoading(false)
    }
  }, [dateRange, dateOption, total, pageSize, calculateTotalStats])

  const updateDisplayedAdSets = (page, pageSize, data = allAdSets) => {
    const start = (page - 1) * pageSize
    const end = start + pageSize

    setDisplayedAdSets(data.slice(start, end))
    setCurrentPage(page)
    setPageSize(pageSize)
  }

  const handleTableChange = (pagination, filters, sorter) => {
    console.log('Table change:', { pagination, filters, sorter })

    let sortedData = [...allAdSets]
    if (sorter && sorter.columnKey) {
      sortedData = applySorting(sortedData, sorter)
    }

    setAllAdSets(sortedData)
    updateDisplayedAdSets(pagination.current, pagination.pageSize, sortedData)
  }

  const applySorting = (data, sorter) => {
    if (!sorter || !sorter.columnKey) return data

    return [...data].sort((a, b) => {
      const { columnKey, order } = sorter
      let comparison = 0

      const getAnalyticsValue = (item, field) => {
        return item.analytics?.[field] || 0
      }

      switch (columnKey) {
        case 'startDate':
          comparison =
            moment(a.startDate).valueOf() - moment(b.startDate).valueOf()
          break
        case 'impressions':
          comparison =
            getAnalyticsValue(a, 'impressions') -
            getAnalyticsValue(b, 'impressions')
          break
        case 'spentBudget':
          comparison =
            getAnalyticsValue(a, 'spentBudget') -
            getAnalyticsValue(b, 'spentBudget')
          break
        case 'revenue':
          comparison =
            getAnalyticsValue(a, 'revenue') - getAnalyticsValue(b, 'revenue')
          break
        case 'roas': {
          const roasA =
            getAnalyticsValue(a, 'spentBudget') > 0
              ? (getAnalyticsValue(a, 'revenue') /
                  getAnalyticsValue(a, 'spentBudget')) *
                100
              : 0
          const roasB =
            getAnalyticsValue(b, 'spentBudget') > 0
              ? (getAnalyticsValue(b, 'revenue') /
                  getAnalyticsValue(b, 'spentBudget')) *
                100
              : 0
          comparison = roasA - roasB
          break
        }
        default:
          return 0
      }

      return order === 'descend' ? -comparison : comparison
    })
  }

  const columns = useMemo(
    () => [
      {
        title: '광고 세트 ID',
        key: 'id',
        width: '120px',
        render: (_, record) => (
          <Button
            type='link'
            onClick={() => handleAdSetSelect(record)}
            className='text-blue-500 hover:text-blue-700'
          >
            <span>{record._id.slice(-8)}</span>
          </Button>
        ),
      },
      {
        title: '광고 세트',
        key: 'adSet',
        width: '120px',
        render: (_, record) => (
          <span>{record.cpmItemIds?.length || 0}개 상품 광고</span>
        ),
      },
      {
        title: '상태',
        key: 'status',
        width: '80px',
        align: 'center',
        render: (_, record) => getStatusTag(record.status),
      },
      {
        title: '광고 기간',
        key: 'startDate',
        width: '180px',
        render: (_, record) => {
          const startDate = moment(record.startDate).format('YYYY-MM-DD')
          const endDate = moment(record.endDate)
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
          return (
            <div>
              {startDate} ~ {endDate}
            </div>
          )
        },
      },
      {
        title: '최대 노출 수',
        dataIndex: 'maxImpressions',
        key: 'maxImpressions',
        width: '120px',
        align: 'right',
        render: (_, record) => record.maxImpressions?.toLocaleString() || '-',
      },
      {
        title: '현재 노출 수',
        dataIndex: ['analytics', 'impressions'],
        key: 'impressions',
        width: '120px',
        align: 'right',
        sorter: true,
        render: (_, record) => {
          const impressions = record.analytics?.impressions || 0
          const percentage = record.maxImpressions
            ? ((impressions / record.maxImpressions) * 100).toFixed(1)
            : 0
          return (
            <div>
              <div>{impressions.toLocaleString()}</div>
              <div className='text-xs text-gray-500'>({percentage}%)</div>
            </div>
          )
        },
      },
      {
        title: '소진 예산',
        dataIndex: ['analytics', 'spentBudget'],
        key: 'spentBudget',
        width: '120px',
        align: 'right',
        sorter: true,
        render: (_, record) =>
          `₩${Math.ceil(record.analytics?.spentBudget || 0).toLocaleString()}`,
      },
      {
        title: '매출',
        dataIndex: ['analytics', 'revenue'],
        key: 'revenue',
        width: '120px',
        align: 'right',
        sorter: true,
        render: (_, record) =>
          `₩${Math.floor(record.analytics?.revenue || 0).toLocaleString()}`,
      },
      {
        title: 'ROAS',
        key: 'roas',
        width: '100px',
        align: 'right',
        sorter: true,
        render: (_, record) => {
          const roas =
            record.analytics?.spentBudget > 0
              ? (
                  (record.analytics.revenue / record.analytics.spentBudget) *
                  100
                ).toFixed(0)
              : 0
          return `${roas}%`
        },
      },
    ],
    [],
  )

  const handleAdSetSelect = async (adSet) => {
    setSelectedAdSet(adSet)
    setIsDetailModalVisible(true)
    setDetailLoading(true)

    try {
      const analyticsRes = await backendApis.getCpmAdSetAnalytics({
        adSetId: adSet._id,
      })
      if (analyticsRes?.status === 200) {
        setAnalyticsData(analyticsRes.data)
      }
    } catch (error) {
      console.error('Error fetching analytics data:', error)
    } finally {
      setDetailLoading(false)
    }
  }

  const handleDateOptionChange = (e) => {
    setDateOption(e.target.value)
    if (e.target.value === 'all') {
      setDateRange([null, null])
      fetchAdSets()
    } else if (e.target.value === 'custom') {
      const newDateRange = [moment().subtract(30, 'days'), moment()]
      setDateRange(newDateRange)
    }
  }

  const handleRefresh = () => {
    if (isRefreshDisabled) return

    localStorage.setItem(REFRESH_TIMESTAMP_KEY, Date.now().toString())
    setIsRefreshDisabled(true)
    setRemainingTime(REFRESH_COOLDOWN)

    // 타이머 시작
    const timer = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev <= 1) {
          clearInterval(timer)
          setIsRefreshDisabled(false)
          return 0
        }
        return prev - 1
      })
    }, 1000)

    // 데이터 새로고침
    fetchAdSets()
  }

  const handleSearch = () => {
    if (dateOption === 'custom' && (!dateRange[0] || !dateRange[1])) {
      message.warning('날짜를 선택해주세요')
      return
    }
    setCurrentPage(1)
    fetchAdSets()
  }

  // 새로고침 타이머 관리
  useEffect(() => {
    const lastRefreshTime = parseInt(
      localStorage.getItem(REFRESH_TIMESTAMP_KEY) || '0',
      10,
    )
    const timeSinceLastRefresh = (Date.now() - lastRefreshTime) / 1000

    if (timeSinceLastRefresh < REFRESH_COOLDOWN) {
      setIsRefreshDisabled(true)
      setRemainingTime(Math.ceil(REFRESH_COOLDOWN - timeSinceLastRefresh))

      const timer = setInterval(() => {
        setRemainingTime((prev) => {
          if (prev <= 1) {
            clearInterval(timer)
            setIsRefreshDisabled(false)
            return 0
          }
          return prev - 1
        })
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [])

  // refreshTrigger 변경 시 데이터 새로고침
  useEffect(() => {
    if (refreshTrigger) {
      const timer = setTimeout(() => {
        fetchAdSets()
      }, 300)

      return () => clearTimeout(timer)
    }
  }, [refreshTrigger])

  useEffect(() => {
    setCurrentPage(1)
    // 직접 호출하는 대신 디바운스 적용
    const timer = setTimeout(() => {
      fetchAdSets(1)
    }, 300)

    return () => clearTimeout(timer)
  }, [dateRange, dateOption, refreshTrigger])

  const renderTotalStats = () => {
    const roas =
      totalStats.totalSpentBudget > 0
        ? (
            (totalStats.totalRevenue / totalStats.totalSpentBudget) *
            100
          ).toFixed(0)
        : 0

    return (
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card bordered={false} className='bg-gray-50'>
            <div className='mb-1 text-sm text-gray-600'>총 노출 수</div>
            <div className='text-xl font-bold text-blue-600'>
              {totalStats.totalImpressions.toLocaleString()}
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} className='bg-gray-50'>
            <div className='mb-1 text-sm text-gray-600'>총 소진 예산</div>
            <div className='text-xl font-bold text-orange-600'>
              ₩{Math.ceil(totalStats.totalSpentBudget).toLocaleString()}
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} className='bg-gray-50'>
            <div className='mb-1 text-sm text-gray-600'>총 매출</div>
            <div className='text-xl font-bold text-green-600'>
              ₩{Math.floor(totalStats.totalRevenue).toLocaleString()}
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} className='bg-gray-50'>
            <div className='mb-1 text-sm text-gray-600'>ROAS</div>
            <div className='text-xl font-bold text-purple-600'>{roas}%</div>
            <div className='text-xs text-gray-500'>(광고비 대비 매출)</div>
          </Card>
        </Col>
      </Row>
    )
  }

  const handleMetricsChange = (checkedValues) => {
    setSelectedMetrics(checkedValues)
  }

  const renderPerformanceGraphs = () => {
    return (
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title='일별 광고 성과'>
            <CpmAdAnalyticsGraph
              data={allAdSets?.map((adSet) => adSet.analytics?.dailyStats)}
              loading={detailLoading}
              isUnderMaintenance={false}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card title='성과 지표별 추이'>
            <div className='mb-4'>
              <Checkbox.Group
                value={selectedMetrics}
                onChange={handleMetricsChange}
              >
                <Checkbox value='impressions'>노출수</Checkbox>
                <Checkbox value='spentBudget'>소진액</Checkbox>
                <Checkbox value='revenue'>매출</Checkbox>
                <Checkbox value='roas'>ROAS</Checkbox>
              </Checkbox.Group>
            </div>
            <CpmAdAnalyticsGraphSecondary
              data={allAdSets?.map((adSet) => adSet.analytics?.dailyStats)}
              loading={detailLoading}
              selectedMetrics={selectedMetrics}
              isUnderMaintenance={false}
            />
          </Card>
        </Col>
      </Row>
    )
  }

  const renderTableContent = () => {
    return (
      <Card>
        <Table
          columns={columns}
          dataSource={displayedAdSets}
          loading={loading}
          onChange={handleTableChange}
          pagination={{
            current: currentPage,
            pageSize,
            total,
            showSizeChanger: true,
          }}
        />
      </Card>
    )
  }

  const itemColumns = [
    {
      title: '상품명',
      key: 'itemName',
      width: '35%',
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      render: (_, record) => (
        <div className='flex items-center'>
          <img
            src={record.itemImage}
            alt={record.itemName}
            className='flex-shrink-0 object-cover w-10 h-10 mr-2 rounded'
          />
          <span className='truncate' title={record.itemName}>
            {record.itemName}
          </span>
        </div>
      ),
    },
    {
      title: '노출 수',
      dataIndex: 'impressions',
      key: 'impressions',
      width: '15%',
      align: 'right',
      sorter: (a, b) => (a.impressions || 0) - (b.impressions || 0),
      render: (imp) => (imp || 0).toLocaleString(),
    },
    {
      title: '소진 예산',
      dataIndex: 'spentBudget',
      key: 'spentBudget',
      width: '20%',
      align: 'right',
      sorter: (a, b) => (a.spentBudget || 0) - (b.spentBudget || 0),
      render: (spent) => `₩${Math.ceil(spent || 0).toLocaleString()}`,
    },
    {
      title: '매출',
      dataIndex: 'revenue',
      key: 'revenue',
      width: '20%',
      align: 'right',
      sorter: (a, b) => (a.revenue || 0) - (b.revenue || 0),
      render: (revenue) => `₩${Math.floor(revenue || 0).toLocaleString()}`,
    },
    {
      title: 'ROAS',
      key: 'roas',
      width: '10%',
      align: 'right',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const roasA = a.spentBudget > 0 ? (a.revenue / a.spentBudget) * 100 : 0
        const roasB = b.spentBudget > 0 ? (b.revenue / b.spentBudget) * 100 : 0
        return roasA - roasB
      },
      render: (_, record) => {
        const roas =
          record.spentBudget > 0
            ? ((record.revenue / record.spentBudget) * 100).toFixed(0)
            : 0
        return `${roas}%`
      },
    },
  ]

  const tabItems = [
    {
      key: 'summary',
      label: '요약',
      children: renderTotalStats(),
    },
    {
      key: 'performance',
      label: '성과 그래프',
      children: renderPerformanceGraphs(),
    },
    {
      key: 'detail',
      label: '상세 데이터',
      children: renderTableContent(),
    },
  ]

  const getStatusTag = (status) => {
    const statusConfig = {
      active: {
        color: 'green',
        text: '진행중',
      },
      completed: {
        color: 'blue',
        text: '완료',
      },
      pending: {
        color: 'gold',
        text: '대기중',
      },
      rejected: {
        color: 'red',
        text: '반려',
      },
      cancelled: {
        color: 'default',
        text: '취소',
      },
      cancelledAfterActive: {
        color: 'purple',
        text: '중단',
      },
      draft: {
        color: 'default',
        text: '임시저장',
      },
    }

    const config = statusConfig[status] || {
      color: 'default',
      text: '알 수 없음',
    }

    return (
      <Tag color={config.color} className='min-w-[60px] text-center'>
        {config.text}
      </Tag>
    )
  }

  return (
    <div className='space-y-6'>
      <div className='mb-6'>
        <div className='flex items-center justify-between mb-4'>
          <div className='flex gap-8'>
            <div className='max-w-xs'>
              <RadioGroup
                options={dateOptions}
                value={dateOption}
                onChange={handleDateOptionChange}
              />
            </div>
          </div>
          <Button
            onClick={handleRefresh}
            icon={<ReloadOutlined spin={isRefreshDisabled} />}
            loading={loading}
            disabled={isRefreshDisabled}
          >
            {isRefreshDisabled
              ? `${remainingTime}초 후 새로고침 가능`
              : '새로고침'}
          </Button>
        </div>
        {dateOption === 'custom' && (
          <div className='flex items-center gap-4 mt-4'>
            <span className='text-[0.9vw] font-bold'>광고 시작일</span>
            <div>
              <DatePicker
                value={{
                  startDate: dateRange[0]?.format('YYYY-MM-DD'),
                  endDate: dateRange[1]?.format('YYYY-MM-DD'),
                }}
                onChange={(newValue) => {
                  setDateRange([
                    newValue.startDate ? moment(newValue.startDate) : null,
                    newValue.endDate ? moment(newValue.endDate) : null,
                  ])
                }}
                displayFormat='YYYY년 MM월 DD일'
                placeholder='조회 간 선택'
                i18n='ko'
                inputClassName='rounded-md focus:ring-0 dark:bg-white dark:placeholder:text-gray-400 dark:border-gray-300 dark:text-gray-800'
                showShortcuts
                configs={{
                  shortcuts: {
                    today: '오늘',
                    yesterday: '어제',
                    currentMonth: '이번 달',
                    pastMonth: '지난 달',
                    past: (period) => `지난 ${period}일`,
                  },
                }}
                containerClassName='w-[18vw]'
                style={{ width: '12rem' }}
              />
            </div>
            <Button
              type='primary'
              onClick={handleSearch}
              disabled={
                dateOption === 'custom' && (!dateRange[0] || !dateRange[1])
              }
            >
              조회하기
            </Button>
          </div>
        )}
      </div>

      <Tabs items={tabItems} defaultActiveKey='summary' />

      <Modal
        title='광고 세트 상세 분석'
        open={isDetailModalVisible}
        onCancel={() => {
          setIsDetailModalVisible(false)
          setSelectedAdSet(null)
        }}
        width={1200}
        footer={null}
      >
        {selectedAdSet && (
          <div className='space-y-6'>
            <div className='flex items-center gap-2 mb-4'>
              <span className='font-bold'>광고 세트 ID:</span>
              <span className='text-gray-600'>{selectedAdSet._id}</span>
              <Button
                type='text'
                icon={<CopyOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(selectedAdSet._id)
                  message.success('ID가 복사되었습니다')
                }}
                className='text-gray-400 hover:text-gray-600'
              />
            </div>
            <div className='flex items-center gap-2 mb-4'>
              <span className='font-bold'>광고 기간:</span>
              <span className='text-gray-600'>
                {moment(selectedAdSet.startDate).format('YYYY-MM-DD')} ~{' '}
                {moment(selectedAdSet.endDate)
                  .subtract(1, 'days')
                  .format('YYYY-MM-DD')}
              </span>
            </div>

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Card>
                  <div className='mb-2 text-lg font-bold'>총 노출 수</div>
                  <div className='text-2xl text-blue-600'>
                    {selectedAdSet.analytics?.impressions?.toLocaleString() ||
                      '0'}
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <div className='mb-2 text-lg font-bold'>소진 예산</div>
                  <div className='text-2xl text-orange-600'>
                    ₩
                    {Math.ceil(
                      selectedAdSet.analytics?.spentBudget || 0,
                    ).toLocaleString()}
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <div className='mb-2 text-lg font-bold'>매출</div>
                  <div className='text-2xl text-green-600'>
                    ₩
                    {Math.floor(
                      selectedAdSet.analytics?.revenue || 0,
                    ).toLocaleString()}
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <div className='mb-2 text-lg font-bold'>ROAS</div>
                  <div className='text-2xl text-purple-600'>
                    {selectedAdSet.analytics?.spentBudget > 0
                      ? (
                          (selectedAdSet.analytics.revenue /
                            selectedAdSet.analytics.spentBudget) *
                          100
                        ).toFixed(0)
                      : '0'}
                    %
                  </div>
                  <div className='text-xs text-gray-500'>
                    (광고비 대비 매출)
                  </div>
                </Card>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                {selectedAdSet.cpmItemIds?.length > 0 ? (
                  <Card title='상품별 성과'>
                    <Table
                      columns={itemColumns}
                      dataSource={analyticsData?.adSetAnalytics?.items || []}
                      rowKey='itemId'
                      pagination={false}
                      scroll={{ y: 400 }}
                      loading={detailLoading}
                      onChange={(pagination, filters, sorter) => {
                        console.log('정렬 변경:', sorter)
                      }}
                      defaultSortOrder='descend'
                      sortDirections={['descend', 'ascend']}
                      defaultSortField='roas'
                    />
                  </Card>
                ) : (
                  <div className='flex items-center justify-center p-8 bg-white rounded-xl'>
                    <div className='text-xl text-gray-500'>
                      상품별 성과 데이터가 없습니다.
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Modal>
      {activeTab === '3' && <CpmAdRewardNoticeModal />}
    </div>
  )
}

export default CpmAdAnalyticsSection
