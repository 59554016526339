import moment from 'moment'
import backendApis from './backendApis'
import * as ExcelJS from 'exceljs'
import Format from './format'
import shortUUID from 'short-uuid'
import {
  getCouponNotSettlementAmount,
  getCouponSettlementAmount,
  getSettlementAmount,
} from '../helper/order'
import { saveAs } from 'file-saver'
import SellerStore from '../stores/SellerStore'
import tableUtils from './tableUtils'
import {
  possiblePickUpPostCode,
  possibleRecipientPostCode,
} from './fastArrivalPostCode'
import React from 'react'
import { getTotalRefundPrice, getTotalReturnPrice } from '../helper/refund'

const bmoment = require('moment-business-days')

bmoment.updateLocale('us', {
  workingWeekdays: [1, 2, 3, 4, 5],
  holidayFormat: 'YYYY-MM-DD',
  holidays: SellerStore.holidays,
})

const createColumn = (
  header,
  valueAccessor,
  formatFunction = (val) => val,
) => ({
  Header: header,
  Cell: ({ row }) => {
    const item = row?.original
    const value = valueAccessor(item)
    return (
      <div className='flex flex-col flex-1'>
        <div>{formatFunction(value)}</div>
      </div>
    )
  },
})

const createColumnsTable = (currentOrderStatus) => {
  const refundingColumns = [
    createColumn('접수일시', (item) =>
      moment(item?.refundInfo?.refundRequestedAt).format('YYYY-MM-DD HH:mm'),
    ),
    createColumn('처리상태', (item) => {
      if (item?.refundInfo?.refundStatus === 'refunding-requested') {
        return '반품 요청'
      }
      if (item?.refundInfo?.refundStatus === 'refunding-retrieving') {
        return '수거 중'
      }
      if (item?.refundInfo?.refundStatus === 'refunding-retrieved') {
        return '수거 완료'
      }
    }),
    createColumn('반품품명', (item) => item?.itemInfo?.itemTitle),
    createColumn('주문수량', (item) => item?.quantity),
    createColumn('반품사유', (item) => item?.refundInfo?.refundReason),
    createColumn('상세사유', (item) => {
      const detailedReason = item?.refundInfo?.refundDetailedReason || ''
      const chunkSize = 30
      const chunks = []
      for (let i = 0; i < detailedReason.length; i += chunkSize) {
        chunks.push(detailedReason.substring(i, i + chunkSize))
      }
      const content = chunks.map((chunk, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {chunk}
          {index < chunks.length - 1 && <br />}
        </React.Fragment>
      ))
      return <div>{content}</div>
    }),
    createColumn('첨부이미지', (item) => (
      <>
        {item.refundInfo?.refundImageUris?.map((imageUri, index) => (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img
            // eslint-disable-next-line react/no-array-index-key
            key={`${imageUri}-${index}`}
            alt={`Refund Image ${index + 1}`}
            src={imageUri}
            style={{
              height: 196,
              width: 196,
              paddingRight: 16,
              objectFit: 'contain',
            }}
          />
        ))}
      </>
    )),
    createColumn('무료반품', (item) =>
      item?.itemInfo?.shippingInfo?.isReturnFree ? '신청 상품' : '',
    ),
    createColumn('환불정보', (item) => (
      <div>
        <div className='font-bold text-lg'>총 환불 금액</div>
        <div className='mt-2 text-lg font-bold'>
          {getTotalRefundPrice(item)}
        </div>
        <div className='mt-2'>상품 금액</div>
        <div className='mt-2'>{item?.totalPrice}</div>
        <div className='mt-2'>총 반품비</div>
        <div className='mt-2'>{getTotalReturnPrice(item)}</div>
      </div>
    )),
    createColumn('택배사', (item) => item?.shippingInfo?.shippingCompanyName),
    createColumn('주문아이디', (item) => item?._id),
    createColumn(
      '합포장아이디',
      (item) => item?.combinedOrder?.combinedOrderId || '',
    ),
    createColumn('구매자', (item) => item?.addressInfo?.recipient),
    createColumn('구매자 주소', (item) => item?.addressInfo?.postcodeAddress),
    createColumn(
      '구매자 연락처',
      (item) => item?.addressInfo?.recipientPhoneNumber,
    ),
  ]
  const couponColumns = [
    createColumn('주문성사시간', (item) =>
      moment(item?.preShippingTimeStamp).format('YYYY-MM-DD HH:mm:ss'),
    ),
    createColumn('집화시간', (item) =>
      moment(item?.shippingTimeStamp).format('YYYY-MM-DD HH:mm:ss'),
    ),
    createColumn('배송완료시간', (item) =>
      moment(item?.arrivedTimeStamp).format('YYYY-MM-DD HH:mm:ss'),
    ),
    createColumn('지급사유', (item) => '정시 미집화/미출고'),
    createColumn('주문 아이디', (item) => item?._id),
    createColumn(
      '합포장아이디',
      (item) => item?.combinedOrder?.combinedOrderId || '',
    ),
    createColumn('상품명', (item) => item?.itemInfo?.itemTitle),
    createColumn(
      '배송지',
      (item) =>
        `${item.addressInfo?.postcode} ${item.addressInfo?.postcodeAddress}`,
    ),
    createColumn('상세 주소', (item) => item.addressInfo?.detailAddress),
    createColumn('수령인', (item) =>
      Format.Recipient(item.addressInfo?.recipient),
    ),
    createColumn(
      '수령인 연락처',
      (item) => item.addressInfo?.recipientPhoneNumber,
    ),
    createColumn('쿠폰 금액', (item) => 1000),
  ]
  const baseColumns = [
    createColumn('배송형태', (item) => {
      if (item?.shippingInfo?.shippingCompanyName === '딜리래빗') {
        return '당일도착'
      }
      if (item?.shippingInfo?.shippingCompanyName === 'CJ대한통운') {
        return '익일도착'
      }
      return item?.deliveryType || '배송정보 없음'
    }),
    createColumn('담당택배사', (item) => {
      if (item?.shippingInfo?.shippingCompanyName === '딜리래빗') {
        return item?.shippingInfo?.shippingCompanyName
      }
      if (item?.shippingInfo?.shippingCompanyName === 'CJ대한통운') {
        return item?.shippingInfo?.shippingCompanyName
      }
      return item?.deliveryCompany || '배송정보 없음'
    }),
    createColumn('주문성사시간', (item) =>
      moment(item?.preShippingTimeStamp).format('YYYY-MM-DD HH:mm:ss'),
    ),
    createColumn('주문 아이디', (item) => item?._id),
    createColumn(
      '합포장아이디',
      (item) => item?.combinedOrder?.combinedOrderId || '',
    ),
    createColumn('상품명', (item) => item?.itemInfo?.itemTitle),
    createColumn('옵션', (item) =>
      tableUtils.parseOptionText(
        item?.itemInfo?.optionsInfo,
        item?.selectedOption,
      ),
    ),
    createColumn('수량', (item) => item?.quantity),
    createColumn('상품가격', (item) => item?.totalPrice),
    createColumn(
      '배송지',
      (item) =>
        `${item.addressInfo?.postcode} ${item.addressInfo?.postcodeAddress}`,
    ),
    createColumn('상세 주소', (item) => item.addressInfo?.detailAddress),
    createColumn('수령인', (item) =>
      Format.Recipient(item.addressInfo?.recipient),
    ),
    createColumn(
      '수령인 연락처',
      (item) => item.addressInfo?.recipientPhoneNumber,
    ),
  ]
  if (currentOrderStatus === 'shipping' || currentOrderStatus === 'arrived') {
    baseColumns.splice(
      2,
      0,
      createColumn('송장번호', (item) => item?.shippingInfo?.shippingNumber),
    )
    return baseColumns
  }
  if (currentOrderStatus === 'refunding') {
    return refundingColumns
  }
  if (currentOrderStatus === 'refunded') {
    refundingColumns.splice(
      1,
      0,
      createColumn('송장번호', (item) => item?.shippingInfo?.shippingNumber),
    )
    return refundingColumns
  }
  if (currentOrderStatus === 'compensation') {
    return couponColumns
  }
  return baseColumns
}

const OffDayList = {
  0: '일요일',
  1: '월요일',
  2: '화요일',
  3: '수요일',
  4: '목요일',
  5: '금요일',
  6: '토요일',
}

const explainTextMap = {
  todayDelivery: {
    shippingTime: '13시',
    cutOffTime: '12시 00분',
    cutOffTimer: '12:00:00',
    day: '오늘',
    nextDay: false,
  },
  tomorrowDelivery: {
    shippingTime: '17시',
    cutOffTime: '16시 00분',
    cutOffTimer: '16:00:00',
    day: '오늘',
    nextDay: false,
  },
  tomorrowDeliveryTomorrowDeparture: {
    shippingTime: '17시',
    cutOffTime: '16시 00분',
    cutOffTimer: '16:00:00',
    day: '익일',
    nextDay: true,
  },
  todayDeliveryTomorrowDeparture: {
    shippingTime: '13시',
    cutOffTime: '12시 00분',
    cutOffTimer: '12:00:00',
    day: '익일',
    nextDay: true,
  },
}

const worksheetHeaders = [
  { header: '주문아이디', key: 'orderId', width: 20 },
  { header: '상품아이디', key: 'itemId', width: 20 },
  { header: '합배송아이디', key: 'addressId', width: 20 },
  { header: '판매자 상품코드', key: 'optionCode', width: 20 },
  { header: '상품명', key: 'itemTitle', width: 30 },
  { header: '옵션', key: 'option', width: 40 },
  { header: '수량', key: 'quantity' },
  { header: '상품가격', key: 'totalPrice' },
  { header: '배송비', key: 'shippingFee' },
  { header: '추가지원금', key: 'platformSupportPrice' },
  {
    header: '올웨이즈 부담 쿠폰할인금',
    key: 'couponSettlementAmount',
  },
  {
    header: '판매자 부담 쿠폰할인금',
    key: 'couponNotSettlementAmount',
  },
  { header: '쿠폰할인금', key: 'finalDiscountAmount' },
  { header: '정산대상금액(수수료 제외)', key: 'settlementAmount' },
  { header: '주소', key: 'address', width: 50 },
  { header: '우편번호', key: 'postcode' },
  { header: '공동현관 비밀번호', key: 'frontDoorPassword', width: 20 },
  { header: '수령 방법', key: 'detailForReceivingMethod' },
  { header: '수령인', key: 'recipient' },
  { header: '수령인 연락처', key: 'recipientPhoneNumber', width: 20 },
  { header: '택배사', key: 'shippingCompany' },
  { header: '주문 시점', key: 'payedAt' },
  { header: '운송장번호', key: 'shippingNumber', width: 30 },
  { header: '고유 코드', key: 'manualItemCode' },
]
const translator = shortUUID()

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

async function handleExcelExport(selectedOrderIds) {
  try {
    const result = await backendApis.loadOrdersByOrderIds(selectedOrderIds)
    if (result.status === 200) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('주문 내역')
      const orders = result?.data
      const worksheetData = orders?.map((e) => {
        const manualItemCode = ''
        const optionsText = e?.itemInfo?.optionsInfo?.optionNames.map(
          (value, optionIndex) => {
            const optionDetail =
              e?.itemInfo?.optionsInfo?.totalOptions[optionIndex][
                e?.selectedOption[optionIndex]
              ]?.name
            if (optionDetail === undefined) {
              return ''
            }
            return `${optionIndex}. ${value}: ${optionDetail}`
          },
        )
        return {
          orderId: e._id,
          itemId: e?.itemInfo?.itemId,
          itemTitle: e?.itemInfo?.itemTitle,
          addressId: e?.combinedOrder?.combinedOrderId || '',
          optionCode: e?.selectedOption?.optionCode || '',
          option: optionsText.join('\n'),
          quantity: e?.quantity,
          address: `${e?.addressInfo?.postcodeAddress} ${e?.addressInfo?.detailAddress}`,
          postcode: e?.addressInfo?.postcode,
          frontDoorPassword: e?.addressInfo?.frontDoorPassword,
          detailForReceivingMethod:
            e?.addressInfo?.receivingMethod === 'door'
              ? '문 앞'
              : e?.addressInfo?.detailForReceivingMethod,
          recipient: Format.Recipient(e?.addressInfo?.recipient),
          recipientPhoneNumber: e?.addressInfo?.recipientPhoneNumber,
          shippingCompany: e?.shippingInfo?.shippingCompanyName,
          shippingNumber: e?.shippingInfo?.shippingNumber,
          payedAt: moment(e?.payedAt).format('YYYY-MM-DD HH:mm'),
          manualItemCode,
          totalPrice: e?.totalPrice,
          shippingFee: e?.shippingFee,
          couponSettlementAmount: getCouponSettlementAmount(e),
          couponNotSettlementAmount: getCouponNotSettlementAmount(e),
          platformSupportPrice:
            (e?.selectedOption?.platformSupportPrice || 0) * (e?.quantity || 1),
          finalDiscountAmount: e?.selectedCouponInfo?.finalDiscountAmount || 0,
          settlementAmount: getSettlementAmount(e),
        }
      })
      worksheet.columns = worksheetHeaders
      worksheet.addRows(worksheetData)
      worksheet.getColumn(3).alignment = { wrapText: true }
      const headerRow = worksheet.getRow(1)
      worksheetHeaders.forEach((header, index) => {
        headerRow.getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFEA3F49' },
        }
      })
      worksheet.getRow(1).font = {
        color: { argb: 'FFFFFFFF' },
        bold: true,
      }
      const mimeType = {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)
      const saveDate = new Date()
      const year = saveDate.getFullYear()
      const month = saveDate.getMonth() + 1
      const date = saveDate.getDate()
      saveAs(
        blob,
        `${SellerStore.sellerInfo.sellerDisplayName}_주문 내역_${year}-${
          month < 10 ? `0${month}` : month
        }-${date < 10 ? `0${date}` : date}.xlsx`,
      )
      return { success: true }
    }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

async function handleCombinedOrderExcelExport(selectedOrderIds) {
  const result = await backendApis.loadOrdersByOrderIds(selectedOrderIds)
  if (result.status === 200) {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('주문 내역')
    const orders = result.data
    const worksheetData = orders.map((e) => {
      const manualItemCode = ''
      const optionsText = e.itemInfo.optionsInfo.optionNames.map(
        (value, optionIndex) => {
          const optionDetail =
            e.itemInfo.optionsInfo.totalOptions[optionIndex][
              e.selectedOption[optionIndex]
            ]?.name
          if (optionDetail === undefined) {
            return ''
          }
          return `${optionIndex}. ${value}: ${optionDetail}`
        },
      )
      return {
        orderId: e._id,
        itemId: e?.itemInfo?.itemId,
        itemTitle: e?.itemInfo?.itemTitle,
        addressId: e?.combinedOrder?.combinedOrderId,
        optionCode: e?.selectedOption?.optionCode || '',
        option: optionsText.join('\n'),
        quantity: e.quantity,
        address: `${e.addressInfo?.postcodeAddress} ${e.addressInfo?.detailAddress}`,
        postcode: e.addressInfo?.postcode,
        frontDoorPassword: e.addressInfo?.frontDoorPassword,
        detailForReceivingMethod:
          e.addressInfo?.receivingMethod === 'door'
            ? '문 앞'
            : e.addressInfo?.detailForReceivingMethod,
        recipient: Format.Recipient(e.addressInfo?.recipient),
        recipientPhoneNumber: e.addressInfo?.recipientPhoneNumber,
        shippingCompany: e.shippingInfo.shippingCompanyName,
        shippingNumber: e.shippingInfo.shippingNumber,
        payedAt: moment(e.payedAt).format('YYYY-MM-DD HH:mm'),
        manualItemCode,
        totalPrice: e?.totalPrice,
        shippingFee: e?.shippingFee,
        couponSettlementAmount: getCouponSettlementAmount(e),
        couponNotSettlementAmount: getCouponNotSettlementAmount(e),
        platformSupportPrice:
          (e.selectedOption?.platformSupportPrice || 0) * (e.quantity || 1),
        finalDiscountAmount: e.selectedCouponInfo?.finalDiscountAmount || 0,
        settlementAmount: getSettlementAmount(e),
      }
    })
    worksheet.columns = worksheetHeaders
    worksheet.addRows(worksheetData)
    worksheet.getColumn(3).alignment = { wrapText: true }
    const headerRow = worksheet.getRow(1)
    worksheetHeaders.forEach((header, index) => {
      headerRow.getCell(index + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFEA3F49' },
      }
    })
    worksheet.getRow(1).font = {
      color: { argb: 'FFFFFFFF' },
      bold: true,
    }
    const mimeType = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], mimeType)
    const saveDate = new Date()
    const year = saveDate.getFullYear()
    const month = saveDate.getMonth() + 1
    const date = saveDate.getDate()
    saveAs(
      blob,
      `${SellerStore.sellerInfo.sellerDisplayName}_주문 내역_${year}-${
        month < 10 ? `0${month}` : month
      }-${date < 10 ? `0${date}` : date}.xlsx`,
    )
  }
}

async function validateAddress(orders) {
  const chunk = 200
  const todayDeliveryValidOrders = []
  const todayDeliveryInvalidOrders = []
  for (let i = 0; i < orders.length; i += chunk) {
    const chunkedOrders = orders.slice(i, i + chunk)
    const chunkedItemIdsTemp = chunkedOrders.map(
      (order) => order?.itemInfo?.itemId,
    )
    const chunkedItemIds = [...new Set(chunkedItemIdsTemp)]
    const itemInfoWithStorage =
      await backendApis.getItemsInfoWithStorageByItemIds(chunkedItemIds)
    if (itemInfoWithStorage?.status === 2000) {
      const storagePostCodes = itemInfoWithStorage?.data.map((e) => {
        return {
          itemId: e?._id,
          storagePostCode: e?.storageLocation?.departurePostCode,
        }
      })
      const todayDeliveryValidTemp = chunkedOrders.filter((order) => {
        const orderStorageInfo = storagePostCodes.find(
          (info) => info?.itemId === order?.itemInfo?.itemId,
        )
        const isPickUpPostCodeValid = possiblePickUpPostCode.includes(
          orderStorageInfo?.storagePostCode,
        )
        const isRecipientPostCodeValid = possibleRecipientPostCode.includes(
          order?.addressInfo?.postcode,
        )
        return isPickUpPostCodeValid && isRecipientPostCodeValid
      })
      const todayDeliveryInvalidTemp = chunkedOrders?.filter(
        (order) =>
          !possibleRecipientPostCode.includes(order?.addressInfo?.postcode) ||
          !possiblePickUpPostCode.includes(
            storagePostCodes.find(
              (info) => info?.itemId === order?.itemInfo?.itemId,
            )?.storagePostCode,
          ),
      )
      todayDeliveryValidOrders.push(...todayDeliveryValidTemp)
      todayDeliveryInvalidOrders.push(...todayDeliveryInvalidTemp)
    }
  }
  return [todayDeliveryValidOrders, todayDeliveryInvalidOrders]
}

async function checkOrderCutOff(
  todayDeliveryValidOrders,
  todayDeliveryInvalidOrders,
) {
  const now = moment()
  const todayDeliveryOrderCutOff = moment().hour(11).minute(30).second(0) // 오늘 배송 마감 시간
  const tomorrowDeliveryOrderCutOff = moment().hour(15).minute(30).second(0) // 내일 배송 마감 시간
  const todayDeliveryShippingCutOff = moment().hour(12).minute(0).second(0) // 오늘 배송 마감 시간
  const tomorrowDeliveryShippingCutOff = moment().hour(16).minute(0).second(0) // 내일 배송 마감 시간

  // 주문을 분류
  const todayDeliveryTodayDepartureOrders = []
  const todayDeliveryTomorrowDepartureOrders = []
  const tomorrowDeliveryTodayDepartureOrders = []
  const tomorrowDeliveryTomorrowDepartureOrders = []

  todayDeliveryValidOrders?.forEach((order) => {
    const orderTime = moment(order?.preShippingTimeStamp)
    if (now.isBefore(todayDeliveryShippingCutOff)) {
      // 현재 시간이 오늘 배송 마감 시간 전
      if (orderTime.isBefore(todayDeliveryOrderCutOff)) {
        todayDeliveryTodayDepartureOrders.push({
          ...order,
          deliveryType: '당일도착',
          deliveryCompany: '딜리래빗',
        })
      } else {
        todayDeliveryTomorrowDepartureOrders.push({
          ...order,
          deliveryType: '당일도착',
          deliveryCompany: '딜리래빗',
        })
      }
    } else {
      // 현재 시간이 오늘 배송 마감 시간 이후
      todayDeliveryTomorrowDepartureOrders.push({
        ...order,
        deliveryType: '당일도착',
        deliveryCompany: '딜리래빗',
      })
    }
  })

  todayDeliveryInvalidOrders?.forEach((order) => {
    const orderTime = moment(order?.preShippingTimeStamp)
    if (now.isBefore(tomorrowDeliveryShippingCutOff)) {
      // 현재 시간이 내일 배송 마감 시간 전
      if (orderTime.isBefore(tomorrowDeliveryOrderCutOff)) {
        tomorrowDeliveryTodayDepartureOrders.push({
          ...order,
          deliveryType: '익일도착',
          deliveryCompany: 'CJ대한통운',
        })
      } else {
        tomorrowDeliveryTomorrowDepartureOrders.push({
          ...order,
          deliveryType: '익일도착',
          deliveryCompany: 'CJ대한통운',
        })
      }
    } else {
      tomorrowDeliveryTomorrowDepartureOrders.push({
        ...order,
        deliveryType: '익일도착',
        deliveryCompany: 'CJ대한통운',
      })
    }
  })
  return [
    todayDeliveryTodayDepartureOrders,
    tomorrowDeliveryTodayDepartureOrders,
    todayDeliveryTomorrowDepartureOrders,
    tomorrowDeliveryTomorrowDepartureOrders,
  ]
}

async function loadFastArrivalOrdersByStatus(status) {
  const totalOrders = await backendApis.loadFastArrivalOrdersByStatus(status)
  if (totalOrders?.status === 200 && totalOrders?.data?.length > 0) {
    return totalOrders
  }
  return []
}

async function loadFastArrivalArrivedOrders() {
  const totalOrders = await backendApis.loadArrivedFastArrivalOrders()
  if (totalOrders?.status === 200 && totalOrders?.data?.length > 0) {
    return totalOrders
  }
  return []
}

async function loadSegmentedFastArrivalArrivedOrders(startDate, endDate) {
  const safeStartDate = new Date(startDate)
  const safeEndDate = new Date(endDate)

  const startOfStartDate = new Date(safeStartDate.setHours(0, 0, 0, 0))
  const endOfEndDate = new Date(safeEndDate.setHours(23, 59, 59, 999))

  const formattedStartDate = startOfStartDate.toISOString()
  const formattedEndDate = endOfEndDate.toISOString()

  const totalOrders = await backendApis.loadSegmentedFastArrivalArrivedOrders(
    formattedStartDate,
    formattedEndDate,
  )
  if (totalOrders?.status === 200 && totalOrders?.data?.length > 0) {
    window.alert('조회가 완료되었습니다.')
    return totalOrders
  }
  window.alert('주문 내역이 없습니다.')
  return []
}

async function loadCompensationCouponRegisteredOrders() {
  const result = await backendApis.loadCompensationCouponRegisteredOrders()
  if (result?.status === 200 && result?.data?.length > 0) {
    const sellerCausedCompensation = result?.data?.filter(
      (e) => e?.couponInfo?.reason === 'notIsOnTimeShipping',
    )
    if (sellerCausedCompensation && sellerCausedCompensation?.length > 0) {
      return sellerCausedCompensation
    }
    return null
  }
  return null
}

async function loadFastArrivalProgressingOrders() {
  const totalProgressingOrders =
    await backendApis.loadProgressingFastArrivalOrders()
  if (
    totalProgressingOrders?.status === 200 &&
    totalProgressingOrders?.data?.length > 0
  ) {
    const preShippingOrders = totalProgressingOrders?.data.filter(
      (order) => order?.status === 'pre-shipping',
    )
    const shippingOrders = totalProgressingOrders?.data.filter(
      (order) => order?.status === 'shipping',
    )
    const preShippingOrdersPostCode = preShippingOrders.map(
      (order) => order?.addressInfo?.postcode,
    )
    return [preShippingOrders, preShippingOrdersPostCode, shippingOrders]
  }
  return [[], [], []]
}

async function classifyFastArrivalOrders(
  preShippingOrdersAddressList,
  preShippingOrders,
) {
  const [todayDeliveryPossibleOrderData, tomorrowDeliveryPossibleOrderData] =
    await validateAddress(preShippingOrdersAddressList, preShippingOrders)
  const [
    todayDeliveryTargetOrders,
    tomorrowDeliveryTargetOrders,
    todayDeliveryTomorrowDepartureOrders,
    tomorrowDeliveryTomorrowDepartureOrders,
  ] = await checkOrderCutOff(
    todayDeliveryPossibleOrderData,
    tomorrowDeliveryPossibleOrderData,
  )
  return [
    todayDeliveryTargetOrders,
    tomorrowDeliveryTargetOrders,
    todayDeliveryTomorrowDepartureOrders,
    tomorrowDeliveryTomorrowDepartureOrders,
  ]
}

// async function checkCancelPossibleOrders(selectedOrderId) {
//   const result = await backendApis.loadOrdersByOrderIds(selectedOrderId)
//   if (result.status === 200) {
//     let cancelLimit = moment()
//     const order = result.data
//     const now = moment()
//     const orderTime = moment(order[0]?.preShippingTimeStamp)
//     const orderCutOffForDelivus = moment(orderTime)
//       .hour(12)
//       .minute(0)
//       .second(0)
//       .millisecond(0)
//       .toISOString()
//     const orderCutOffForCjLogistics = moment(order[0]?.preShippingTimeStamp)
//       .hour(15)
//       .minute(30)
//       .second(0)
//       .millisecond(0)
//       .toISOString()
//     const shippingCompanyName = order[0]?.shippingInfo?.shippingCompanyName
//     if (shippingCompanyName === '딜리래빗') {
//       if (orderTime.isBefore(orderCutOffForDelivus)) {
//         cancelLimit = moment(orderTime)
//           .hour(12)
//           .minute(0)
//           .second(0)
//           .millisecond(0)
//           .toISOString()
//       } else {
//         cancelLimit = bmoment(orderTime)
//           .add(1, 'days')
//           .hour(12)
//           .minute(0)
//           .second(0)
//           .millisecond(0)
//           .toISOString()
//       }
//     }
//     if (shippingCompanyName === 'CJ대한통운') {
//       if (orderTime.isBefore(orderCutOffForCjLogistics)) {
//         cancelLimit = moment(orderTime)
//           .hour(17)
//           .minute(0)
//           .second(0)
//           .millisecond(0)
//           .toISOString()
//       } else {
//         cancelLimit = bmoment(orderTime)
//           .add(1, 'days')
//           .hour(17)
//           .minute(0)
//           .second(0)
//           .millisecond(0)
//           .toISOString()
//       }
//     }
//     if (now.isBefore(cancelLimit)) {
//       return true
//     }
//     return false
//   }
// }

// async function cancelFastArrivalOrders(selectedOrderId) {
//   const result = await backendApis.loadOrdersByOrderIds(selectedOrderId)
//   if (result?.status === 200) {
//     const order = result.data
//     const shippingCompanyName = order[0]?.shippingInfo?.shippingCompanyName
//     if (shippingCompanyName === '딜리래빗') {
//       const cancelRequest =
//         await backendApis.requestCancelingShippingForDelivus(order[0]?._id)
//       if (!cancelRequest) return null
//       if (
//         cancelRequest?.status === 200 &&
//         cancelRequest?.data === order[0]?._id
//       ) {
//         return cancelRequest?.data
//       }
//     }
//     if (shippingCompanyName === 'CJ대한통운') {
//       const cancelRequest = await backendApis.requestCancelingShippingForCj(
//         order[0]?._id,
//       )
//     }
//   }
// }

async function approvingRefundingWithOutPickup(selectedOrderId) {
  SellerStore.setIsLoading(true)
  const result = await backendApis.loadOrdersByOrderIds(selectedOrderId)
  if (result?.status === 200) {
    const order = result.data
    let returnFee = order[0]?.itemInfo?.shippingInfo?.returnFee
    if (order[0]?.refundInfo?.refundReason !== '단순 변심') {
      returnFee = 0
    }
    const cancelingOrder = await backendApis.approveOrderCancel(
      order[0]?._id,
      order[0]?.totalPrice,
      order[0]?.shippingFee,
      order[0]?.itemInfo?.shippingInfo?.returnFee,
      parseInt(returnFee, 10),
    )
    if (cancelingOrder?.status === 200) {
      await backendApis.notifyUserRefundEventWithOutPickUp(
        order[0]?._id,
        order[0]?.refundInfo?.refundReason,
      )
      SellerStore.setIsLoading(false)
      return true
    }
  }
  SellerStore.setIsLoading(false)
  return null
}

async function registerRefundPickUp(selectedOrderId) {
  SellerStore.setIsLoading(true)
  const result = await backendApis.loadOrdersByOrderIds(selectedOrderId)
  const shippingCompany = result?.data[0]?.shippingInfo?.shippingCompanyName
  if (result?.status === 200) {
    const order = result.data

    // 1. 택배사 별 수거 접수
    // 2. 스윗트레커 콜백
    // 3. refund_management 업데이트
    if (shippingCompany === '딜리래빗') {
      let requestRefundingForDelivus
      let requestRefundingCombinedItemsForDelivus
      if (order[0]?.combinedOrder?.combinedOrderId) {
        requestRefundingCombinedItemsForDelivus =
          await backendApis.requestRefundingForDelivus(
            'combined',
            order[0]?._id,
          )
      } else {
        requestRefundingForDelivus =
          await backendApis.requestRefundingForDelivus('single', order[0]?._id)
      }
      if (
        requestRefundingForDelivus?.status === 200 ||
        requestRefundingCombinedItemsForDelivus?.status === 200
      ) {
        SellerStore.setIsLoading(false)
        return true
      }
      SellerStore.setIsLoading(false)
      return null
    }
    if (shippingCompany === 'CJ대한통운') {
      const cjRefundShippingNum =
        await backendApis.createShippingNumberForCjLogistics(selectedOrderId)
      if (cjRefundShippingNum?.length === 0) return null
      const orderData = {
        ...result?.data[0],
        refundShippingNumber: cjRefundShippingNum[0],
      }
      const requestRefundingForCj = await backendApis.requestRefundingForCj(
        orderData,
      )
      if (requestRefundingForCj?.modifiedCount === 1) {
        SellerStore.setIsLoading(false)
        return true
      }
      SellerStore.setIsLoading(false)
      return null
    }
  }
}

async function approvingRefunding(selectedOrderId) {
  SellerStore.setIsLoading(true)
  const result = await backendApis.loadOrdersByOrderIds(selectedOrderId)
  if (result?.status === 200) {
    const order = result.data
    let returnFee = order[0]?.itemInfo?.shippingInfo?.returnFee
    if (order[0]?.refundInfo?.refundReason !== '단순 변심') {
      returnFee = 0
    }
    const cancelingOrder = await backendApis.approveOrderCancel(
      order[0]?._id,
      order[0]?.totalPrice,
      order[0]?.shippingFee,
      order[0]?.itemInfo?.shippingInfo?.returnFee,
      parseInt(returnFee, 10),
    )
    if (cancelingOrder?.status === 200) {
      SellerStore.setIsLoading(false)
      return true
    }
    SellerStore.setIsLoading(false)
    return null
  }
}

export {
  validateAddress,
  checkOrderCutOff,
  handleExcelExport,
  loadFastArrivalArrivedOrders,
  loadFastArrivalProgressingOrders,
  loadFastArrivalOrdersByStatus,
  classifyFastArrivalOrders,
  handleCombinedOrderExcelExport,
  explainTextMap,
  OffDayList,
  createColumn,
  createColumnsTable,
  approvingRefunding,
  approvingRefundingWithOutPickup,
  loadSegmentedFastArrivalArrivedOrders,
  loadCompensationCouponRegisteredOrders,
  registerRefundPickUp,
}
