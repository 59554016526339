import { useEffect, useState } from 'react'
import SellerStore from '../../../../stores/SellerStore'
import backendApis from '../../../../utils/backendApis'
import freeCpmCreditSellers from '../freeCpmCreditSellers.json'

const useIsFreeCpmSeller = () => {
  const [isFreeCpmTargetSeller, setIsFreeCpmTargetSeller] = useState(false)

  useEffect(() => {
    const getFreeAdCPMCandidateHistory = async () => {
      const result = await backendApis.getFreeAdCPMCandidateHistory()

      if (result?.status !== 200) {
        setIsFreeCpmTargetSeller(false)
        return
      }

      if (result?.data === null || !result?.data?.hasReceivedFreeAd) {
        setIsFreeCpmTargetSeller(true)
      }
    }

    const hasEndedWithPhoneNumber = (endsWith) => {
      const contactNumber = SellerStore?.sellerInfo?.contactNumber?.replace(
        /[^0-9]/g,
        '',
      )

      const phoneNumber = SellerStore?.sellerInfo?.phoneNumber?.replace(
        /[^0-9]/g,
        '',
      )

      return (
        contactNumber?.endsWith(endsWith) || phoneNumber?.endsWith(endsWith)
      )
    }

    if (
      freeCpmCreditSellers.data.includes(SellerStore?.sellerInfo?._id) &&
      (hasEndedWithPhoneNumber('0') ||
        hasEndedWithPhoneNumber('1') ||
        hasEndedWithPhoneNumber('2') ||
        hasEndedWithPhoneNumber('3') ||
        hasEndedWithPhoneNumber('4'))
    ) {
      getFreeAdCPMCandidateHistory()
    }
  }, [isFreeCpmTargetSeller, SellerStore?.sellerInfo?._id])

  return isFreeCpmTargetSeller
}

export default useIsFreeCpmSeller
