import moment from 'moment'
import React, { useState, useEffect, useMemo } from 'react'
import { FcIdea as BulbIcon, FcOk as CheckIcon } from 'react-icons/fc'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useForm } from 'react-hook-form'
import SellerStore from '../../stores/SellerStore'
import adSettingByTab from '../../data/adSettingByTab'
import backendApis from '../../utils/backendApis'
import Table from '../../components/ADS/molecules/table'
import Pagination from '../../components/molecules/pagination'
import RankIconSetter from '../../components/molecules/rankIconSetter'
import resize from '../../utils/resize'
import Button from '../../components/atoms/button'
import Tooltip from '../../components/ADS/atoms/tooltip'
import commaNumber from 'comma-number'
import QuizAdStatusBar from '../../components/molecules/quizAdStatusBar'
import ApplyTabHandler from '../../components/molecules/applyTabHandler'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const AlfarmQuizAdScreenV2 = () => {
  const history = useHistory()
  const { register, handleSubmit } = useForm()
  const onSubmit = (data) => setQuizInfo(data)
  const [startDate, setStartDate] = useState(
    new Date(moment(new Date()).startOf('day').add(3, 'days')),
  )
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemsCount, setItemsCount] = useState(0)
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [checkedItemInfo, setCheckedItemInfo] = useState([])
  const [checkedItemsTitle, setCheckedItemsTitle] = useState([])
  const [alreadyRegisteredItemIds, setAlreadyRegisteredItemIds] = useState([])
  const [quizInfo, setQuizInfo] = useState({
    quizDescription: '',
    quizChoice1: '',
    quizChoice2: '',
    quizChoice3: '',
    quizAnswer: 0,
  })
  const [quizCount, setQuizCount] = useState(50000)
  const [impCost, setImpCost] = useState(6)
  const [adStatusTab, setAdStatusTab] = useState('candidate')
  const [sellerSettlementAmount, setSellerSettlementAmount] = useState(0)

  const cleanUp = () => {
    setStartDate(new Date(moment(new Date()).startOf('day').add(3, 'days')))
    setPage(1)
    setLimit(5)
    setItemsInfo([])
    setItemsCount(0)
    setQuizInfo({
      quizDescription: '',
      quizChoice1: '',
      quizChoice2: '',
      quizChoice3: '',
      quizAnswer: 0,
    })
    setQuizCount(50000)
    setImpCost(6)
    setCheckedItemIds([])
  }

  const columns = useMemo(
    () => [
      { Header: '선택', accessor: 'status' },
      { Header: '상품정보', accessor: 'itemInfo' },
    ],
    [],
  )

  const data = useMemo(
    () =>
      itemsInfo
        ?.filter((item) => item?.itemTitle)
        .map((item) => {
          return {
            status: (
              <div className='flex items-center justify-center flex-1 select-none'>
                <input
                  type='checkbox'
                  checked={checkedItemIds?.includes(item?._id)}
                  onChange={(e) => handleCheckboxChange(e, item)}
                />
              </div>
            ),
            itemInfo: (
              <div key={item?._id}>
                <div className='flex flex-row justify-between flex-1 w-full'>
                  <div className='flex flex-row flex-1 w-max'>
                    <img
                      alt='mainImage'
                      src={
                        item?.mainImageUris
                          ? resize(item?.mainImageUris[0])
                          : ''
                      }
                      className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
                    />
                    <div className='mr-3'>
                      <div className='mb-1 text-lg font-bold whitespace-normal'>
                        {item?.itemTitle}
                      </div>

                      <div className='flex flex-row items-center mb-1'>
                        {item?.rankInfo && (
                          <div className='flex items-center px-2 py-0.5 bg-gray-100 rounded-md mr-2'>
                            <RankIconSetter
                              itemRank={item?.rankInfo?.itemRank}
                              className='w-4 h-4'
                            />
                            등급
                          </div>
                        )}
                        <div>
                          {item?.teamPurchasePrice?.toLocaleString() || 0}원
                        </div>
                      </div>
                      <div className='p-0.5'>
                        평점:{' '}
                        {!item?.totalRatingCount ? (
                          <>없음</>
                        ) : (
                          <>
                            {(
                              item?.totalRatingScore / item?.totalRatingCount
                            )?.toFixed(1)}
                          </>
                        )}
                      </div>

                      <div className='text-gray-400 '>{item?._id}</div>
                    </div>
                  </div>
                </div>
              </div>
            ),
          }
        }),
    [itemsInfo, checkedItemIds, alreadyRegisteredItemIds],
  )

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    itemCondition.soldOut = false
    itemCondition.outOfStock = { $ne: true }
    itemCondition.$or = [
      { itemFilters: { $eq: null } },
      {
        itemFilters: {
          $not: {
            $elemMatch: {
              $or: [{ key: 'ad', value: adSettingByTab?.[2].type }], // 체크 필요
            },
          },
        },
      },
    ]

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }

    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      await SellerStore.loadSettlements()
    }
    fetchData()

    const filteredData = SellerStore?.settlements?.filter((item) => {
      const threeWeeksAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 21) // Corrected the threeWeeksAgo calculation
      return !item?.errorType && new Date(item?.createdAt) > threeWeeksAgo // Ensured both are Date objects for comparison
    })
    const settlementSum = filteredData?.reduce((acc, cur) => {
      return acc + cur?.totalSettlementAmount
    }, 0)

    setSellerSettlementAmount(settlementSum)
  }, [])

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [adStatusTab])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  useEffect(() => {
    setCheckedItemIds([])
    setCheckedItemsTitle([])
  }, [startDate])

  useEffect(() => {
    // 여기서 셀러 정보를 가져와서 지금까지 신청한 광고 정보를 얻는다.
    const fetchRegisteredAdInfo = async () => {
      const convertedStartDate = moment(new Date(startDate))
        .startOf('day')
        .subtract(4, 'days')
      const convertedEndDate = moment(new Date(startDate))
        .startOf('day')
        .subtract(-4, 'days')

      const result = await backendApis.getQuizAdBetweenDate(
        convertedStartDate,
        convertedEndDate,
      )

      const allItemIds = result?.data?.flatMap(
        (entry) => entry.detailInfo.itemIds,
      )
      const uniqueItemIds = [...new Set(allItemIds)]

      setAlreadyRegisteredItemIds(uniqueItemIds)
    }
    fetchRegisteredAdInfo()
  }, [adStatusTab, startDate])

  const handleCheckboxChange = (e, item) => {
    // if (alreadyRegisteredItemIds?.includes(item?._id)) {
    //   alert(
    //     '3일 이내 신청한 상품은 중복해서 신청할 수 없습니다. 다른 상품을 선택해주세요.',
    //   )
    //   return
    // }
    if (item?.totalRatingScore / item?.totalRatingCount <= 3.5) {
      alert(`평점이 3.5점 이상인 상품만 선택 가능해요.`)
      return
    }
    if (checkedItemIds?.length >= 51) {
      alert('상품은 최대 50개까지 선택 가능해요.')
      return
    }
    if (e.target.checked) {
      setCheckedItemIds((prevIds) => [...prevIds, item._id])
      setCheckedItemsTitle((prevTitles) => [...prevTitles, item.itemTitle])
      setCheckedItemInfo((prevInfo) => [...prevInfo, item])
    } else {
      setCheckedItemIds((prevIds) => prevIds.filter((id) => id !== item._id))
      setCheckedItemsTitle((prevTitles) =>
        prevTitles.filter((title) => title !== item.itemTitle),
      )
      setCheckedItemInfo((prevInfo) =>
        prevInfo.filter((info) => info._id !== item._id),
      )
    }
  }

  return (
    <div className='w-[80vw]'>
      <div className='w-2/3 '>
        <div className='flex flex-col items-start flex-1 p-8 mb-5 bg-white shadow-lg rounded-xl'>
          <div className='flex flex-col text-xl whitespace-pre-line'>
            <div className='font-bold'>
              해당 광고 상품은 12월 15일 이후 CPM 광고 신청을 통해 이용
              가능합니다.
            </div>
            {`12월 15일까지만 광고 신청이 가능하며, 신청된 광고는 12월 15일까지 노출 후 자동 종료됩니다. 
            CPM 광고 신청을 통해 더 높은 효율로 해당 광고 상품을 이용하실 수 있습니다.`}
            <Button
              className='bg-blue-500 text-white w-fit mt-5'
              key='apply'
              onClick={() => {
                history.push('/ad-cpm-apply', { showIntroModal: true })
              }}
            >
              CPM 광고 신청
            </Button>
          </div>
        </div>
        <div className='flex flex-col items-start flex-1 p-8 mb-5 bg-white shadow-lg rounded-xl'>
          <div className='flex flex-row items-center mb-5 text-xl font-bold'>
            <div className='mr-3 text-3xl text-amber-500'>All</div> 모든 등급의
            상품 신청 가능합니다.
          </div>
          <div>120만 명이 보는 퀴즈 광고를 만나보세요!</div>
        </div>
        {/* <div>
          <QuizAdRevenueGraph />
        </div> */}
        {/* <div>
          <QuizAdApplicationGraph />
        </div> */}
        <div className='mt-12 mb-10'>
          <ApplyTabHandler
            applyStatusTab={adStatusTab}
            setApplyStatusTab={setAdStatusTab}
            setPage={setPage}
            btnText1='광고 신청'
            btnText2='신청 완료'
          />
        </div>
      </div>
      {adStatusTab === 'candidate' ? (
        <div className='flex flex-row flex-1'>
          <div className='flex w-2/3 p-10 bg-white shadow-lg rounded-xl'>
            <div className='flex flex-col flex-1'>
              <div className='mb-12'>
                <div className='mb-5 text-2xl font-bold'>
                  올팜 퀴즈 광고 신청
                </div>
                <div className='flex flex-col flex-1 p-5 bg-gray-100 rounded-xl'>
                  <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
                    <BulbIcon className='mr-2' />
                    퀴즈 광고란?
                  </span>
                  <ul className='ml-5 list-disc'>
                    <li>
                      퀴즈 광고는 올팜에서 제품과 관련된 퀴즈를 노출하여 구매를
                      촉진하는 광고입니다.
                    </li>
                    <li>
                      올팜은 고객이 선택한 작물을 키우고 키운 작물을 공짜로 받는
                      미니 프로그램으로 매일 120만 명의 고객이 이용하고
                      있습니다.
                    </li>
                    <li>{`고객은 '퀴즈 상품'과 관련된 퀴즈를 풀게 됩니다.`}</li>
                    <li>
                      퀴즈를 맞출 경우 소정의 1차 보상이 지급되며, 제품을 구매할
                      경우 큰 2차 보상이 지급되는 구조로{' '}
                      <span className='font-bold text-blue-500'>
                        전환율이 높은 지면
                      </span>
                      입니다.
                    </li>
                    <li>
                      퀴즈는 최소{' '}
                      <span className='font-bold text-blue-500'>
                        5만 건 부터
                      </span>{' '}
                      구입 가능합니다.
                    </li>
                    <li>
                      신청해주신 상품들의 입찰가, 퀴즈 퀄리티, 상품적합성 등을
                      복합적으로 고려하여{' '}
                      <span className='font-bold text-blue-500'>광고 승인</span>
                      이 진행됩니다.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='mb-12'>
                <div className='mb-3 text-xl font-bold'>1. 진행 일자</div>
                <div className='flex flex-row flex-1'>
                  <Calendar
                    onChange={setStartDate}
                    value={startDate}
                    maxDate={
                      moment(new Date())
                        .startOf('day')
                        .add(9, 'days')
                        .isBefore(moment('2024-12-15').endOf('day'))
                        ? new Date(
                            moment(new Date()).startOf('day').add(9, 'days'),
                          )
                        : new Date(moment('2024-12-15').endOf('day'))
                    }
                    minDate={
                      new Date(moment(new Date()).startOf('day').add(3, 'days'))
                    }
                  />
                  <div className='flex flex-col items-end justify-between flex-1 ml-5'>
                    <div className='flex flex-col p-5 bg-gray-100 rounded-xl'>
                      <div className='flex flex-row items-center flex-1 mb-1'>
                        <CheckIcon className='mr-2' />
                        <span className='font-bold'>신청 후 검수 안내</span>
                      </div>
                      <div>
                        퀴즈 광고는{' '}
                        <span className='font-bold text-blue-500'>
                          신청 후 검수
                        </span>
                        를 통해 진행됩니다. 검수 과정에는 퀴즈 내용의 적절성,
                        상품 적합성 등이 포함됩니다.
                      </div>
                      <div>
                        날짜별로 구좌 제한이 있습니다. 광고 신청 후 검수가
                        완료되면 본 페이지 및 SMS를 통해 알려드립니다.
                      </div>
                    </div>
                    <div className='flex flex-col items-end'>
                      <div>광고 시작 일자</div>
                      <div className='flex flex-row items-end justify-end flex-1 my-3'>
                        <div className='mr-2 text-gray-500'>
                          {moment(new Date(startDate)).format('YYYY년')}
                        </div>
                        <div className='text-4xl font-bold'>
                          {moment(new Date(startDate)).format('MM월 DD일')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-12'>
                <div className='mb-3 text-xl font-bold'>2. 상품 선정</div>
                <div>
                  <li>퀴즈 광고를 진행할 상품을 선택해주세요.</li>
                  <li>상품은 최소 5개, 최대 50개까지 선택가능해요.</li>
                  <li>
                    상품 선택순으로 노출돼요. 상단에 노출할 상품을 먼저
                    선택해주세요.
                  </li>
                  <li className='text-red-500 font-bold'>
                    {`가장 처음 선택한 상품이 퀴즈를 내는 '퀴즈 상품'이 됩니다.`}
                  </li>
                  <li>
                    상품들이 동시 노출되기 때문에 상품 숫자가 많을수록 판매에
                    유리해요.
                  </li>
                  <Table columns={columns} data={data} />
                  <Pagination
                    total={itemsCount}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    size='lg'
                  />
                </div>
              </div>
              <div className='mb-12'>
                <div className='mt-5 mb-3 text-xl font-bold'>3. 퀴즈 작성</div>
                <div className='mb-3'>
                  퀴즈 상품과 관련된 퀴즈 내용을 작성해주세요.
                </div>
                <div>
                  <div className='flex flex-row justify-between flex-1'>
                    <div>
                      <div className='flex flex-col p-5 mb-5 bg-gray-100 rounded-xl'>
                        <div className='flex flex-row items-center flex-1 mb-1'>
                          <CheckIcon className='mr-2' />
                          <span className='font-bold'>퀴즈 작성 안내</span>
                        </div>
                        <div>
                          난이도가 쉬운{' '}
                          <span className='font-bold text-blue-500'>
                            간단한 퀴즈
                          </span>
                          를 진행할 때 고객 참여도가 올라갑니다.
                        </div>
                        <div>
                          {`퀴즈를 작성한 이후 하단의 '적용하기' 버튼을`}
                          눌러주세요.
                        </div>
                      </div>
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className='flex flex-col gap-2 '
                      >
                        <span>
                          <span className='font-bold text-gray-500'>문제</span>
                          <Tooltip text='예시) 올웨이즈 식품 카테고리에서 가장 많이 팔린 상품은 무엇일까요?' />
                        </span>
                        <input
                          className='rounded-lg'
                          type='text'
                          placeholder='문제를 적어주세요.'
                          {...register('quizDescription', { required: true })}
                        />
                        <span>
                          <span className='font-bold text-gray-500'>
                            1번 선택지
                          </span>
                          <Tooltip text='예시) 두리안' />
                        </span>
                        <input
                          className='rounded-lg'
                          type='text'
                          placeholder='첫 번째 선택지를 적어주세요.'
                          {...register('quizChoice1', {})}
                        />
                        <span>
                          <span className='font-bold text-gray-500'>
                            2번 선택지
                          </span>
                          <Tooltip text='예시) 고구마' />
                        </span>
                        <input
                          className='rounded-lg'
                          type='text'
                          placeholder='두 번째 선택지를 적어주세요.'
                          {...register('quizChoice2', {})}
                        />
                        <span>
                          <span className='font-bold text-gray-500'>
                            3번 선택지
                          </span>
                          <Tooltip text='예시) 감자' />
                        </span>
                        <input
                          className='rounded-lg'
                          type='text'
                          placeholder='세 번째 선택지를 적어주세요.'
                          {...register('quizChoice3', {})}
                        />
                        <span>
                          <span className='font-bold text-gray-500'>
                            정답 번호
                          </span>
                          <Tooltip text='예시) 2' />
                        </span>
                        <input
                          className='rounded-lg'
                          type='number'
                          max={3}
                          placeholder='정답 번호를 적어주세요'
                          {...register('quizAnswer', {})}
                        />
                        <input
                          type='submit'
                          value='퀴즈 등록'
                          className='py-2 mt-5 text-lg font-bold text-white border-b-2 border-r-2 border-gray-700 rounded-lg shadow-md bg-gradient-to-r from-gray-400 to-gray-600 hover:bg-gradient-to-r hover:from-gray-500 hover:to-gray-800'
                        />
                      </form>
                    </div>
                    <div className='relative select-none w-80'>
                      <img
                        src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/45815db8-39d3-4e3e-916e-2e33556245ae.png'
                        className='relative w-80'
                        alt='alfarm_banner_image'
                      />
                      <div className='absolute text-lg top-60 left-10 right-10'>
                        <div className='text-lg font-bold text-red-500'>
                          퀴즈
                        </div>
                        <div>{quizInfo.quizDescription}</div>
                        <div
                          className={`flex items-center justify-center flex-1 w-full h-10 mt-3  border-2 border-gray-500 select-none rounded-xl hover:border-blue-500 ${
                            parseInt(quizInfo?.quizAnswer, 10) === 1
                              ? 'bg-blue-200'
                              : 'bg-white'
                          }`}
                        >
                          {quizInfo.quizChoice1 || '1번 선택지'}
                        </div>
                        <div
                          className={`flex items-center justify-center flex-1 w-full h-10 mt-3  border-2 border-gray-500 select-none rounded-xl hover:border-blue-500 ${
                            parseInt(quizInfo?.quizAnswer, 10) === 2
                              ? 'bg-blue-200'
                              : 'bg-white'
                          }`}
                        >
                          {quizInfo.quizChoice2 || '2번 선택지'}
                        </div>
                        <div
                          className={`flex items-center justify-center flex-1 w-full h-10 mt-3  border-2 border-gray-500 select-none rounded-xl hover:border-blue-500 ${
                            parseInt(quizInfo?.quizAnswer, 10) === 3
                              ? 'bg-blue-200'
                              : 'bg-white'
                          }`}
                        >
                          {quizInfo.quizChoice3 || '3번 선택지'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='mt-5 mb-3 text-xl font-bold'>
                  4. 퀴즈 수량 선택
                </div>
                <div>퀴즈 수량과 개당 가격을 선택해주세요.</div>
                <div>
                  퀴즈 구좌는 일 소진량이 정해져있는 한정 구좌로 상품 등급과
                  입찰가, 퀴즈 퀄리티를 반영하여{' '}
                  <span className='font-bold text-blue-500'>일단위로 선정</span>
                  됩니다.
                </div>
                <div className='mb-3'>
                  최소 입찰가는 6원이며 입찰가가 높을 수록 선정에 유리합니다.
                </div>
                <div className='flex flex-row flex-1'>
                  <div className='mr-5'>
                    <div className='font-bold'>퀴즈 수량</div>
                    <select
                      onChange={(e) => {
                        setQuizCount(e.target.value)
                      }}
                    >
                      <option value={50000}>5만 개</option>
                      <option value={100000}>10만 개</option>
                      <option value={150000}>15만 개</option>
                      <option value={200000}>20만 개</option>
                      <option value={300000}>30만 개</option>
                      <option value={500000}>50만 개</option>
                    </select>
                  </div>
                  <div className='mr-5'>
                    <div className='font-bold'>퀴즈당 비용(₩)</div>
                    <input
                      type='number'
                      value={impCost}
                      onChange={(e) => {
                        setImpCost(e.target.value)
                      }}
                      min={6}
                      max={100}
                    />
                  </div>
                  <div className='flex flex-col justify-start flex-1 mr-5'>
                    <div className='mb-2 font-bold'>결제 방식</div>
                    <div className='flex items-center flex-1'>
                      <input type='radio' checked className='mr-2' readOnly />{' '}
                      정산액 차감
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed right-0 z-50 flex flex-col items-start flex-1 w-1/4 p-10 mr-20 bg-white shadow-xl rounded-xl bottom-10'>
            <div className='mb-5'>
              <div className='mb-3 text-lg font-bold'>광고 신청서</div>
              <div className='mb-2'>
                신청일자:{' '}
                <span className='font-bold text-blue-500'>
                  {moment(new Date(startDate)).format('MM월 DD일')}
                </span>
              </div>
              <div className='mb-0'>
                퀴즈 상품:{' '}
                <span className='font-bold text-amber-500'>
                  {checkedItemsTitle?.[0]}
                </span>
              </div>
              <div className='mb-2'>
                퀴즈:{' '}
                <span className='font-bold text-blue-500'>
                  {quizInfo.quizDescription}
                </span>
              </div>
              <div className='mb-2'>
                1번:{' '}
                <span className='font-bold text-blue-500'>
                  {quizInfo.quizChoice1}
                </span>
              </div>
              <div className='mb-2'>
                2번:{' '}
                <span className='font-bold text-blue-500'>
                  {quizInfo.quizChoice2}
                </span>
              </div>
              <div className='mb-2'>
                3번:{' '}
                <span className='font-bold text-blue-500'>
                  {quizInfo.quizChoice3}
                </span>
              </div>
              <div className='mb-2'>
                정답:{' '}
                <span className='font-bold text-blue-500'>
                  {quizInfo.quizAnswer}번
                </span>
              </div>
              <div className='mb-2'>
                신청 상품명(노출 순서) <br />
                {checkedItemsTitle?.map((item, index) => {
                  return (
                    <div key={item._id}>
                      {index + 1}.{' '}
                      <span className='font-bold text-blue-500'>
                        {item} <br />{' '}
                      </span>
                    </div>
                  )
                })}
              </div>
              <div className='mb-2'>
                신청 퀴즈 수:{' '}
                <span className='font-bold text-blue-500'>
                  {commaNumber(quizCount)}
                </span>
                회
              </div>
              <div className='mb-2'>
                총 가격:{' '}
                <span className='font-bold text-blue-500'>
                  ￦{commaNumber(quizCount * impCost)}(정산액 차감)
                </span>
              </div>
            </div>
            <Button
              onClick={async () => {
                const adUnit = {
                  itemIds:
                    checkedItemIds?.filter(
                      (item, index, self) =>
                        item && index === self.findIndex((t) => t === item),
                    ) || [],
                  itemId: checkedItemIds?.[0],
                  itemImage: checkedItemInfo?.[0]?.mainImageUris?.[0],
                  quizDescription: quizInfo?.quizDescription,
                  quizChoice: {
                    one: quizInfo?.quizChoice1,
                    two: quizInfo?.quizChoice2,
                    three: quizInfo?.quizChoice3,
                  },
                  quizAnswer: parseInt(quizInfo?.quizAnswer, 10),
                  quizCount,
                  costPerQuiz: impCost,
                  quizStartedAt: new Date(
                    moment(new Date(startDate)).startOf('day'),
                  ),
                  quizEndedAt: new Date(
                    moment(new Date(startDate)).startOf('day').add(3, 'days'),
                  ),
                }
                if (adUnit?.quizChoice?.one?.length > 7) {
                  alert('퀴즈 정답은 7글자까지 입력 가능해요.')
                  return
                }
                if (adUnit?.quizChoice?.two?.length > 7) {
                  alert('퀴즈 정답은 7글자까지 입력 가능해요.')
                  return
                }
                if (adUnit?.quizChoice?.three?.length > 7) {
                  alert('퀴즈 정답은 7글자까지 입력 가능해요.')
                  return
                }
                if (!adUnit?.quizDescription?.endsWith('무엇일까요?')) {
                  alert('퀴즈는 "무엇일까요?"로 끝나야 신청 가능해요.')
                  return
                }
                if (sellerSettlementAmount <= 0) {
                  alert('최근 3주 정산액이 없는 경우 광고 신청이 불가능합니다.')
                  return
                }
                if (checkedItemIds?.length <= 4) {
                  alert(`최소 5개의 상품을 선택해주세요.`)
                  return
                }
                if (!adUnit.quizDescription) {
                  alert('퀴즈 상품의 퀴즈를 입력해주세요.')
                  return
                }
                if (!adUnit.quizChoice.one) {
                  alert('1번 선택지를 입력해주세요.')
                  return
                }
                if (!adUnit.quizChoice.two) {
                  alert('2번 선택지를 입력해주세요.')
                  return
                }
                if (!adUnit.quizChoice.three) {
                  alert('3번 선택지를 입력해주세요.')
                  return
                }
                if (!adUnit.quizAnswer) {
                  alert('정답을 입력해주세요.')
                  return
                }
                if (!adUnit.quizCount) {
                  alert('퀴즈 수량을 입력해주세요.')
                  return
                }
                if (!adUnit.costPerQuiz) {
                  alert('퀴즈당 비용을 입력해주세요.')
                  return
                }
                if (adUnit.costPerQuiz < 6) {
                  alert('퀴즈 최소 입찰가는 퀴즈당 6원입니다.')
                  return
                }
                if (adUnit.costPerQuiz.toString().includes('.')) {
                  alert('단가는 정수로 입력해야 합니다.')
                  return
                }
                if (!adUnit.quizStartedAt) {
                  alert('퀴즈 시작일을 입력해주세요.')
                  return
                }
                if (SellerStore.isLoading) return
                SellerStore.setIsLoading(true)
                const result = await backendApis.registerQuizAdV2(adUnit)
                if (result?.status === 200) {
                  alert(
                    '광고 신청이 완료되었습니다. 입력해주신 광고 시작일자 전일에 퀴즈 진행이 확정되며 승인된 건에 한하여 퀴즈가 시작됩니다.',
                  )
                  cleanUp()

                  setAdStatusTab('complete')
                } else {
                  alert('광고 신청에 실패했습니다. 다시 시도해주세요.')
                }
                SellerStore.setIsLoading(false)
              }}
              appearance='positive'
              size='flexlg'
            >
              신청하기
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <QuizAdStatusBar />
        </div>
      )}
    </div>
  )
}
export default AlfarmQuizAdScreenV2
