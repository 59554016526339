import { useEffect, useState } from 'react'
import { Button, Checkbox, Modal } from 'antd'

import creditRewardEligibleSellers from './creditRewardEligibleSellers.json'
import SellerStore from '../../../stores/SellerStore'

const CpmAdRewardNoticeModal = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [checked, setChecked] = useState(false)

  const isEligible = creditRewardEligibleSellers.data.includes(
    SellerStore?.sellerInfo?._id,
  )

  const handleClose = () => {
    setIsVisible(false)
  }

  const handleOk = () => {
    if (checked) {
      localStorage.setItem(
        `@alwayz@cpmAdRewardNoticeLastShown@${SellerStore?.sellerInfo?._id}`,
        true,
      )
    }
    handleClose()
  }

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked)
  }

  useEffect(() => {
    if (new Date() > new Date('2025-01-10T23:59:59+09:00') || !isEligible) {
      return
    }

    const isShownNotice = localStorage.getItem(
      `@alwayz@cpmAdRewardNoticeLastShown@${SellerStore?.sellerInfo?._id}`,
    )

    if (!isShownNotice) {
      setIsVisible(true)
    }
  }, [isEligible])

  if (new Date() > new Date('2025-01-10T23:59:59+09:00') || !isEligible) {
    return null
  }

  return (
    <Modal
      footer={
        <div className='flex justify-between'>
          <Checkbox
            checked={checked}
            className='items-center justify-center'
            onChange={handleCheckboxChange}
          >
            다시 보지 않기
          </Checkbox>
          <div>
            <Button onClick={handleOk} type='primary' className='bg-blue-500'>
              확인
            </Button>
          </div>
        </div>
      }
      centered
      title='공지사항'
      open={isVisible}
      onOk={handleOk}
      onCancel={() => setIsVisible(false)}
    >
      <div className='flex flex-col break-all whitespace-pre-line'>
        <div className='text-base'>
          {`
          안녕하세요 판매자님, CPM 광고를 이용해주셔서 감사합니다.

          광고 시스템을 점검하는 과정에서 일부 매출 데이터가 성과 페이지 표기에 정상 반영되지 않던 문제가 발견되었습니다.
          2024년 12월 27일 수정이 진행되었으며 표기 상 누락되었던 매출과 ROAS 데이터가 재반영되었습니다.`}
          <strong>
            실제 발생했던 매출에는 변화가 없으며 표기 상의 문제였던 점을 알려
            드립니다.
          </strong>
          {`광고 운영에 혼선을 드린 것에 진심으로 사과 드립니다.
          보상으로 3만원 상당의 무료 크레딧을 지급 드릴 예정이며, 관련 문제가 재발하지 않도록 노력하겠습니다. 더 좋은 광고 시스템으로 보답 드리겠습니다.
          감사합니다.

          올웨이즈 광고팀 드림
        `}
        </div>
      </div>
    </Modal>
  )
}

export default CpmAdRewardNoticeModal
