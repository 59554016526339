import configs from '../../utils/configs'
import moment from 'moment'
import 'moment-timezone'

const getCommissionRate = (order) => {
  let commissionRate = configs.getDefaultCommissionRate()

  if (!order?.payedAt) {
    return commissionRate
  }

  const payedYear = moment(order.payedAt).tz('Asia/Seoul').year()

  if (payedYear < 2025) {
    commissionRate = 0.035
  }

  if (
    typeof order.itemInfo?.characteristicItem?.commissionRate === 'number' &&
    Number(order?.itemInfo?.characteristicItem?.commissionRate) >= 0 &&
    Number(order?.itemInfo?.characteristicItem?.commissionRate) < 1
  ) {
    commissionRate = Number(order.itemInfo?.characteristicItem?.commissionRate)
  }

  return commissionRate
}

export default getCommissionRate
