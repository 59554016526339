import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { RocketFilled } from '@ant-design/icons'

const CpmAdPromotionModal = () => {
  const [isVisible, setIsVisible] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const lastShownDate = localStorage.getItem('cpmAdPromotionLastShown')
    const today = new Date().toDateString()

    if (!lastShownDate || lastShownDate !== today) {
      setIsVisible(true)
      localStorage.setItem('cpmAdPromotionLastShown', today)
    }
  }, [])

  const handleClose = () => {
    setIsVisible(false)
  }

  const handleGoToCpmAd = () => {
    history.push('/ad-cpm-apply', { showIntroModal: true })
    setIsVisible(false)
  }

  return (
    <Modal
      title={
        <div className='text-lg font-bold'>
          새로운 CPM 광고를 소개합니다!
          <RocketFilled className='ml-2' />
        </div>
      }
      open={isVisible}
      onCancel={handleClose}
      centered
      width={600}
      footer={[
        <Button key='close' onClick={handleClose}>
          닫기
        </Button>,
        <Button
          key='intro'
          onClick={() =>
            window.open(
              'https://drive.google.com/file/d/1vayx_8Iik_caKHLEraWJEDXTJQsPIUul/view?usp=sharing',
              '_blank',
            )
          }
        >
          CPM 광고 소개서 ↗
        </Button>,
        <Button
          key='apply'
          type='primary'
          onClick={handleGoToCpmAd}
          className='bg-blue-500 font-bold'
        >
          CPM 광고 신청하기
        </Button>,
      ]}
    >
      <div className='space-y-4 py-4'>
        <h3 className='text-lg font-bold'>
          <span className='text-red-500'>AI 맞춤 타겟팅</span>으로 더
          스마트하게!
        </h3>
        <p>
          CPM 광고는 AI가 고객의 성별, 연령, 구매 기록, 상품의 특성 등을
          분석하여 구매 가능성이 높은 고객에게 맞춤형으로 광고를 노출합니다.
        </p>
        <div className='bg-gray-50 p-4 rounded-lg'>
          <ul className='list-disc pl-4 space-y-2'>
            <li>구매 가능성이 높은 고객에게만 노출</li>
            <li>실시간 광고 성과 데이터 대시보드 제공</li>
            <li>정산 예정금이 없어도 광고 가능</li>
            <li>미사용 광고비는 100% 환급</li>
          </ul>
        </div>
        <p>
          더욱 자세한 내용은 <strong>CPM 광고 소개서</strong>를 참고해주세요.
        </p>
        <p>
          하단의 버튼 및 좌측의 <strong>[CPM 광고 신청]</strong> 메뉴를 통해
          광고를 신청하실 수 있습니다.
        </p>
        <p>감사합니다.</p>
        <p>올웨이즈 드림</p>
      </div>
    </Modal>
  )
}

export default CpmAdPromotionModal
