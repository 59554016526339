import React, { useState } from 'react'
import { Modal, Button } from 'antd'

const ServiceMigrationModal = () => {
  const [isVisible, setIsVisible] = useState(false)

  const handleClose = () => {
    setIsVisible(false)
  }

  return (
    <Modal
      title={<div className='text-lg font-bold'>서비스 점검 안내</div>}
      open={isVisible}
      onCancel={handleClose}
      centered
      width={600}
      footer={[
        <Button key='close' onClick={handleClose}>
          닫기
        </Button>,
      ]}
    >
      <div className='space-y-4 py-4'>
        <p>안녕하세요 올웨이즈입니다 </p>
        <p>
          올웨이즈 서비스 개선을 위해 DB작업이 진행될 예정입니다.
          작업시간동안에는 판매자 센터 관련 메뉴 접속이 불가하거나, 사용에
          불안정할 수 있으니 아래 일정 참고하시어 서비스 이용에 불편이 없으시길
          바랍니다
        </p>
        <div className='bg-gray-50 p-4 rounded-lg'>
          <ul className='list-disc pl-4 space-y-2'>
            <li>일정 : 2025년 12월 31일 02:00~05:00</li>
            <li> 영향범위 : 판매자 센터 로그인 후 모든 화면 </li>
          </ul>
        </div>
        <p>
          판매자 로그인 후 작업에 지연이 발생 될 수 있으므로 업무에 참고
          부탁드립니다
        </p>
        <p>감사합니다.</p>
        <p>올웨이즈 드림</p>
      </div>
    </Modal>
  )
}

export default ServiceMigrationModal
