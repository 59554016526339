import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import AlertModal from '../../../components/atoms/alertModal'
import useIsFreeCpmSeller from './hooks/useIsFreeCpmSeller'

const FreeCPMAdNoticeModal = () => {
  const history = useHistory()

  const isFreeCpmTargetSeller = useIsFreeCpmSeller()

  const [freeAdCpmGuideModal, setFreeAdCpmGuideModal] = useState(true)

  const handleGoToCpmAd = () => {
    setFreeAdCpmGuideModal(false)
    history.push('/ad-cpm-apply')
  }

  if (!isFreeCpmTargetSeller) {
    return null
  }

  return (
    <AlertModal
      centered
      hideDurationInDays={1}
      isTriggered={freeAdCpmGuideModal}
      handleTrigger={setFreeAdCpmGuideModal}
      onConfirm={handleGoToCpmAd}
      visibleAlertIcon={false}
      confirmText='CPM 광고 신청하러 가기'
      cancelText='닫기'
      title='CPM 광고를 무료로 신청해보세요!'
    >
      <div className='flex flex-col break-all whitespace-pre-line'>
        <div>
          {`판매자님은 CPM 광고 무료 이벤트 대상자입니다.
          CPM 광고는 올팜 등 다양한 인기 서비스들에서 AI 맞춤으로 구매 가능성이 높은 고객들에게만 상품을 노출합니다.
          무료 광고 이벤트는 한 번만 참여 가능하며, 예고 없이 종료될 수 있습니다.`}
        </div>
      </div>
    </AlertModal>
  )
}

export default FreeCPMAdNoticeModal
