import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import SalesInfo from '../components/molecules/salesInfo'
import SettlementInfo from '../components/molecules/settlementInfo'
import Notice from '../components/molecules/notice'
import CsInfo from '../components/molecules/csInfo'
import RevenueInfo from '../components/molecules/revenueInfo'
import WelcomeBanner from '../components/molecules/welcomeBanner'
import backendApis from '../utils/backendApis'
import AdCreditInfo from '../components/molecules/adCreditInfo'
import SellerPenaltyNotice from '../components/molecules/SellerPenaltyNotice'
import ShippingDaysChangeToTwoDaysAlert from '../components/molecules/ShippingDaysChangeToTwoDaysAlert'
import ShippingSystemChangeAlert from '../components/molecules/ShippingSystemChangeAlert'
import TodayDepartureAlert from '../components/molecules/TodayDepartureAlert'
import CommisionChangeNoticeModal from '../components/atoms/commisionChangeNoticeModal'
import CpmAdPromotionModal from '../components/atoms/CpmAdPromotionModal'
import FreeCPMAdNoticeModal from './ad/cpm/FreeCpmAdNoticeModal'
import ServiceMigrationModal from '../components/atoms/ServiceMigrationModal'

const WelcomeScreen = observer(() => {
  useEffect(() => {
    backendApis.recordEventLog('MainScreen', 'entered main screen')
  }, [])

  return (
    <div className='grid w-full grid-cols-2 gap-5 my-10 min-w-7xl max-w-7xl'>
      <div className='col-start-1 col-end-3'>
        <AdCreditInfo />
      </div>
      <div className='col-start-1 col-end-3'>
        <WelcomeBanner />
      </div>
      <div className='col-start-1 col-end-2'>
        <SalesInfo />
      </div>
      <div className='col-start-2 col-end-3'>
        <SettlementInfo />
      </div>
      <div className='col-start-1 col-end-2'>
        <RevenueInfo />
      </div>
      <div className='col-start-2 col-end-3'>
        <Notice />
      </div>
      <div className='col-start-1 col-end-3'>
        <SellerPenaltyNotice />
      </div>
      <div className='col-start-1 col-end-3'>
        <CsInfo />
      </div>
      <div className='col-start-1 col-end-3'>
        <ShippingDaysChangeToTwoDaysAlert />
      </div>
      <div className='col-start-1 col-end-3'>
        <ShippingSystemChangeAlert />
      </div>
      <div className='col-start-1 col-end-3'>
        <TodayDepartureAlert />
      </div>
      <CommisionChangeNoticeModal />
      <CpmAdPromotionModal />
      <FreeCPMAdNoticeModal />
      <ServiceMigrationModal />
    </div>
  )
})

export default WelcomeScreen
