import AuthStore from '../stores/AuthStore'
import { displayMaintenanceMode } from './maintenanceMode'

const updateHeader = (options) => {
  const updatedOptions = { ...options }
  const token = AuthStore.token !== '' ? AuthStore.token : AuthStore.loadToken()
  if (token) {
    updatedOptions.headers = {
      ...updatedOptions.headers,
      'x-access-token': token,
    }
  }
  return updatedOptions
}

export default async function fetchWrapper(
  url,
  options,
  shouldIncludeToken = true,
) {
  return fetch(url, updateHeader(options, shouldIncludeToken)).then(
    async (response) => {
      if (response.status === 503) {
        const clonedResponse = response.clone()
        const body = await clonedResponse.json()
        if (
          response.headers.get('X-Maintenance-Mode') === 'true' ||
          body.mode === 'maintenance'
        ) {
          displayMaintenanceMode(body.html)
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('maintenance mode')
        }
      }
      return response
    },
  )
}
