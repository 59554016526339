import moment from 'moment'
import React, { useState, useEffect, useMemo } from 'react'
import { FcIdea as BulbIcon, FcOk as CheckIcon } from 'react-icons/fc'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import SellerStore from '../../stores/SellerStore'
import adSettingByTab from '../../data/adSettingByTab'
import backendApis from '../../utils/backendApis'
import Table from '../../components/ADS/molecules/table'
import Pagination from '../../components/molecules/pagination'
import RankIconSetter from '../../components/molecules/rankIconSetter'
import resize from '../../utils/resize'
import Button from '../../components/atoms/button'
import commaNumber from 'comma-number'
import ApplyTabHandler from '../../components/molecules/applyTabHandler'
import AlfarmWinnerDealAdStatusBar from '../../components/molecules/AlfarmWinnerDealAdStatusBar'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

// 3일 동안 나눠서 노출된다는 거 알려주기
const AlfarmWinenrDealAdScreen = () => {
  const history = useHistory()
  const [startDate, setStartDate] = useState(
    new Date(moment(new Date()).startOf('day').add(3, 'days')),
  )
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemsCount, setItemsCount] = useState(0)
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [checkedItemInfo, setCheckedItemInfo] = useState([])
  const [checkedItemsTitle, setCheckedItemsTitle] = useState([])
  const [adCount, setAdCount] = useState(50000)
  const [costPerAd, setCostPerAd] = useState(6)
  const [sellerSettlementAmount, setSellerSettlementAmount] = useState(0)

  const [adStatusTab, setAdStatusTab] = useState('candidate')
  const [alreadyRegisteredItemIds, setAlreadyRegisteredItemIds] = useState([])

  const cleanUp = () => {
    setStartDate(new Date(moment(new Date()).startOf('day').add(3, 'days')))
    setPage(1)
    setLimit(5)
    setItemsInfo([])
    setItemsCount(0)
    setAdCount(50000)
    setCostPerAd(6)
    setCheckedItemIds([])
  }

  const columns = useMemo(
    () => [
      { Header: '선택', accessor: 'status' },
      { Header: '상품정보', accessor: 'itemInfo' },
    ],
    [],
  )

  const data = useMemo(
    () =>
      itemsInfo
        ?.filter((item) => item?.itemTitle)
        .map((item) => {
          return {
            status: (
              <div className='flex items-center justify-center flex-1 select-none'>
                <div className='flex flex-row flex-1 items-center justify-start min-w-[3%] max-w-[3%] select-none'>
                  <input
                    type='checkbox'
                    checked={checkedItemIds?.includes(item?._id)}
                    onChange={(e) => {
                      // if (alreadyRegisteredItemIds?.includes(item?._id)) {
                      //   alert(
                      //     '3일 이내 신청한 상품은 중복해서 신청할 수 없습니다. 다른 상품을 선택해주세요.',
                      //   )
                      //   return
                      // }
                      if (
                        item?.totalRatingScore / item?.totalRatingCount <=
                        3.5
                      ) {
                        alert(`평점이 3.5점 이상인 상품만 선택 가능해요.`)
                        return
                      }
                      if (checkedItemIds?.length >= 51) {
                        alert(`상품은 최대 50개까지 선택 가능해요.`)
                        return
                      }
                      if (e.target.checked) {
                        setCheckedItemIds([
                          ...checkedItemIds?.filter(
                            (itemId) => itemId !== item?._id,
                          ),
                          item?._id,
                        ])
                        setCheckedItemsTitle([
                          ...checkedItemsTitle?.filter(
                            (itemTitle) => itemTitle !== item?.itemTitle,
                          ),
                          item?.itemTitle,
                        ])
                        setCheckedItemInfo([
                          ...checkedItemInfo?.filter(
                            (itemId) => itemId !== item?._id,
                          ),
                          item,
                        ])
                      } else {
                        setCheckedItemIds(
                          checkedItemIds?.filter(
                            (itemId) => itemId !== item?._id,
                          ),
                        )
                        setCheckedItemsTitle(
                          checkedItemsTitle?.filter(
                            (itemTitle) => itemTitle !== item?.itemTitle,
                          ),
                        )
                        setCheckedItemInfo(
                          checkedItemInfo?.filter(
                            (itemId) => itemId !== item?._id,
                          ),
                        )
                      }
                    }}
                  />
                </div>
              </div>
            ),
            itemInfo: (
              <div key={item?._id}>
                <div className='flex flex-row justify-between flex-1 w-full'>
                  <div className='flex flex-row flex-1 w-max'>
                    <img
                      alt='mainImage'
                      src={
                        item?.mainImageUris
                          ? resize(item?.mainImageUris[0])
                          : ''
                      }
                      className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
                    />
                    <div className='mr-3'>
                      <div className='mb-1 text-lg font-bold whitespace-normal'>
                        {item?.itemTitle}
                      </div>

                      <div className='flex flex-row items-center mb-1'>
                        {item?.rankInfo && (
                          <div className='flex items-center px-2 py-0.5 bg-gray-100 rounded-md mr-2'>
                            <RankIconSetter
                              itemRank={item?.rankInfo?.itemRank}
                              className='w-4 h-4'
                            />
                            등급
                          </div>
                        )}
                        <div>
                          {item?.teamPurchasePrice?.toLocaleString() || 0}원
                        </div>
                      </div>
                      <div className='p-0.5'>
                        평점:{' '}
                        {!item?.totalRatingCount ? (
                          <>없음</>
                        ) : (
                          <>
                            {(
                              item?.totalRatingScore / item?.totalRatingCount
                            )?.toFixed(1)}
                          </>
                        )}
                      </div>

                      <div className='text-gray-400 '>{item?._id}</div>
                    </div>
                  </div>
                </div>
              </div>
            ),
          }
        }),
    [itemsInfo, checkedItemIds, alreadyRegisteredItemIds],
  )

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    itemCondition.soldOut = false
    itemCondition.outOfStock = { $ne: true }
    itemCondition.$or = [
      { itemFilters: { $eq: null } },
      {
        itemFilters: {
          $not: {
            $elemMatch: {
              $or: [{ key: 'ad', value: adSettingByTab?.[3].type }],
            },
          },
        },
      },
    ]

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )

    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }

    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      await SellerStore.loadSettlements()
    }
    fetchData()

    const filteredData = SellerStore?.settlements?.filter((item) => {
      const threeWeeksAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 21) // Corrected the threeWeeksAgo calculation
      return !item?.errorType && new Date(item?.createdAt) > threeWeeksAgo // Ensured both are Date objects for comparison
    })
    const settlementSum = filteredData?.reduce((acc, cur) => {
      return acc + cur?.totalSettlementAmount
    }, 0)

    console.log('settlementSum', settlementSum)
    setSellerSettlementAmount(settlementSum)
  }, [])

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [adStatusTab])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  useEffect(() => {
    setCheckedItemIds([])
    setCheckedItemsTitle([])
  }, [startDate])

  useEffect(() => {
    // 여기서 셀러 정보를 가져와서 지금까지 신청한 광고 정보를 얻는다.
    const fetchRegisteredAdInfo = async () => {
      const convertedStartDate = moment(new Date(startDate))
        .startOf('day')
        .subtract(4, 'days')
      const convertedEndDate = moment(new Date(startDate))
        .startOf('day')
        .subtract(-4, 'days')

      const result = await backendApis.getWinnerDealAdBetweenDate(
        convertedStartDate,
        convertedEndDate,
      )

      const allItemIds = result?.data?.flatMap(
        (entry) => entry.detailInfo.itemIds,
      )
      const uniqueItemIds = [...new Set(allItemIds)]

      setAlreadyRegisteredItemIds(uniqueItemIds)
    }
    fetchRegisteredAdInfo()
  }, [adStatusTab, startDate])

  return (
    <div className='w-[80vw]'>
      <div className='w-2/3 '>
        <div className='flex flex-col items-start flex-1 p-8 mb-5 bg-white shadow-lg rounded-xl'>
          <div className='flex flex-col text-xl whitespace-pre-line'>
            <div className='font-bold'>
              해당 광고 상품은 12월 15일 이후 CPM 광고 신청을 통해 이용
              가능합니다.
            </div>
            {`12월 15일까지만 광고 신청이 가능하며, 신청된 광고는 12월 15일까지 노출 후 자동 종료됩니다. 
            CPM 광고 신청을 통해 더 높은 효율로 해당 광고 상품을 이용하실 수 있습니다.`}
            <Button
              className='bg-blue-500 text-white w-fit mt-5'
              key='apply'
              onClick={() => {
                history.push('/ad-cpm-apply', { showIntroModal: true })
              }}
            >
              CPM 광고 신청
            </Button>
          </div>
        </div>
        <div className='flex flex-col items-start flex-1 p-8 mb-5 bg-white shadow-lg rounded-xl'>
          <div className='flex flex-row items-center mb-5 text-xl font-bold'>
            <div className='mr-3 text-3xl text-amber-500'>All</div> 모든 등급의
            상품 신청 가능합니다.
          </div>
          <div>
            120만 올팜 고객들에게 하루 30초 이상 상품들을 노출시켜요. <br />
          </div>
          {/* <div className='flex justify-end mt-6'>
            <button
              type='button'
              className='inline-block px-4 py-2 mr-5 border rounded-lg w-fit hover:bg-gray-100 text-white font-bold'
              style={{ backgroundColor: 'rgb(59, 130, 246)' }}
              onClick={() =>
                // 여기에 광고 소개서 링크 넣기
                window.open(
                  'https://drive.google.com/file/d/12I5gKaUw1SZEuQkthy0J-F_ReYc2vXCG/view?usp=sharing',
                  '_blank',
                )
              }
            >
              광고 소개서 ↗
            </button>
          </div> */}
        </div>
        {/* // 수정 필요한 영역  */}
        {/* <div>
          <WinnerDealAdApplicationGraph />
        </div> */}
        {/* <div>
          <AlfarmWinnerDealAdRevenueGraph />
        </div> */}
        <div className='mt-12 mb-10'>
          <ApplyTabHandler
            applyStatusTab={adStatusTab}
            setApplyStatusTab={setAdStatusTab}
            setPage={setPage}
            btnText1='광고 신청'
            btnText2='신청 완료'
          />
        </div>
      </div>
      {adStatusTab === 'candidate' ? (
        <div className='flex flex-row flex-1'>
          <div className='flex w-2/3 p-10 bg-white shadow-lg rounded-xl'>
            <div className='flex flex-col flex-1'>
              <div className='mb-12'>
                <div className='mb-5 text-2xl font-bold'>
                  오늘의특가 광고 신청
                </div>
                <div className='flex flex-col flex-1 p-5 bg-gray-100 rounded-xl'>
                  <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
                    <BulbIcon className='mr-2' />
                    오늘의특가 광고란?
                  </span>
                  <ul className='ml-5 list-disc'>
                    <li>
                      오늘의특가 광고는 올팜(농장 게임)에 노출하는 광고입니다.
                    </li>
                    <li>
                      올팜은 고객이 선택한 작물을 키우고 키운 작물을 공짜로 받는
                      미니 프로그램이에요.
                    </li>
                    <li>
                      작물을 키우기 위해 매일 120만명의 고객이 올팜을 이용하고
                      있어요.
                    </li>
                    <li>작물을 키우기 위해 물과 비료가 많이 필요해요.</li>
                    <li>
                      고객이 판매자님의 광고 상품을 30초간 구경하면 물을
                      보상으로 지급해요.
                    </li>
                    <li>
                      고객이 판매자님의 광고 상품을 구매하면 대량의 고급 비료를
                      보상으로 지급해요.
                    </li>
                    <li>
                      <span className='font-bold text-blue-500'>
                        여러개의 상품을 한번에 노출
                      </span>
                      할 수 있어 상품들의 노출을 극대화하고 효율을 높일 수
                      있어요.
                    </li>
                    <li>
                      신청해주신 상품들의 입찰가, 상품 퀄리티, 상품적합성 등을
                      복합적으로 고려하여{' '}
                      <span className='font-bold text-blue-500'>광고 승인</span>
                      이 진행돼요.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='mb-12'>
                <div className='mb-3 text-xl font-bold'>1. 진행 일자</div>
                <div className='flex flex-row flex-1'>
                  <Calendar
                    onChange={setStartDate}
                    value={startDate}
                    maxDate={
                      moment(new Date())
                        .startOf('day')
                        .add(9, 'days')
                        .isBefore(moment('2024-12-15').endOf('day'))
                        ? new Date(
                            moment(new Date()).startOf('day').add(9, 'days'),
                          )
                        : new Date(moment('2024-12-15').endOf('day'))
                    }
                    minDate={
                      new Date(moment(new Date()).startOf('day').add(3, 'days'))
                    }
                  />
                  <div className='flex flex-col items-end justify-between flex-1 ml-5'>
                    <div className='flex flex-col p-5 bg-gray-100 rounded-xl'>
                      <div className='flex flex-row items-center flex-1 mb-1'>
                        <CheckIcon className='mr-2' />
                        <span className='font-bold'>신청 후 검수 안내</span>
                      </div>
                      <div>
                        <li>
                          오늘의특가 광고는&nbsp;
                          <span className='font-bold text-blue-500'>
                            신청 후 검수
                          </span>
                          를 통해 진행돼요. 검수 과정에는 상품의 매력도, 상품의
                          적합성, 상품의 가격 등이 포함돼요.
                        </li>
                      </div>
                      <div>
                        <li>
                          날짜별로 구좌 제한이 있어요. <br />
                        </li>
                        <li>
                          광고 신청 후 검수가 완료되면 해당 페이지 및 SMS를 통해
                          알려드려요.
                        </li>
                      </div>
                    </div>
                    <div className='flex flex-col items-end'>
                      <div>광고 시작 일자</div>
                      <div className='flex flex-row items-end justify-end flex-1 my-3'>
                        <div className='mr-2 text-gray-500'>
                          {moment(new Date(startDate)).format('YYYY년')}
                        </div>
                        <div className='text-4xl font-bold'>
                          {moment(new Date(startDate)).format('MM월 DD일')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-12'>
                <div className='mb-3 text-xl font-bold'>2. 상품 선정</div>
                <div>
                  <li>오늘의특가 광고를 진행할 상품을 선택해주세요.</li>
                  <li>상품은 최소 5개, 최대 50개까지 선택가능해요.</li>
                  <li>
                    상품 선택순으로 노출돼요. 상단에 노출할 상품을 먼저
                    선택해주세요.
                  </li>
                  <li>
                    상품들이 동시 노출되기 때문에 상품 숫자가 많을수록 판매에
                    유리해요.
                  </li>
                  <Table columns={columns} data={data} />
                  <Pagination
                    total={itemsCount}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    size='lg'
                  />
                </div>
              </div>
              <div>
                <div className='mt-5 mb-3 text-xl font-bold'>
                  3. 광고 수량 선택
                </div>
                <li>광고 수량과 노출 단가를 선택해주세요.</li>
                <li>
                  오늘의특가 광고 구좌는 일 소진량이 정해져있는 한정 구좌로,
                  상품 등급 및 입찰가와 상품 가격을 반영하여 선정돼요.
                </li>
                <li>최소 입찰가는 6원이에요.</li>
                <li className='mb-4'> 입찰가가 높을 수록 선정에 유리해요.</li>
                <div className='flex flex-row flex-1'>
                  <div className='mr-5'>
                    <div className='font-bold'>광고 노출 수량</div>
                    <select
                      onChange={(e) => {
                        setAdCount(e.target.value)
                      }}
                    >
                      <option value={50000}>5만 개</option>
                      <option value={100000}>10만 개</option>
                      <option value={150000}>15만 개</option>
                      <option value={200000}>20만 개</option>
                      <option value={300000}>30만 개</option>
                      <option value={500000}>50만 개</option>
                    </select>
                  </div>
                  <div className='mr-5'>
                    <div className='font-bold'>광고 노출당 비용(₩)</div>
                    <input
                      type='number'
                      value={costPerAd}
                      onChange={(e) => {
                        setCostPerAd(e.target.value)
                      }}
                      min={6}
                      max={100}
                    />
                  </div>
                  <div className='flex flex-col justify-start flex-1 mr-5'>
                    <div className='mb-2 font-bold'>결제 방식</div>
                    <div className='flex items-center flex-1'>
                      <input type='radio' checked className='mr-2' readOnly />{' '}
                      정산액 차감
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed right-0 z-50 flex flex-col items-start flex-1 w-1/4 p-10 mr-20 bg-white shadow-xl rounded-xl bottom-10'>
            <div className='mb-5'>
              <div className='mb-3 text-lg font-bold'>광고 신청서</div>
              <div className='mb-2'>
                신청일자:{' '}
                <span className='font-bold text-blue-500'>
                  {moment(new Date(startDate)).format('MM월 DD일')}
                </span>
              </div>
              <div className='mb-2'>
                신청 상품명(노출 순서) <br />
                {checkedItemsTitle?.map((item, index) => {
                  return (
                    <div key={item._id}>
                      {index + 1}.{' '}
                      <span className='font-bold text-blue-500'>
                        {item} <br />{' '}
                      </span>
                    </div>
                  )
                })}
              </div>

              <div className='mb-2'>
                신청 광고 수:{' '}
                <span className='font-bold text-blue-500'>
                  {commaNumber(adCount)}
                </span>
                회
              </div>
              <div className='mb-2'>
                최대 광고비:{' '}
                <span className='font-bold text-blue-500'>
                  ￦{commaNumber(adCount * costPerAd)}(정산액 차감)
                </span>
              </div>
            </div>
            <Button
              onClick={async () => {
                const adUnit = {
                  itemIds:
                    checkedItemIds?.filter(
                      (id, index, self) =>
                        index === self.findIndex((t) => t === id),
                    ) || [],
                  adCount,
                  costPerAd,
                  adStartedAt: new Date(
                    moment(new Date(startDate)).startOf('day'),
                  ),
                  adEndedAt: new Date(
                    moment(new Date(startDate)).startOf('day').add(3, 'days'),
                  ),
                }
                if (checkedItemIds?.length <= 4) {
                  alert(`최소 5개의 상품을 선택해주세요.`)
                  return
                }
                if (sellerSettlementAmount <= 0) {
                  alert('최근 3주 정산액이 없는 경우 광고 신청이 불가능합니다.')
                  return
                }
                // if (adUnit.itemIds?.length < 3) {
                //   alert('상품은 최소 3개 이상 신청해야 해요.')
                //   return
                // }
                // if (adDescription?.length <= 3) {
                //   alert('매력 문구를 입력해주세요.')
                //   return
                // }
                // if (adDescription?.length > 19) {
                //   alert('매력 문구는 최대 18자까지 적을 수 있어요')
                //   return
                // }
                if (!adUnit.adCount) {
                  alert('광고 수량을 입력해주세요.')
                  return
                }
                if (!adUnit.costPerAd) {
                  alert('광고당 단가를 입력해주세요.')
                  return
                }
                if (adUnit.costPerAd < 6) {
                  alert('광고 최소 입찰가는 광고당 6원입니다.')
                  return
                }
                if (adUnit?.costPerAd.toString().includes('.')) {
                  alert('단가는 정수로 입력해야 합니다.')
                  return
                }
                if (!adUnit.adStartedAt) {
                  alert('광고 시작일을 입력해주세요.')
                  return
                }
                if (SellerStore.isLoading) return

                SellerStore.setIsLoading(true)
                // 광고 신청 시 백엔드로 넘겨야 함
                const result = await backendApis.registerAlfarmWinnerDealAd(
                  adUnit,
                )
                if (result?.status === 200) {
                  alert(
                    '광고 신청이 완료되었습니다. 입력해주신 광고 시작일자 전일에 광고 진행이 확정되며 승인된 건에 한하여 광고가 시작됩니다.',
                  )
                  cleanUp()

                  setAdStatusTab('complete')
                } else {
                  alert('광고 신청에 실패했습니다. 다시 시도해주세요.')
                }
                SellerStore.setIsLoading(false)
              }}
              appearance='positive'
              size='flexlg'
            >
              신청하기
            </Button>
          </div>
        </div>
      ) : (
        // 수정 필요한 영역
        <div>
          <AlfarmWinnerDealAdStatusBar />
        </div>
      )}
    </div>
  )
}
export default AlfarmWinenrDealAdScreen
