import React, { useState, useEffect } from 'react'
import { FcAdvertising } from 'react-icons/fc'
import { Modal, Button } from 'antd'
import AdCardList from '../../components/molecules/adCardList'
import SearchAdScreen from './SearchAdScreen'
import CpcAdScreen from './CpcAdScreen'
import AlfarmStoreAdScreen from './AlfarmStoreAdScreen'
import AlfarmTrendQuizScreen from './AlfarmTrendQuizScreen'
import AlfarmMinigameAdScreen from './AlfarmMinigameAdScreen'
import AlfarmLeavesAdScreen from './AlfarmLeavesAdScreen'
import AlfarmWinenrDealAdScreen from './AlfarmWinnerDealAdScreen'
import AlfarmEggBreakersScreenV2 from './AlfarmEggBreakersAdScreenV2'
import AlfarmQuizAdScreenV2 from './AlfarmQuizAdScreenV2'
import CpmAdScreen from './cpm/CpmAdScreen'
import { useHistory } from 'react-router-dom'
import Alert from '../../images/alert.png'

const AdScreen = ({ type }) => {
  const history = useHistory()
  const [tab, setTab] = useState(0)
  const [isNoticeModalVisible, setIsNoticeModalVisible] = useState(false)

  // 공지 표시 기간 설정
  const NOTICE_START_DATE = new Date('2024-12-06')
  const NOTICE_END_DATE = new Date('2025-01-08') // 종료일 23:59:59 까지로 설정
  NOTICE_END_DATE.setHours(23, 59, 59, 999)

  const isNoticeEnabled = () => {
    const now = new Date()
    return now >= NOTICE_START_DATE && now <= NOTICE_END_DATE
  }

  // 메뉴바 이동했을 때 자동으로 첫 번째 탭으로 이동하도록 설정
  useEffect(() => {
    if (type === 'normal') {
      setTab(0)
    } else if (type === 'alfarm') {
      setTab(5)
    } else if (type === 'cpm') {
      setTab(9)
    }
  }, [type])

  let selectedTab = null
  switch (tab) {
    case 0:
      selectedTab = <CpcAdScreen />
      break
    case 1:
      selectedTab = <SearchAdScreen /> // 검색 광고
      break
    case 2:
      selectedTab = <AlfarmLeavesAdScreen /> // 낙엽 광고
      break
    case 3:
      selectedTab = <AlfarmMinigameAdScreen /> // 미니게임 광고
      break
    case 4:
      selectedTab = <AlfarmQuizAdScreenV2 /> // 퀴즈 광고
      break
    case 5:
      selectedTab = <AlfarmStoreAdScreen /> // 올팜 상점 광고
      break
    case 6:
      selectedTab = <AlfarmEggBreakersScreenV2 /> // 포춘쿠키 광고
      break
    case 7:
      selectedTab = <AlfarmTrendQuizScreen /> // 우주 최저가 광고
      break
    case 8:
      selectedTab = <AlfarmWinenrDealAdScreen /> // 오늘의 특가 광고
      break
    case 9:
      selectedTab = <CpmAdScreen />
      break
    default:
      break
  }

  return (
    <>
      <div className='w-[85vw] pl-10 pr-20 py-10 mt-10 '>
        <div className='flex flex-col flex-1 mb-8'>
          {type === 'alfarm' && isNoticeEnabled() && (
            <Button
              className='flex flex-row items-center flex-1 px-5 py-3 my-5 text-lg shadow-md bg-white rounded-xl w-max hover:bg-gray-50'
              onClick={() => setIsNoticeModalVisible(true)}
            >
              <img src={Alert} alt='Alert' className='w-6 h-6' />
              <div>CPM 통폐합 예정 공지 사항</div>
            </Button>
          )}
          <div className='flex flex-row items-center flex-1 mb-5'>
            <FcAdvertising className='w-8 h-8 mr-2' />
            <div className='text-2xl font-bold select-none'>광고 신청</div>
          </div>
          {type === 'normal' && (
            <div className='flex flex-row items-center flex-1 px-5 py-5 my-5 text-lg shadow-md bg-white rounded-xl w-max'>
              <div>
                이제 누구든지 파워 퍼포먼스(CPC) 광고를 시작할 수 있어요. 클릭
                한 번으로 나의 상품을 최상단에 노출해보세요.
              </div>
            </div>
          )}
          <AdCardList tab={tab} setTab={setTab} type={type} />
          <Modal
            title={
              <div className='flex items-center py-2'>
                <img src={Alert} alt='Alert' className='w-6 h-6 mr-2' />
                CPM 광고 통폐합 예정 공지
              </div>
            }
            open={isNoticeModalVisible}
            onCancel={() => setIsNoticeModalVisible(false)}
            width={600}
            footer={[
              <Button
                className='bg-blue-500 text-white'
                key='apply'
                onClick={() => {
                  setIsNoticeModalVisible(false)
                  history.push('/ad-cpm-apply', { showIntroModal: true })
                }}
              >
                CPM 광고 신청
              </Button>,
              <Button
                key='close'
                onClick={() => setIsNoticeModalVisible(false)}
              >
                확인
              </Button>,
            ]}
            centered
          >
            <div className='space-y-4'>
              <p>안녕하세요, 올웨이즈 판매자님.</p>
              <p>
                더 나은 광고 서비스 제공을 위해 일부 올팜 광고가 CPM 광고로{' '}
                <strong className='text-red-500'>통폐합</strong>될 예정임을
                알려드립니다.
              </p>
              <p>
                CPM 광고는 AI 고객 맞춤 노출형 광고로 더 높은 광고 효율을 기대할
                수 있습니다.
              </p>
              <p>
                하단의 버튼 및 좌측의 <strong>[CPM 광고 신청]</strong> 메뉴를
                통해 광고를 신청하실 수 있습니다.
              </p>
              <div className='bg-gray-50 p-4 rounded-lg'>
                <h4 className='font-bold mb-2'>주요 변경사항</h4>
                <ul className='list-disc pl-4 space-y-2'>
                  <li>
                    변경 예정일: <strong>2024년 12월 16일 00시</strong>
                  </li>
                  <li>
                    기존{' '}
                    <strong>
                      [오늘의 특가, 낙엽, 미니게임, 퀴즈, 포춘쿠키]
                    </strong>
                    광고는{' '}
                    <strong className='text-red-500'>
                      2024년 12월 15일 23시 59분
                    </strong>
                    까지 운영됩니다.
                  </li>
                  <li>
                    <strong>[상점 광고, 우주 최저가]</strong> 광고는 기존과
                    동일하게 운영됩니다.
                  </li>
                  {/* <li>
                    미사용 CPM 광고 크레딧은 CPC 광고 크레딧으로 자동
                    전환됩니다.
                  </li> */}
                </ul>
              </div>
              <p>감사합니다.</p>
              <p>올웨이즈 드림</p>
            </div>
          </Modal>
        </div>
        {selectedTab}
      </div>
    </>
  )
}
export default AdScreen
