import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  Table,
  Tag,
  Space,
  Button,
  message,
  Modal,
  Spin,
  List,
  Select,
} from 'antd'
import moment from 'moment'
import backendApis from '../../../utils/backendApis'
import RadioGroup from '../../../components/ADS/molecules/radioGroup'
import { ReloadOutlined, CopyOutlined } from '@ant-design/icons'
import DatePicker from 'react-tailwindcss-datepicker'

// 상수 정의
const STATUS_CONFIG = {
  pending: { color: 'gold', text: '대기 중' },
  rejected: { color: 'red', text: '거부됨' },
  active: { color: 'green', text: '진행중' },
  cancelledAfterActive: { color: 'gray', text: '취소됨' },
  cancelledBeforeActive: { color: 'gray', text: '취소됨' },
  completed: { color: 'blue', text: '종료됨' },
}

const GENDER_MAP = {
  all: '전체',
  male: '남성',
  female: '여성',
}

const DATE_OPTIONS = [
  { label: '전체 기간', value: 'all' },
  { label: '날짜 선택', value: 'custom' },
]

const STATUS_OPTIONS = [
  { label: '전체', value: 'all' },
  { label: '대기 중', value: 'pending' },
  { label: '진행중', value: 'active' },
  { label: '취소됨', value: 'cancelled' },
  { label: '완료됨', value: 'completed' },
]

const CpmAdModificationSection = ({ refreshTrigger }) => {
  const [targetAd, setTargetAd] = useState([])
  const [dateRange, setDateRange] = useState(() => [
    moment().subtract(30, 'days'),
    moment(),
  ])
  const [dateOption, setDateOption] = useState('custom')
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false)
  const [selectedAd, setSelectedAd] = useState(null)
  const [selectedAdItems, setSelectedAdItems] = useState([])
  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [selectedAdForCancel, setSelectedAdForCancel] = useState(null)
  const [refundCredit, setRefundCredit] = useState(null)
  const [statusFilter, setStatusFilter] = useState('all')
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [isCancelling, setIsCancelling] = useState(false)

  const displayBudget = (ad) => {
    return ad.isFreeAd === true ? '0' : ad.budget.toLocaleString()
  }

  // filteredAds는 useMemo로만 선언
  const filteredAds = useMemo(() => {
    if (!targetAd?.length) return []

    return targetAd.filter((ad) => {
      if (statusFilter === 'all') return true
      if (statusFilter === 'cancelled') {
        return ['cancelledAfterActive', 'cancelledBeforeActive'].includes(
          ad.status,
        )
      }
      return ad.status === statusFilter
    })
  }, [targetAd, statusFilter])

  // API 호출 최적화
  const fetchData = useCallback(
    async (page = currentPage) => {
      try {
        const [startDate, endDate] = dateRange
        const params = {
          size: pageSize,
          page,
          ...(dateOption === 'custom' && {
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
          }),
          ...(statusFilter !== 'all' && { status: statusFilter }),
        }

        const res = await backendApis.getCpmAdSetList(params)

        if (res?.status === 200) {
          setTargetAd(res.data)
          setTotal(res.data.length)
        }
      } catch (error) {
        console.error('Error fetching CPM ad data:', error)
        setTargetAd([])
        setTotal(0)
      }
    },
    [currentPage, pageSize, dateRange, dateOption, statusFilter],
  )

  // handleOpenModal을 columns 정의보다 먼저 선언
  const handleOpenModal = useCallback(async (record) => {
    setSelectedAd(record)
    setIsDetailModalVisible(true)
    setIsLoadingItems(true)

    try {
      const itemsRes = await backendApis.getItemsByAdSetId({
        adCpmSetId: record._id,
      })

      setSelectedAdItems(itemsRes.data || [])
    } catch (error) {
      console.error('Error fetching items:', error)
      setSelectedAdItems([])
    } finally {
      setIsLoadingItems(false)
    }
  }, [])

  // 그 다음에 columns 정의
  const columns = useMemo(
    () => [
      {
        title: '광고 세트 ID',
        key: 'id',
        align: 'center',
        width: 200,
        render: (_, record) => (
          <Button
            type='link'
            onClick={() => handleOpenModal(record)}
            className='text-blue-500 hover:text-blue-700'
          >
            <span>{record._id.slice(-8)}</span>
          </Button>
        ),
      },
      {
        title: '생성일',
        key: 'createdAt',
        align: 'center',
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        render: (_, record) => (
          <span>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
        ),
      },
      {
        title: '광고 세트',
        key: 'itemCount',
        align: 'center',
        sorter: (a, b) =>
          (a.cpmItemIds?.length || 0) - (b.cpmItemIds?.length || 0),
        render: (_, record) => {
          const itemCount = record.cpmItemIds?.length || 0
          return <span>{itemCount}개 상품</span>
        },
      },
      {
        title: '예산',
        dataIndex: 'budget',
        key: 'budget',
        align: 'center',
        sorter: (a, b) => a.budget - b.budget,
        render: (_, record) => `${displayBudget(record)}원`,
      },
      {
        title: '노출 단가',
        dataIndex: 'costPerImpression',
        key: 'costPerImpression',
        align: 'center',
        sorter: (a, b) => a.costPerImpression - b.costPerImpression,
        render: (cost) => `${cost}원`,
      },
      {
        title: '최대 노출 수',
        dataIndex: 'maxImpressions',
        key: 'maxImpressions',
        align: 'center',
        sorter: (a, b) => a.maxImpressions - b.maxImpressions,
        render: (impressions) => impressions.toLocaleString(),
      },
      {
        title: '광고 기간',
        key: 'adPeriod',
        align: 'center',
        sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
        render: (_, record) => (
          <span>
            {moment(record.startDate).format('YYYY-MM-DD')} ~{' '}
            {moment(record.endDate).subtract(1, 'days').format('YYYY-MM-DD')}
          </span>
        ),
      },
      {
        title: '성별',
        key: 'gender',
        align: 'center',
        sorter: (a, b) => {
          const genderA = a.targeting?.gender || 'all'
          const genderB = b.targeting?.gender || 'all'
          return genderA.localeCompare(genderB)
        },
        render: (_, record) => {
          if (!record.targeting) return '-'
          const genderMap = {
            all: '전체',
            male: '남성',
            female: '여성',
          }
          return genderMap[record.targeting.gender] || '-'
        },
      },
      {
        title: '연령',
        key: 'age',
        align: 'center',
        sorter: (a, b) => {
          const ageA = a.targeting?.ageRange?.[0] || 0
          const ageB = b.targeting?.ageRange?.[0] || 0
          return ageA - ageB
        },
        render: (_, record) => {
          if (!record.targeting?.ageRange) return '전체'
          return getAgeRangeText(record.targeting.ageRange)
        },
      },
      {
        title: '상태',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: (status) => {
          const { color, text } =
            STATUS_CONFIG[status] || STATUS_CONFIG.approved
          return <Tag color={color}>{text}</Tag>
        },
      },
      {
        title: '액션',
        key: 'action',
        align: 'center',
        render: (_, record) => (
          <Space size='middle'>
            {(record.status === 'pending' || record.status === 'active') && (
              <Button type='link' onClick={() => showCancelConfirm(record)}>
                취소
              </Button>
            )}
          </Space>
        ),
      },
    ],
    [handleOpenModal],
  )

  // 헬퍼 함수들 메모이제이션
  const getAgeRangeText = useCallback((ageRange) => {
    if (!ageRange) return '전체'
    return ageRange[0] === 0 && ageRange[1] === 100
      ? '전체'
      : `${ageRange[0]}~${ageRange[1]}세`
  }, [])

  const getRefundCredit = () => {
    if (selectedAdForCancel.isFreeAd) {
      return '0'
    }

    return selectedAdForCancel.status === 'pending'
      ? selectedAdForCancel.budget.toLocaleString()
      : Math.floor(refundCredit)?.toLocaleString()
  }

  // 취소 관련 로직 최적화
  const handleCancelConfirm = useCallback(async () => {
    if (!selectedAdForCancel || isCancelling) return

    setIsCancelling(true)
    try {
      const res = await backendApis.cancelCpmAdSet({
        adCpmSetId: selectedAdForCancel._id,
      })

      if (res?.status === 200) {
        message.success('광고가 취소되었습니다.')
        fetchData()
      } else {
        message.error(res?.message || '광고 취소에 실패했습니다.')
      }
    } catch (error) {
      console.error('Error canceling ad:', error)
      message.error('광고 취소 중 오류가 발생했습니다.')
    } finally {
      setIsConfirmModalVisible(false)
      setSelectedAdForCancel(null)
      setRefundCredit(null)
      setIsCancelling(false)
    }
  }, [selectedAdForCancel, isCancelling, fetchData])

  // 효과적인 useEffect 사용
  useEffect(() => {
    const abortController = new AbortController()
    fetchData()

    return () => {
      abortController.abort()
    }
  }, [fetchData, refreshTrigger])

  useEffect(() => {
    const abortController = new AbortController()

    if (dateOption === 'all') {
      setDateRange([null, null])
      fetchData()
    } else if (dateOption === 'custom') {
      setDateRange([moment().subtract(30, 'days'), moment().add(7, 'days')])
    }

    return () => {
      abortController.abort()
    }
  }, [dateOption])

  const handleDateOptionChange = (e) => {
    setDateOption(e.target.value)
    if (e.target.value === 'all') {
      setDateRange([null, null])
      fetchData()
    } else if (e.target.value === 'custom') {
      const newDateRange = [moment().subtract(30, 'days'), moment()]
      setDateRange(newDateRange)
    }
  }

  const showCancelConfirm = async (record) => {
    setSelectedAdForCancel(record)
    setIsConfirmModalVisible(true)
    setRefundCredit(null)
    if (record.status === 'active') {
      await fetchRefundCredit(record._id)
    }
  }
  const handleSearch = () => {
    setCurrentPage(1)
    fetchData(1)
  }

  const fetchRefundCredit = async (adCpmSetId) => {
    try {
      const res = await backendApis.getRefundCreditAmount({ adCpmSetId })
      if (res?.status === 200) {
        setRefundCredit(res.data.refundAmount)
      }
    } catch (error) {
      console.error('Error fetching refund credit:', error)
    }
  }

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value)
  }

  return (
    <div className='text-slate-700'>
      <div style={{ marginBottom: 16 }}>
        <div className='flex gap-8 mb-4'>
          <div className='max-w-xs'>
            <RadioGroup
              options={DATE_OPTIONS}
              value={dateOption}
              onChange={handleDateOptionChange}
            />
          </div>
        </div>
        <div className='flex items-center gap-4 mt-4'>
          {dateOption === 'custom' && (
            <>
              <span className='text-[0.9vw] font-bold'>광고 시작일</span>
              <div>
                <DatePicker
                  value={{
                    startDate: dateRange[0]?.format('YYYY-MM-DD'),
                    endDate: dateRange[1]?.format('YYYY-MM-DD'),
                  }}
                  onChange={(newValue) => {
                    setDateRange([
                      newValue.startDate ? moment(newValue.startDate) : null,
                      newValue.endDate ? moment(newValue.endDate) : null,
                    ])
                  }}
                  displayFormat='YYYY년 MM월 DD일'
                  placeholder='조회 기간 선택'
                  i18n='ko'
                  inputClassName='rounded-md focus:ring-0 dark:bg-white dark:placeholder:text-gray-400 dark:border-gray-300 dark:text-gray-800'
                  showShortcuts
                  configs={{
                    shortcuts: {
                      today: '오늘',
                      yesterday: '어제',
                      currentMonth: '이번 달',
                      pastMonth: '지난 달',
                      past: (period) => `지난 ${period}일`,
                    },
                  }}
                  containerClassName='w-[18vw]'
                  style={{ width: '12rem' }}
                />
              </div>
              <Button
                type='primary'
                onClick={handleSearch}
                disabled={
                  dateOption === 'custom' && (!dateRange[0] || !dateRange[1])
                }
              >
                조회하기
              </Button>
            </>
          )}
        </div>
      </div>
      <div className='flex justify-between mb-4'>
        <Select
          style={{ width: 120 }}
          value={statusFilter}
          onChange={handleStatusFilterChange}
          options={STATUS_OPTIONS}
          placeholder='상태 선택'
        />
        <Button
          icon={<ReloadOutlined />}
          onClick={() => fetchData(currentPage)}
          className='ml-2'
        >
          새로고침
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filteredAds}
        rowKey={(record) => record._id}
        onChange={(pagination, filters, sorter) => {
          console.log('sorter:', sorter)
        }}
        pagination={{
          current: currentPage,
          pageSize,
          total,
          onChange: (page, pageSize) => {
            setCurrentPage(page)
            setPageSize(pageSize)
            fetchData(page)
          },
          showSizeChanger: true,
          showTotal: (total) => `총 ${total}개`,
          position: ['bottomCenter'],
        }}
      />
      <Modal
        title='광고 세트 상세 정보'
        open={isDetailModalVisible}
        onCancel={() => setIsDetailModalVisible(false)}
        footer={null}
        width={800}
      >
        {selectedAd && (
          <div className='space-y-4'>
            <div className='flex justify-between items-center mb-4'>
              <div className='flex items-center gap-2'>
                <span className='font-bold'>광고 세트 ID:</span>
                <span className='text-gray-600'>{selectedAd._id}</span>
                <Button
                  type='text'
                  icon={<CopyOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(selectedAd._id)
                    message.success('ID가 복사되었습니다')
                  }}
                  className='text-gray-400 hover:text-gray-600'
                />
              </div>
            </div>

            <div className='grid grid-cols-2 gap-4'>
              <div>
                <h3 className='font-bold mb-2'>타겟팅 정보</h3>
                <p>성별: {GENDER_MAP[selectedAd.targeting?.gender] || '-'}</p>
                <p>연령: {getAgeRangeText(selectedAd.targeting?.ageRange)}</p>
              </div>
              <div>
                <h3 className='font-bold mb-2'>광고 정보</h3>
                <p>예산: {displayBudget(selectedAd)}원</p>
                <p>노출 단가: {selectedAd.costPerImpression}원</p>
                <p>
                  최대 노출 수: {selectedAd.maxImpressions.toLocaleString()}
                </p>
              </div>
            </div>

            <div>
              <div className='flex justify-between items-center mb-2'>
                <h3 className='font-bold'>
                  등록된 상품 목록 ({selectedAdItems.length}개)
                </h3>
              </div>

              {isLoadingItems && (
                <div className='text-center py-4'>
                  <Spin /> 상품 정보를 불러오는 중...
                </div>
              )}

              {!isLoadingItems && selectedAdItems.length > 0 && (
                <List
                  grid={{ gutter: 16, column: 2 }}
                  dataSource={selectedAdItems}
                  renderItem={(item) => (
                    <List.Item
                      key={item._id}
                      className='hover:bg-gray-50 rounded-lg'
                    >
                      <div className='flex p-2 h-full'>
                        <div className='w-[5vw] h-[5vw] mr-3 flex-shrink-0'>
                          <img
                            src={item.mainImageUri || item.mainImageUris?.[0]}
                            alt={item.title}
                            className='w-full h-full object-cover rounded-md'
                            onError={(e) => {
                              e.target.onerror = null
                              e.target.src = '/default-product-image.png'
                            }}
                          />
                        </div>
                        <div className='flex-grow min-w-0'>
                          <div className='font-medium text-sm mb-1 truncate'>
                            {item.itemTitle}
                          </div>
                          <div className='text-[0.7vw] space-y-0.5'>
                            <div className='flex justify-between'>
                              <span>판매가</span>
                              <span>
                                {item.individualPurchasePrice?.toLocaleString()}
                                원
                              </span>
                            </div>
                            <div className='flex justify-between'>
                              <span>팀구매가</span>
                              <span>
                                {item.teamPurchasePrice?.toLocaleString()}원
                              </span>
                            </div>
                            <div className='flex justify-between'>
                              <span>재고</span>
                              <span>
                                {item.outOfStock ? '품절' : '재고 있음'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </List.Item>
                  )}
                  pagination={{
                    pageSize: 6,
                    position: 'bottom',
                    align: 'center',
                    total: selectedAdItems.length,
                    showSizeChanger: false,
                  }}
                />
              )}

              {!isLoadingItems && selectedAdItems.length === 0 && (
                <div className='text-center text-gray-500 py-4'>
                  등록된 상품이 없습니다.
                </div>
              )}
            </div>

            <div>
              <h3 className='font-bold mb-2'>광고 기간</h3>
              <p>
                {moment(selectedAd.startDate).format('YYYY-MM-DD')} ~{' '}
                {moment(selectedAd.endDate)
                  .subtract(1, 'days')
                  .format('YYYY-MM-DD')}
              </p>
            </div>

            <div>
              <h3 className='font-bold mb-2'>상태</h3>
              {(() => {
                const { color, text } =
                  STATUS_CONFIG[selectedAd.status] || STATUS_CONFIG.approved
                return <Tag color={color}>{text}</Tag>
              })()}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        title='광고 취소 확인'
        open={isConfirmModalVisible}
        onOk={handleCancelConfirm}
        onCancel={() => {
          setIsConfirmModalVisible(false)
          setSelectedAdForCancel(null)
          setRefundCredit(null)
        }}
        okText='확인'
        cancelText='취소'
        okButtonProps={{
          loading: isCancelling,
          disabled: isCancelling,
        }}
      >
        <p>선택한 광고를 취소하시겠습니까?</p>
        {selectedAdForCancel && (
          <div className='mt-4 p-4 bg-gray-50 rounded-md space-y-2'>
            <p className='text-sm text-gray-500'>
              광고 기간:{' '}
              {moment(selectedAdForCancel.startDate).format('YYYY-MM-DD')} ~{' '}
              {moment(selectedAdForCancel.endDate).format('YYYY-MM-DD')}
            </p>
            <p className='text-sm text-gray-500'>
              등록 상품 수: {selectedAdForCancel.cpmItemIds?.length || 0}개
            </p>
            <p className='text-sm text-gray-500'>
              예산: {displayBudget(selectedAdForCancel)}원
            </p>
            <div className='mt-3 p-3 bg-blue-50 rounded border border-blue-100'>
              <p className='text-blue-600 font-medium'>
                환불 예정 크레딧: {getRefundCredit()}원
              </p>
              <p className='text-xs text-blue-500 mt-1'>
                {selectedAdForCancel.status === 'pending'
                  ? '* 대기 중인 광고 취소 시 전체 예산이 크레딧으로 환불됩니다.'
                  : '* 진행 중인 광고 취소 시 잔여 예산이 크레딧으로 환불됩니다. 잔여 예산은 광고 기간 중 노출된 횟수에 따라 결정됩니다. 현재 광고가 진행 중이므로 실제 환불 크레딧과 상이할 수 있습니다.'}
              </p>
            </div>
          </div>
        )}
        <p className='mt-4 text-red-500'>* 취소된 광고는 복구할 수 없습니다.</p>
      </Modal>
    </div>
  )
}

export default CpmAdModificationSection
