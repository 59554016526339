import React, { memo } from 'react'
import { Button, Image, Tooltip } from 'antd'
import CpmAdApplicationGraph from '../../../../components/molecules/CpmAdApplicationGraph'
import './Stage0Introduction.css'
import useIsFreeCpmSeller from '../hooks/useIsFreeCpmSeller'

const COMMON_STYLES = {
  flexContainer: 'flex flex-1 text-left justify-start',
  whiteCard: 'bg-white shadow-lg rounded-t-xl p-12',
}

const ActionButton = memo(({ type, onClick, children }) => (
  <Button
    type={type}
    onClick={onClick}
    className={`${type === 'default' ? 'bg-white' : ''} text-md px-8 h-12`}
    size='large'
  >
    {children}
  </Button>
))

const Stage0Introduction = memo(
  ({ handleNextStage, setModalStatus, activeTab }) => {
    const isFreeCpmTargetSeller = useIsFreeCpmSeller()

    const handleExplanationClick = () => {
      setModalStatus((prev) => ({
        ...prev,
        adExplanationModal: true,
      }))
    }

    return (
      <section className='mb-20 text-slate-700'>
        <div className={COMMON_STYLES.whiteCard}>
          <h2 className='text-2xl flex w-full font-bold mb-5 text-left justify-start'>
            AI 맞춤형 CPM 광고
          </h2>

          <div className='flex flex-row justify-between items-center w-full mb-12 text-slate-800'>
            <div className='flex flex-col flex-1 mr-8'>
              <p>
                올팜 등 인기 서비스들에서 100만 고객에게 상품을 광고하고 판매를
                유도해보세요
              </p>
              <p>
                AI 맞춤형으로 구매 가능성이 높은 고객들에게만 상품을 노출해요
              </p>
            </div>
            <div className='flex flex-row gap-4'>
              <ActionButton
                type='default'
                onClick={() => {
                  window.open(
                    'https://drive.google.com/file/d/1vayx_8Iik_caKHLEraWJEDXTJQsPIUul/view?usp=sharing',
                    '_blank',
                  )
                }}
              >
                자세한 CPM 광고 소개서 ↗
              </ActionButton>
              <ActionButton type='default' onClick={handleExplanationClick}>
                CPM 광고 요약 소개 보기
              </ActionButton>
              <Tooltip
                title='CPM 무료 광고 신청 OPEN!'
                color='red'
                open={activeTab === '1' && isFreeCpmTargetSeller}
                overlayClassName='pulse-tooltip'
              >
                <ActionButton type='primary' onClick={() => handleNextStage(1)}>
                  간편 신청하기
                </ActionButton>
              </Tooltip>
            </div>
          </div>
          <Image
            src='https://assets.ilevit.com/2154c14f-8bff-42b1-a64d-d05a803bfb06.png'
            preview={false}
          />
          {/* 이미지 사이의 간격을 두고 싶음. */}
          <div className='mb-12'></div>
          <Image
            src='https://assets.ilevit.com/2154c14f-8bff-42b1-a64d-d05a803b06fb.png'
            preview={false}
          />
        </div>

        <CpmAdApplicationGraph />
      </section>
    )
  },
)

Stage0Introduction.displayName = 'Stage0Introduction'
export default Stage0Introduction
