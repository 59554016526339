import ModalStore from '../stores/ModalStore'
import logo from '../images/LogoRed.png'

// eslint-disable-next-line import/prefer-default-export
export const displayMaintenanceMode = (html) => {
  ModalStore.setMaintenanceMode({
    visible: true,
    html: `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Maintenance Mode</title>
  <style>
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    html, body {
      height: 100%;
    }

    body {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
      font-family: 'Arial', sans-serif;
      color: #333;
      text-align: center;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      max-width: 400px;
      padding: 20px;
      background: #fff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      box-sizing: border-box;
    }

    .logo {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
    }

    .logo img {
      max-width: 100%;
    }

    .title {
      font-size: 1.8em;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .description {
      font-size: 1em;
      margin-bottom: 20px;
      color: #555;
    }

    .maintenance-time {
      font-size: 1em;
      font-weight: bold;
      color: #e63946;
      margin-bottom: 20px;
    }

    .footer {
      font-size: 0.9em;
      color: #999;
    }

    @media (max-width: 480px) {
      .container {
        padding: 15px;
      }

      .title {
        font-size: 1.5em;
      }

      .description,
      .maintenance-time {
        font-size: 0.9em;
      }
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="logo">
      <img src="${logo}" alt="Company Logo" />
    </div>
    ${html}
  </div>
</body>
</html>
    `,
  })
}
