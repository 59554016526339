import './App.css'
import LoginScreen from './screens/LoginScreen'
import AdminScreen from './screens/AdminScreen'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './screens/PrivateRoute'
import SearchPasswordScreen from './screens/SearchPasswordScreen'
import moment from 'moment-timezone'
import RegisterSellerScreen from './screens/RegisterSellerScreen'
import { observer } from 'mobx-react-lite'
import LoadingIndicator from './components/LoadingIndicator'
// import * from 'moment-timezone'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'

import MobileLoginScreen from './screens/MobileLoginScreen'
import MobileSearchPasswordScreen from './screens/MobileSearchPasswordScreen'
import AdminLoginScreen from './screens/AdminLoginScreen'
import ModalStore from './stores/ModalStore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyClPzQLW8VYM0H9_ESxXOI14qQi9GLG55g',
  authDomain: 'alwayz-seller.firebaseapp.com',
  projectId: 'alwayz-seller',
  storageBucket: 'alwayz-seller.appspot.com',
  messagingSenderId: '393145456305',
  appId: '1:393145456305:web:200b56d6a7a1e61985b490',
  measurementId: 'G-3WN0DZG7JD',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

moment.tz.setDefault('Asia/Seoul')
// const curr = new Date()
// const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000
// const KR_TIME_DIFF = 9 * 60 * 60 * 1000
// const kr_curr = new Date(utc + KR_TIME_DIFF)

const App = observer(() => {
  if (ModalStore.maintenanceMode.visible) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: ModalStore.maintenanceMode.html }}
      />
    )
  }

  return (
    <Router>
      <Switch>
        <Route path='/login/register-seller'>
          {/* <BrowserView> */}
          <RegisterSellerScreen />
          {/* </BrowserView> */}
          {/* 
          모바일뷰 코드와 브라우저뷰 코드가 너무 차이나서 브라우저뷰 코드만 남김
          <MobileView>
            <MobileRegisterSellerScreen />
          </MobileView> */}
        </Route>
        <Route path='/login/password-search'>
          <BrowserView>
            <SearchPasswordScreen />
          </BrowserView>
          <MobileView>
            <MobileSearchPasswordScreen />
          </MobileView>
        </Route>
        <Route path='/login'>
          <BrowserView>
            <LoginScreen />
          </BrowserView>
          <MobileView>
            <MobileLoginScreen />
          </MobileView>
        </Route>
        <Route path='/admin/login'>
          <BrowserView>
            <AdminLoginScreen />
          </BrowserView>
        </Route>
        <PrivateRoute path='/' component={AdminScreen} />
        {/* <Route path='/admin'>
          <AdminScreen />
        </Route> */}
      </Switch>
      <LoadingIndicator />
    </Router>
  )
})

export default App
